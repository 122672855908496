'use strict';

app.controller('CompaniesCtrl', ['$scope', '_', 'Modal', '$state', '$stateParams', 'Notif', 'Company', 'Contact', 'Family', 'User',
    function ($scope, _, Modal, $state, $stateParams, Notif, Company, Contact, Family, User) {
    /* Init variables */
    $scope.companies = [];

    $scope.pagination = {
        total: 0,
        skip: 0,
        currentPage: 1,
        limit: 20
    };

    $scope.params = {};
    $scope.search = {};
    $scope.options = {
        sort: '_name',
        orderBy: 1
    };

    var getContact = function (company, cb) {
        Contact.query({params: {reference: company._id}}, function (contacts) {
            var i = _.findIndex(contacts, {main: true});
            company.contact = (i === -1 ? contacts[0] : contacts[i]);
            cb();
        }, cb);
    };

    var getFamilyCount = function (company, cb) {
        Family.count({params: {'parents.company.reference': company._id}}, function (c) {
            company.familyCount = c.count;
            cb();
        }, cb);
    };

    $scope.initCompanies = function () {
        $scope.loader = true;
        $scope.getCount(function () {
            $scope.changeList();
            $scope.loader = false;
        });
    };

    $scope.changeList = function (cb) {
        var options = _.clone($scope.options);
        options.skip = ($scope.pagination.currentPage - 1) * $scope.pagination.limit;
        $scope.pagination.skip = options.skip;
        options.limit = $scope.pagination.limit;
        options.populate = true;

        async.series([
            function (cb) {
                Company.query({params: $scope.params, search: $scope.search, options: options}, function (companies) {
                    $scope.companies = companies;
                    cb();
                }, cb);
            },
            function (cb) {
                async.each($scope.companies, function (company, cb) {
                    getContact(company, cb);
                }, cb);
            },
            function (cb) {
                async.each($scope.companies, function (company, cb) {
                    getFamilyCount(company, cb);
                });
            }
        ], function (err) {
            if (err) Notif.error(err);
            $scope.loader = false;
        });
    };

    $scope.filterList = $scope.initCompanies;

    $scope.getCount = function (cb) {
        Company.count({params: $scope.params, search: $scope.search}, function (data) {
            if (data) {
                $scope.resetPaging(data.count);
                if (cb) cb();
            }
        }, Notif.error);
    };

    $scope.resetPaging = function (total) {
        $scope.pagination.total = total;
        $scope.pagination.skip = 0;
        $scope.pagination.currentPage = 1;
    };

    $scope.delete = function (company) {
        Modal.confirm().then(function (result) {
            Company.delete(company, function () {
                Notif.success("L'entreprise a bien été supprimé !");
                $scope.initCompanies();
            }, Notif.error);
        });
    };
}]);