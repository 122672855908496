/* Define Angular app */
var app = angular.module('app', [
  'ngResource', 'ui.router', 'ncy-angular-breadcrumb', 'ui.bootstrap', 'ngMask',
  'angular.filter', 'ngNotify', 'ngTagsInput', 'uiGmapgoogle-maps', 'ngCsv',
  'mgcrea.ngStrap.select', 'angularScreenfull', 'luegg.directives', 'ngVis', 'ngFileUpload'
]);

/* Define constants */
app.constant('_', window._);
app.constant('moment', window.moment);
app.constant('string_to_color', window.string_to_color);
app.constant('AUTH_EVENTS', {
  notAuthenticated: 'auth-not-authenticated',
  notAuthorized: 'auth-not-authorized'
});

app.config(['$compileProvider', function ($compileProvider) {
  $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|tel|mailto):/);
}]);

/* Defines routes */
app.config(['$stateProvider', '$urlRouterProvider', function ($stateProvider, $urlRouterProvider) {

  $stateProvider.state('root', {
    abstract: true,
    views: {
      '': { templateUrl: 'partials/layout.html' },
      'header@root': { templateUrl: 'partials/header.html' },
      'sidebar@root': { templateUrl: 'partials/sidebar.html' },
      'footer@root': { templateUrl: 'partials/footer.html' }
    },
    data: {
      bodyClass: 'skin-blue sidebar-mini'
    }
  }).state('auth', {
    url: "/auth",
    controller: 'AuthCtrl',
    templateUrl: "views/auth.html",
    resolve: {
      isModal: function () {
        return false;
      },
      message: function () {
        return null;
      }
    },
    data: {
      bodyClass: 'hold-transition login-page'
    }
  }).state('home', {
    url: "/",
    parent: 'root',
    templateUrl: "partials/index.html",
    ncyBreadcrumb: { label: 'Accueil' }
  }).state('dashboard', {
    url: "/dashboard?ca",
    parent: 'root',
    controller: 'DashboardCtrl',
    templateUrl: "views/dashboard/index.html",
    ncyBreadcrumb: { label: 'Dashboard' },
    resolve: {}
  }).state('spanco', {
    url: "/spanco?ca",
    parent: 'root',
    controller: 'SpancoCtrl',
    templateUrl: "views/spanco/index.html",
    ncyBreadcrumb: { label: 'Portefeuille clients' },
    data: {
      authorizedRights: ['COMMERCIAL_MANAGE', 'COMMERCIAL_HANDLE_FAMILY']
    },
    resolve: {}
  }).state('profile', {
    url: "/profile",
    parent: 'root',
    controller: 'ProfileCtrl',
    templateUrl: "views/profile/index.html",
    ncyBreadcrumb: { label: 'Mon Profil' }
  }).state('user', {
    url: "/admin/user",
    parent: 'root',
    controller: 'UserCtrl',
    templateUrl: "views/user/list.html",
    ncyBreadcrumb: { parent: 'home', label: 'utilisateurs' },
    data: {
      authorizedRights: ['USER_VIEW_LIST']
    }
  }).state('user.add', {
    url: "/admin/user/add",
    parent: 'root',
    controller: 'UserCtrl',
    templateUrl: "views/user/edit.html",
    ncyBreadcrumb: { parent: 'home', label: 'Utilisateurs' },
    data: {
      authorizedRights: ['USER_ADD']
    }
  }).state('user.edit', {
    url: "/admin/user/edit/:userId",
    parent: 'root',
    controller: 'UserCtrl',
    templateUrl: "views/user/edit.html",
    ncyBreadcrumb: { parent: 'home', label: 'Utilisateurs' },
    data: {
      authorizedRights: ['USER_EDIT']
    }
  }).state('user.repertoire', {
    url: "/admin/user/repertoire",
    parent: 'root',
      controller: 'UserCtrl',
      templateUrl: "views/user/repertoire.html"
  }).state('user.profile', {
    url: "/admin/user/:userId",
    parent: 'root',
    controller: 'UserCtrl',
    templateUrl: "views/user/profile.html",
    ncyBreadcrumb: { parent: 'home', label: 'Utilisateurs' }
  }).state('network', {
    url: "/network",
    parent: 'root',
    controller: 'NetworksCtrl',
    templateUrl: "views/network/list.html",
    ncyBreadcrumb: { parent: 'home', label: 'Réseaux' }
  }).state('network.add', {
    url: "/network/add",
    parent: 'root',
    controller: 'NetworkCtrl',
    templateUrl: "views/network/add.html",
    ncyBreadcrumb: { parent: 'home', label: 'Réseaux' }
  }).state('network-detail', {
    url: '/network/:networkId',
    parent: 'root',
    controller: 'NetworkRootCtrl',
    abstract: true,
    templateUrl: "views/network/index.html",
    ncyBreadcrumb: { parent: 'network', label: 'Détail' }
  }).state('network-detail.edit', {
    url: '/edit',
    views: {
      'default': {
        controller: 'NetworkCtrl',
        templateUrl: 'views/network/edit.html'
      }
    },
    ncyBreadcrumb: { 'label': 'Edit' }
  }).state('network-detail.profil', {
    url: '/profil',
    views: {
      'default': {
        controller: 'NetworkCtrl',
        templateUrl: 'views/network/detail.html'
      }
    },
    ncyBreadcrumb: { 'label': 'Profil' }
  }).state('network-detail.creches', {
    url: '/creches',
    views: {
      'default': {
        controller: 'CrechesListCtrl',
        templateUrl: 'views/network/crechesList.html'
      }
    },
    ncyBreadcrumb: { 'label': 'Crèches' }
  }).state('network-detail.contacts', {
    url: '/contacts',
    views: {
      'default': {
        templateUrl: 'views/network/contactsList.html',
        controller: function($scope) {
          $scope.editMode = $scope.$parent.editMode;
        }
      }
    },
    ncyBreadcrumb: { 'label': 'Contacts' }
  }).state('event-list-quality', {
    url: "/event-list/quality?users&status&result&dateStart&dateEnd&page&limit",
    parent: 'root',
    controller: 'EventListCtrl',
    templateUrl: "views/event/list.html",
    ncyBreadcrumb: { parent: 'home', label: 'Appels qualité' },
    data: {
      eventType: 'quality'
    }
  }).state('event-list-visit', {
    url: "/event-list/visit?users&creches&networks&state&status&zipCode&dateStart&dateEnd&page&limit",
    parent: 'root',
    controller: 'EventListCtrl',
    templateUrl: "views/event/list.html",
    ncyBreadcrumb: { parent: 'home', label: 'Visites qualité' },
    data: {
      eventType: 'visit'
    }
  }).state('event-list-networkvisits', {
    url: "/event-list/networkvisits?state",
    parent: 'root',
    controller: 'NetworkEvenstListCtrl',
    templateUrl: "views/event/networkvisitslist.html",
    ncyBreadcrumb: { parent: 'home', label: 'Réseaux à visiter' },
    data: {
      eventType: 'visit'
    }
  }).state('creche', {
    url: "/creche?networks&status&search&zipCode&page&limit",
    parent: 'root',
    controller: 'CrecheCtrl',
    templateUrl: "views/creche/list.html",
    ncyBreadcrumb: { parent: 'home', label: 'Crèches' }
  }).state('creche-detail', {
    url: "/creche/:crecheId",
    parent: 'root',
    controller: 'CrecheCtrl',
    templateUrl: "views/creche/index.html",
    abstract: true,
    ncyBreadcrumb: { parent: 'home', label: 'Crèches' }
  }).state('creche-detail.new', {
    url: '/new',
    views: {
      'default': {
        controller: 'CrecheCtrl',
        templateUrl: 'views/creche/edit.html'
      }
    },
    ncyBreadcrumb: { label: 'Nouveau' }
  }).state('creche-detail.profile', {
    url: '/profile',
    views: {
      'default': {
        controller: 'CrecheCtrl',
        templateUrl: 'views/creche/edit.html'
      }
    },
    ncyBreadcrumb: { label: 'Profil' }
  }).state('creche-detail.contract', {
    url: '/contract',
    views: {
      'default': {
        controller: 'ContractCrecheListCtrl',
        templateUrl: 'views/creche/contract.html'
      }
    },
    ncyBreadcrumb: { label: 'Berceaux' }
  }).state('creche-detail.legacy', {
    url: '/legacy',
    views: {
      'default': {
        controller: 'CrecheCtrl',
        templateUrl: 'views/creche/legacy.html'
      }
    },
    ncyBreadcrumb: { label: 'Backend' }
  }).state('creche-detail.contact', {
    url: '/contact',
    views: {
      'default': {
        controller: 'CrecheCtrl',
        templateUrl: 'views/creche/contact.html'
      }
    },
    ncyBreadcrumb: { label: 'Contact' }
  }).state('creche-detail.visit', {
    url: '/visits',
    views: {
      'default': {
        controller: 'CrecheEventCtrl',
        templateUrl: 'views/creche/listVisit.html'
      }
    },
    ncyBreadcrumb: { label: 'Visites' }
  }).state('creche-detail.visit-profile', {
    url: '/visit-profile/:eventId',
    views: {
      'default': {
        controller: 'CrecheEventCtrl',
        templateUrl: 'views/creche/visit.html'
      }
    },
    ncyBreadcrumb: { label: 'Profil Visite' }
  }).state('pam', {
    url: "/pam?address&familyId&networkId&crecheId&addressCompany",
    parent: 'root',
    controller: 'PamCtrl',
    templateUrl: "views/pam/carte.html",
    ncyBreadcrumb: { parent: 'home', label: 'Pam' }
  }).state('roles', {
    url: "/admin/roles",
    parent: 'root',
    controller: 'RoleCtrl',
    templateUrl: "views/role/role.html",
    ncyBreadcrumb: { parent: 'home', label: 'Rôles' }
  }).state('reservation', {
    url: "/reservation",
    parent: 'root',
    controller: 'RootCtrl',
    templateUrl: "partials/wip.html",
    ncyBreadcrumb: { parent: 'home', label: 'Réservation' }
  }).state('family', {
    url: "/famille?search&ca&status&company&nextStep&dateStart&dateEnd&zipCode&page&limit&meta.name&meta.source&meta.medium&application",
    params: {
      defaultApplication: '58ad59fabda3fdb4efba6d13'
    },
    parent: 'root',
    controller: 'FamilyCtrl',
    templateUrl: "views/family/list.html",
    ncyBreadcrumb: { parent: 'home', label: 'Famille' }
  }).state('family.icare', {
    url: "/icare/famille?search&ca&status&company&nextStep&dateStart&dateEnd&zipCode&page&limit&meta.name&meta.source&meta.medium&application",
    parent: 'root',
    controller: 'FamilyCtrl',
    templateUrl: "views/family/list.html",
    ncyBreadcrumb: { parent: 'home', label: 'Famille - Adressé' }
  }).state('family.icare.add', {
    url: "/family/add",
    parent: 'root',
    controller: 'FamilyCtrl',
    templateUrl: "views/family/add.html",
    ncyBreadcrumb: { parent: 'home', label: 'Ajout' }
  }).state('family.icare.detail', {
    url: "/famille/detail/:familyId",
    parent: 'root',
    controller: 'FamilyCtrl',
    templateUrl: "views/family/detail.html",
    ncyBreadcrumb: { parent: 'family', label: 'Détail' }
  }).state('family.hub', {
    url: "/hub/famille?search&ca&status&company&nextStep&dateStart&dateEnd&zipCode&page&limit&meta.name&meta.source&meta.medium&application",
    parent: 'root',
    controller: 'FamilyCtrl',
    templateUrl: "views/family/list.html",
    ncyBreadcrumb: { parent: 'home', label: 'FamilyHub - Famille' }
  }).state('family.hub.detail', {
    url: "/hub/famille/detail/:familyId",
    parent: 'root',
    controller: 'FamilyCtrl',
    templateUrl: "views/family/detail.html",
    ncyBreadcrumb: { parent: 'family', label: 'FamilyHub - Détail' }
  }).state('family.event-quality', {
    url: "/famille/quality/:familyId",
    parent: 'root',
    controller: 'EventListCtrl',
    templateUrl: "views/family/quality.html",
    ncyBreadcrumb: { parent: 'family', label: 'Appels qualité' },
    data: {
      eventType: 'quality'
    }
  }).state('link', {
    url: "/link",
    parent: 'root',
    controller: 'LinkCtrl',
    templateUrl: "views/link/list.html",
    ncyBreadcrumb: {parent: 'home', label: 'Liens'}
  }).state('link.add', {
    url: "/link/add",
    parent: 'root',
    controller: 'LinkCtrl',
    templateUrl: "views/link/edit.html",
    ncyBreadcrumb: { parent: 'link', label: 'Ajout' }
  }).state('link.edit', {
    url: "/link/edit/:linkId",
    parent: 'root',
    controller: 'LinkCtrl',
    templateUrl: "views/link/edit.html",
    ncyBreadcrumb: { parent: 'link', label: 'Edition' }
  }).state('gacidmetasourceupdater', {
    url: "/gacidmetasourceupdater",
    parent: 'root',
    controller: 'GACIdSourceUpdateCtrl',
    templateUrl: "views/GACIdSourceUpdate/index.html",
    ncyBreadcrumb: {parent: 'home', label: 'GA CId Meta Source Updater'}
  }).state('company', {
    url: '/company',
    parent: 'root',
    controller: 'CompaniesCtrl',
    templateUrl: 'views/company/list.html',
    ncyBreadcrumb: { parent: 'home', 'label': 'Entreprises' }
  }).state('company-detail', {
    url: '/company/:companyId',
    parent: 'root',
    abstract: true,
    templateUrl: "views/company/index.html",
    resolve: {
      CompanyId: function($stateParams) {
        if($stateParams.companyId == 'add')
          return null;
        else
          return $stateParams.companyId;
      },
      CompanyEntity: function (CompanyId, Company) {
        if (CompanyId) {
          return Company.get({_id: CompanyId}, function(data) {
            return data;
            // force the resolution of the promise before returning
          }).$promise;
        } else {
          return {contacts: []};
        }
      }
    },
    ncyBreadcrumb: { parent: 'company', 'label': 'Détail' },
    controller : function($scope, $state, CompanyId, CompanyEntity) {
      $scope.editMode = ($state.current.name == 'company-detail.edit');
      $scope.company = CompanyEntity;
      $scope.isEdit = CompanyId !== null;

      $scope.changeEditMode = function() {
        $scope.editMode = !$scope.editMode;
        if($scope.editMode)
          $state.go('company-detail.edit');
        else if(!$scope.editMode)
          $state.go('company-detail.profil');
      };
    }
  }).state('company-detail.edit', {
    url: '/edit',
    views: {
      'default': {
        controller: 'CompanyCtrl',
        templateUrl: 'views/company/edit.html'
      }
    },
    ncyBreadcrumb: { 'label': 'Edit' }
  }).state('company-detail.add', {
    url: '/company',
    views: {
      'default': {
        controller: 'CompanyCtrl',
        templateUrl: 'views/company/add.html'
      }
    },
    ncyBreadcrumb: { 'label': 'Ajout' }
  }).state('company-detail.profil', {
    url: '/profil',
    views: {
      'default': {
        controller: 'CompanyCtrl',
        templateUrl: 'views/company/detail.html'
      }
    },
    ncyBreadcrumb: { 'label': 'Profil' }
  }).state('company-detail.contacts', {
    url: '/contacts',
    views: {
      'default': {
        templateUrl: 'views/company/contacts.html',
        controller: function($scope) {
          $scope.editMode = $scope.$parent.editMode;
        }
      }
    },
    ncyBreadcrumb: { 'label': 'Contacts' }
  }).state('company-detail.families', {
    url: '/families',
    views: {
      'default': {
        controller: 'FamilyListCtrl',
        templateUrl: 'views/company/familyList.html'
      }
    },
    ncyBreadcrumb: { 'label': 'Familles' }
  }).state('company-detail.contracts', {
    url: '/contracts',
    views: {
      'default': {
        controller: 'ContractListCtrl',
        templateUrl: 'views/company/contractList.html'
      }
    },
    ncyBreadcrumb: { 'label': 'Contrats' }
  }).state('bill', {
    url: '/bills?search&creches&companies&contracts&applications&year&notreceived',
    parent: 'root',
    controller: 'BillCtrl',
    templateUrl: 'views/bill/list.html',
    ncyBreadcrumb: { parent: 'home', label: 'Facture' }
  }).state('contract', {
    url: '/contract?search&dateStart&dateEnd&dateMonth&statuses&creches&companies&contracts&users&mail&locked&limit&sort&page&applications&networks&state',
    parent: 'root',
    controller: 'ContractCtrl',
    templateUrl: 'views/contract/list.html',
    ncyBreadcrumb: { parent: 'home', label: 'Berceaux' }
  }).state('contract-detail', {
    url: '/contract/:contractId',
    parent: 'root',
    abstract: true,
    templateUrl: 'views/contract/index.html',
    ncyBreadcrumb: { parent: 'contract', label: 'Détail' }
  }).state('contract-detail.profile', {
    url: '/:mode',
    views: {
      'default': {
        controller: 'ContractCtrl',
        templateUrl: 'views/contract/edit.html'
      }
    },
    ncyBreadcrumb: { label: 'Profil' }
  }).state('contract-detail.history', {
    url: '/history/:mode',
    views: {
      'default': {
        controller: 'ContractHistoryCtrl',
        templateUrl: 'views/contract/history.html'
      }
    },
    ncyBreadcrumb: { label: 'Historique' }
  }).state('contract-detail.events', {
    url: '/event/',
    views: {
      'default': {
        controller: 'ContractEventCtrl',
        //templateUrl: 'views/contract/events.html'
      }
    },
    ncyBreadcrumb: { label: 'Appels qualité' }
  }).state('hub-contract', {
    url: "/hub/contract?search&dateStart&dateEnd&statuses&creches&companies&contracts&users&mail&locked&limit&sort&page&applications",
    parent: 'root',
    controller: 'ContractCtrl',
    templateUrl: "views/contract/list.html",
    ncyBreadcrumb: { parent: 'home', label: 'FamilyHub - Berceaux' }
  }).state('hub-contract-detail', {
    url: '/hub/contract/:contractId',
    parent: 'root',
    abstract: true,
    controller: 'ContractCtrl',
    templateUrl: 'views/contract/index.html',
    ncyBreadcrumb: { parent: 'hub-contract', label: 'Détail' }
  }).state('hub-contract-detail.profile', {
    url: '/:mode',
    views: {
      'default': {
        controller: 'ContractCtrl',
        templateUrl: 'views/contract/edit.html'
      }
    },
    ncyBreadcrumb: { label: 'Profil' }
  }).state('hub-contract-detail.history', {
    url: '/history/:mode',
    views: {
      'default': {
        controller: 'ContractHistoryCtrl',
        templateUrl: 'views/contract/history.html'
      }
    },
    ncyBreadcrumb: { label: 'Historique' }
  }).state('notifications', {
    url: '/notifications?limit&page&type&read',
    parent: 'root',
    templateUrl: 'views/notification/list.html',
    controller: 'NotificationsCtrl',
    ncyBreadcrumb: { label: 'Notifications' }
  }).state('contact', {
    url: '/contacts?search&phone&email&types&flags&page&limit',
    parent: 'root',
    controller: 'ContactCtrl',
    templateUrl: 'views/contact/list.html',
    ncyBreadcrumb: { label: 'Contacts'}
  }).state('codir', {
    url: "/codir/ca",
    parent: 'root',
    controller: 'CodirCtrl',
    templateUrl: "views/codir/ca.html",
    ncyBreadcrumb: { parent: 'home', label: 'codir' },
    data: {
      authorizedRights: ['SEE_CODIR_MENU']
    }
  });

  $urlRouterProvider.when('/company/add', '/company/add/edit');
  $urlRouterProvider.when('/company/:companyId', '/company/:companyId/profil');


$urlRouterProvider.otherwise(function ($injector) {
    $authService = $injector.get('AuthService');
    if ($authService.isAuthenticated()) {
      return 'dashboard';
    } else {
      return 'auth';
    }
  });
}
]).config(function ($httpProvider, tagsInputConfigProvider, uiGmapGoogleMapApiProvider) {
  $httpProvider.interceptors.push(function ($rootScope, $q, AUTH_EVENTS) {
    return {
      responseError: function (response) {
        $rootScope.$broadcast({
          401: AUTH_EVENTS.notAuthenticated,
          403: AUTH_EVENTS.notAuthorized
        }[response.status], response);
        return $q.reject(response);
      }
    };
  });

  tagsInputConfigProvider
    .setDefaults('tagsInput', {
      'addFromAutocompleteOnly': true
    })
    .setDefaults('autoComplete', {
      minLength: 0,
      loadOnDownArrow: true,
      loadOnEmpty: true,
      loadOnFocus: true,
      debounceDelay: 300,
      maxResultsToShow: 10000 //Large number
    });

  uiGmapGoogleMapApiProvider.configure({
    key: 'AIzaSyDfkFr840uMoNUvKkoM8we27rE7L3_f1xM',
    v: '3.23',
    language:'fr-FR',
    libraries: 'places'
  });
}).run(function ($rootScope, $state, $uibModal, ngNotify, AuthService, AUTH_EVENTS) {
  // Auth behavior
  var authModal = null;

  function openAuthModal(message) {
    if (authModal === null) {
      //Display Modal
      authModal = $uibModal.open({
        animation: true,
        templateUrl: 'views/auth.html',
        controller: 'AuthCtrl',
        size: 'large',
        windowClass: 'center-modal auth-modal',
        backdrop: false,
        keyboard: false,
        resolve: {
          isModal: function () {
            return true;
          },
          message: function () {
            return message;
          }
        }
      });

      authModal.result.then(function () {
        authModal = null;
      });
    }
  }

  $rootScope.$on('$stateChangeStart', function (event, next, nextParams, fromState) {
    if ('data' in next && 'authorizedRights' in next.data) {
      var authorizedRights = next.data.authorizedRights;
      if (!AuthService.isAuthorized(authorizedRights)) {
        event.preventDefault();
        openAuthModal('Vous ne disposez pas des droits suffisants.');
      }
    }

    if (next.name !== 'auth') {
      if (!AuthService.isAuthenticated()) {
        event.preventDefault();
        if (fromState.abstract) {
          $state.go('auth');
        } else if (fromState.name != 'auth') {
          openAuthModal("Désolé, vous avez été déconnecté. Veuillez vous reconnecter.");
        }
      }
    }
  });

  $rootScope.$on(AUTH_EVENTS.notAuthorized, function (event) {
    event.preventDefault();
    AuthService.logout();
    if (!$state.is('auth')) {
      openAuthModal('Vous ne disposez pas des droits suffisants.');
    }
  });

  $rootScope.$on(AUTH_EVENTS.notAuthenticated, function (event) {
    event.preventDefault();
    if (!$state.is('auth')) {
      openAuthModal('Désolé, vous avez été déconnecté. Veuillez vous reconnecter.');
    }
  });

  //Ng Notify config
  ngNotify.config({
    theme: 'pure',
    position: 'top',
    duration: 3000,
    type: 'warn',
    sticky: false,
    button: true,
    html: false
  });
});

function inArray(needle, haystack) {
  for (var i in haystack) {
    if (haystack[i] == needle) return true;
  }
  return false;
}
//