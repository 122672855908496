app.service('Modal', ['_', '$uibModal',
  function (_, $uibModal) {
    var defaultModalOptions = {
      animation: false,
      backdrop: true,
      keyboard: true,
      size: 'md'
    }

    this.confirm = function(modalOptions, context) {
      modalOptions = modalOptions || {};
      context = context || {};

      var confirmDefaultContext = {
        title: 'Confirmation requise',
        text: 'Êtes-vous sûr de vouloir effectuer cette action ?'
      }

      context = _.assignIn(confirmDefaultContext, context);

      var confirmModalOptions = {
        templateUrl: 'partials/ui/confirm.html',
        backdrop: 'static',
        keyboard: false,
        resolve: {
          context: function() { return context; }
        }
      }

      confirmModalOptions.controller = ['$scope', '$uibModalInstance', 'context',
        function($scope, $uibModalInstance, context) {
          $scope.context = context;

          $scope.confirm = function () {
            $uibModalInstance.close('confirm');
          };

          $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
          };
        }
      ]

      var options = _.assignIn(defaultModalOptions, confirmModalOptions, modalOptions);

      return $uibModal.open(options).result;
    }
  }
])
