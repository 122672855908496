'use strict';

app.controller('ContactCtrl', ['$scope', '$window', '$q', '_', 'Modal', 'Network', 'Creche', 'Contact', '$state', '$stateParams', 'Notif', function ($scope, $window, $q, _, Modal, Network, Creche, Contact, $state, $stateParams, Notif) {
  $scope.pagination = {
        total: 0,
        skip: 0,
        currentPage: 1,
        limit: 20
    };

    $scope.params = {};
    $scope.search = {};
    $scope.options = {
        sort: 'created',
        orderBy: -1,
        populate: ''
    };

    $scope.contacts = [];

    $scope.loader = false;
    $scope.isEditingContact = -1;

    $scope.types = [
      {
        label: 'Réseau',
        value: 'network'
      },
      {
        label: 'Entreprise',
        value: 'company'
      },
      {
        label: 'Crèche',
        value: 'creche'
      }
    ];

    /* List page Method */

    /**
     * Init method to get all networks from the database
     */
    $scope.initContacts = function () {
        // Display loader
        $scope.loader = true;

        // Get data source count
        //$scope.getCount();

        // Load URL params
        $scope.loadStateParams();

        // Get data
        setTimeout(function () {
            $scope.changeList();
            $scope.loader = false;
        }, 500);
    };

    $scope.loadStateParams = function() {
      if($stateParams.limit) {
        var limit = parseInt($stateParams.limit);
        if(_.isNaN(limit) || limit < 1) limit = 20;
        $scope.pagination.limit = $scope.params.limit = limit;
      }
      if($stateParams.page) {
        var page = parseInt($stateParams.page);
        if(_.isNaN(page) || page < 1) page = 1;
        $scope.pagination.currentPage = page;
      }

      if (!_.isUndefined($stateParams.types)) {
        $scope.params.types = [];
        var idx = -1;
        _.forEach(_.split($stateParams.types, ';'), function(value) {
          if ((idx = _.findIndex($scope.types, { value: value })) > -1) $scope.params.types.push($scope.types[idx]);
        });
      }

      if (!_.isUndefined($stateParams.phone)) {
        var phone = parseInt($stateParams.phone);
        if (!_.isNaN(phone)) {
          $scope.params.phone = phone.toString();
        }
      }

      if (!_.isUndefined($stateParams.email)) {
        $scope.params.email = $stateParams.email;
      }

      if (!_.isUndefined($stateParams.search)) {
        $scope.search.text = $stateParams.search;
      }

      $scope.params.flags = $stateParams.flags ? $stateParams.flags.split(',') : [];

      $scope.getCount();

    };

    $scope.getFlags = function() {
        // we want an array of strings instead of the array of object the tags-input module create
        //todo: upgrade tags-input to 3.2 to be able to use strings
        return $scope.params.flags.map(function(flag) {
            return flag.text ? flag.text : flag;
        })
    }

    $scope.buildUrlParams = function () {
      var params = {
        email: '',
        phone: '',
        type: '',
        search: '',
        flags: '',
      };
      if (!_.isEmpty(_.get($scope.params, 'email', ''))) params['email'] = $scope.params.email;
      if (!_.isEmpty(_.get($scope.params, 'phone', ''))) params['phone'] = $scope.params.phone;
      if (_.get($scope.params, 'types', []).length > 0) {
        var values = convertTypes($scope.params.types); // Get only values inside an array
        params['types'] = _.join(values, ';');
      }
      if (!_.isEmpty(_.get($scope.search, 'text', ''))) params['search'] = $scope.search.text;
      if($scope.params.flags.length) params.flags = $scope.getFlags().join(',');

      params.page = $scope.pagination.currentPage;
      params.limit = $scope.pagination.limit;

      var state = 'contact';
      $state.go(state, params, {
        // prevent the events onStart and onSuccess from firing
        notify: false,
        // prevent reload of the current state
        reload: false,
        // replace the last record when changing the params so you don't hit the back button and get old params
        location: 'replace',
        // inherit the current params on the url
        inherit: true
      });

    };

    /**
     * Reset pagging method
     * @param total
     */
    $scope.resetPaging = function (total) {
        $scope.pagination.total = total;
        $scope.pagination.offset = 0;
        $scope.pagination.currentPage = 1;
    };

    /**
     * Method called when the user changes the pagination
     */
    $scope.changeList = function () {
        // Set options for query
        var options = _.clone($scope.options);
        options.skip = ($scope.pagination.currentPage - 1) * $scope.pagination.limit;
        options.limit = $scope.pagination.limit;

        var params = _.clone($scope.params);
        params.types = convertTypes($scope.params.types); // Get only values inside an array
        params.flags = $scope.getFlags();
        //options.populate = true;

        var search = _.clone($scope.search);

        // Perform the query
        Contact.query({params: params, search: search, options: options},
          function (data) {
            var networks = _.map(_.filter(data, ['type', 'network']), function(o) { return o._id; });
            var creches = _.map(_.filter(data, ['type', 'creche']), function(o) { return o._id; });
            var companies = _.map(_.filter(data, ['type', 'company']), function(o) { return o._id; });

            async.parallel([
              function(callback) {
                if (networks.length == 0) return callback(null, []);
                var opt = {};
                opt.populate = 'Network';
                Contact.query({params: {ids: networks}, search: {}, options: opt}, function(data) {
                  callback(null, data);
                });
              },
              function(callback) {
                if (companies.length == 0) return callback(null, []);
                var opt = {};
                opt.populate = 'Company';
                Contact.query({params: {ids: companies}, search: {}, options: opt}, function(data) {
                  callback(null, data);
                });
              },
              function(callback) {
                if (creches.length == 0) return callback(null, []);
                var opt = {};
                opt.populate = 'Creche';
                Contact.query({params: {ids: creches}, search: {}, options: opt}, function(data) {
                  callback(null, data);
                });
              }
            ], function(err, results) {
              $scope.contacts = _.sortBy(_.flatten(results), [$scope.options.sort, '-created']);
            });

          });
    };

    $scope.searchFlags = function(query) {
        if(query.length < 2) return [];
        var deferred = $q.defer();
        Contact.flags({label: query}, function(flags) {
            deferred.resolve(flags);
        });
        return deferred.promise;
    }

    /**
     * Return count of the datasource based on params
     */
    $scope.getCount = function () {
        // Perform the query
        var params = _.clone($scope.params);
        params.flags = $scope.getFlags()

        params.types = convertTypes($scope.params.types); // Get only values inside an array

        Contact.count({params: params, search: $scope.search},
            function (data) {
              $scope.pagination.total = data.count;
                //$scope.resetPaging(data.count);
            });
    };

    $scope.getType = function(type) {
      return _.find($scope.types, ['value', type]).label;
    };

    /**
     * Filter list based on search and params
     */
    $scope.filterList = function () {
        // Display loader dot the list
        $scope.loader = true;

        // Get count
        $scope.getCount();

        // Change URL params
        $scope.buildUrlParams();

        // Get data
        setTimeout(function () {
            $scope.changeList();
            $scope.loader = false;
        }, 500);
    };

    var convertTypes = function(types) {
      return _.map(types, function(o) { return o.value; });
    };


    /**
     * Sort list by key
     * @param key
     */
    $scope.sortList = function (key) {
        $scope.loader = true;
        $scope.options.sort = key;
        $scope.options.order = $scope.options.order == 'DESC' ? 'ASC' : 'DESC';

        //Get data
        setTimeout(function () {
            $scope.changeList();
            $scope.loader = false;
        }, 500);
    };


    /**
     * Delete Contact
     * @param contact
     */
    $scope.delete = function (contact) {
        Modal.confirm().then(function (result) {
            Contact.delete(contact, function () {
                Notif.success('Le contact a bien été supprimé !');
                $scope.initContacts();
            }, Notif.error);
        });
    };

    $scope.paginate = function() {
      $scope.buildUrlParams();

      setTimeout(function() {
        $scope.changeList();
        $scope.loader = false;
      }, 500);
    }

    $scope.exportList = function(format) {
      // Set options for query
      var options = _.clone($scope.options);
      var params = _.clone($scope.params);
      params.types = convertTypes(params.types);

      params = encodeURIComponent(JSON.stringify(params));
      var options = encodeURIComponent(JSON.stringify(options));
      var search = encodeURIComponent(JSON.stringify($scope.search));

      $window.open('/api/contact/export.' + format + '?params=' + params + '&search=' + search + '&options=' + options, '_blank');
    };


}]);
