'use strict';

app.controller('RootCtrl', ['$scope', '$rootScope', '$interval', '$state', 'AuthService', 'ngNotify', '_','Notification', 'Application', function ($scope, $rootScope, $interval, $state, AuthService, ngNotify, _, Notification, Application) {
    $scope.rootScope = $rootScope;
    $scope.notifications = [];
    $scope.applications = [];
    var self = this;
    this.bodyClass = '';
    $scope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
      if (angular.isDefined(toState.data.bodyClass)) {
        self.bodyClass = toState.data.bodyClass;
      }
    });

    $scope.auth = AuthService.getAuthenticatedUser();

    $scope.logout = function() {
        AuthService.logout()
            .success(function() {
                ngNotify.set("Vous avez bien été déconnecté", 'success');
                $state.go('auth');
            });
    }

    $scope.getNotifications = function(cb) {
      cb = cb || function() {};
      if(AuthService.isAuthenticated()) {
        var auth = AuthService.getAuthenticatedUser();
        if(auth && auth.user && auth.user._id) {
          var query = { params: { user: auth.user._id, read: false }, options: { sort: 'created', order: 'ASC' } };
          Notification.query(query, function(data) {
            $scope.notifications = _.reverse(data);
            return cb();
          });
        } else return cb();
      } else return cb();
    }
    
    async.series([
      function(cb){
        if(AuthService.isAuthenticated() && $scope.applications.length === 0) {
          Application.query({}, function(data) {
            _.each(function(app) {
              $scope.applications[app._id] = app;
            })
            return cb();
          });
        } else return cb();
      }
    ], function() {
      $scope.getNotifications();
      $interval(function() { $scope.getNotifications(); }, 600000); 
    });

    $scope.readNotification = function(notification) {
      notification.read = true;
      $scope.notifications = _.filter($scope.notifications, { read: false });
      Notification.update({ _id: notification._id, read: true });
    }

    $rootScope.toggleDialer = function() {
      $rootScope.dialer.visible = !$rootScope.dialer.visible || false;
    }
}]);
