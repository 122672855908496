app.directive("crechesMap", ['$q', '_', 'Geocoder', 'Direction', 'Network', 'CrecheStatus', 'uiGmapIsReady', 'ngNotify', 'Creche', function($q, _, Geocoder, Direction, Network, CrecheStatus, uiGmapIsReady, ngNotify, Creche) {
  return ({
    restrict: "E",
    scope : {
      creches: '=',
      showFilter: '=',
      address: '=',
      location: '=',
      familyId : '=',
      networkId: '=',
      loading: '=',
      total: '=',
      addressCompany: '='
    },
    templateUrl : "/js/directives/templates/crechesMap.html",
    link: function link($scope) {
      //Default Map
      $scope.clustersMarker = [];
      $scope.center = {
        latitude: 48.856614,
        longitude: 2.352222
      };
      $scope.map = {
        center: angular.copy($scope.center),
        zoom: 14,
        options: {
          disableDefaultUI: true,
          zoomControl: true,
          scrollwheel: false
        },
        control: {}
      };

      var crechesMarker = [];
      var clustersData = [];
      var crechesData = $scope.creches;
      var filterAllOnce = false;
      var clusterConf = {
        minZone: 15,
        maxZone: 16,
        inc: 1,
        minCluster: 15
      };

      //Only one creche
      $scope.crechesMarker = $scope.creches;
      $scope.oneCreche = false;
      var crechesFilteredRemaining = [];
      if ($scope.crechesMarker.length == 1) {
        $scope.oneCreche = true;
        $scope.creche = $scope.crechesMarker[0];
      }
      $scope.searchbox = {
        template:'searchbox.tpl.html',
        events: {
          place_changed: function (searchBox) {
            if ($scope.searchLocation && searchBox) {
              var place = searchBox.getPlace();
              var addr = (place.formatted_address ? place.formatted_address : place.name);
              $scope.searchLocation(addr);
            }
          }
        },
        parentdiv: 'pamSearchBox',
        options: {
          autocomplete: true,
          componentRestrictions: {
            country: 'fr'
          }
        }
      };

      uiGmapIsReady.promise(1).then(function() {
        var pamSearchField = document.getElementById("pamSearchTextField");
        var map = $scope.map.control.getGMap();
        if ($scope.oneCreche) {
          //One creche

          //Init Map
          if ($scope.creche.location) {
            $scope.loading = true;

            $scope.center = $scope.creche.location;
            $scope.map.center = angular.copy($scope.center);

            $scope.$watch('creche.location', function() {
              $scope.center = $scope.creche.location;
              $scope.map.center = angular.copy($scope.center);
              if (queryAndFilter)
                queryAndFilter();

            });

            $scope.loading = false;

          }
        } else {
          //Multiple CrechesMarker

          //Init Bounds
          /*$scope.initBounds = function() {
            var bounds = new google.maps.LatLngBounds();
            for (var i = 0; i < $scope.crechesMarker.length; i++) {
            if (!_.isUndefined($scope.crechesMarker[i].location)) {
            var location = $scope.crechesMarker[i].location;
            if (!_.isUndefined(location.latitude) && !_.isUndefined(location.longitude)) {
            var latLng = new google.maps.LatLng({lat: location.latitude, lng: location.longitude});
            bounds.extend(latLng);
            }
            }
            }
            $scope.map.control.getGMap().fitBounds(bounds);
            $scope.map.control.getGMap().setZoom($scope.map.control.getGMap().getZoom() - 1);
            };

            if (!$scope.locations && !$scope.address) {
            $scope.initBounds();
            }*/

          //Init Window

          $scope.currentCreche = null;
          $scope.openWindowCreche = function(marker) {
            $scope.closeWindowCluster();
            $scope.currentCreche = marker.model;

            //Change end direction with clicked creche address
            if ($scope.directions) {
              var address = (_.get($scope.currentCreche, 'address.street', "").toString() + " " + _.get($scope.currentCreche, 'address.zipCode', "").toString() + " " + _.get($scope.currentCreche, 'address.city', "").toString()).trim();
              $scope.directions.end = address;

            }
          };
          $scope.closeWindowCreche = function() {
            $scope.currentCreche = null;
          };
          $scope.currentCluster = null;
          $scope.openWindowCluster = function(marker) {
            $scope.closeWindowCreche();
            $scope.currentCluster = marker.model;
          };
          $scope.closeWindowCluster = function() {
            $scope.currentCluster = null;
          };

          //Init Location
          if (!_.isUndefined($scope.location)) {
            $scope.loading = true;

            $scope.center = $scope.location;
            $scope.map.center = angular.copy($scope.center);
            $scope.map.zoom = 14;

            $scope.$watch('location', function() {
              $scope.center = $scope.location;
              $scope.map.center = angular.copy($scope.center);
            });

            $scope.loading = false;
          }

          //Search
          $scope.search = {
            text: '',
            location : null
          };

          function unionCreche(data) {
            _.each(data, function (creche) {
              var icon = 'creche_error';
              if(creche.status && creche.status.label)
                icon = 'creche_' + slug(creche.status.label, {'lower': true});
              creche.icon = '/public/image/' + icon + '.png';
            });
            crechesData = _.unionBy(crechesData, data, function (e) {
              return e._id;
            });
          }

          var lastBox = undefined;
          function queryAndFilter() {
            var b = $scope.map.control.getGMap().getBounds();

            var la = [b.getSouthWest().lng(), b.getNorthEast().lng()];
            var ea = [b.getSouthWest().lat(), b.getNorthEast().lat()];

            var box = [la, ea];
            var params = {box: box, lastBox: lastBox};
            Creche.query({ params: params, search: {}, options: { populate: true } }, function (data) {
              unionCreche(data);
              lastBox = box;
              $scope.filter();
            });
          }
          $scope.searchLocation = function(text, cb) {
            if (!text) {
              text = pamSearchField.value;
            }
            Geocoder.getFromAddress(text)
              .then(function (result) {
                var location = result.geometry.location;
                var geoPoint = {
                  latitude: location.lat(),
                  longitude : location.lng()
                };
                $scope.search.location = geoPoint;
                $scope.center = geoPoint;
                $scope.map.center = angular.copy($scope.center);
                $scope.map.zoom = 14;
                $scope.search.text = result.formatted_address;
                queryAndFilter();
                if (!_.isUndefined(cb)) {
                  cb();
                }
              });
          };
          //Init Address
          if ($scope.address) { //Default value
            $scope.loading = true;

            $scope.searchLocation($scope.address, function() {
              $scope.directions.start = $scope.search.text;
              pamSearchField.value = $scope.search.text;
              $scope.directions.company = $scope.addressCompany;
              $scope.loading = false;
            });
          }

          var directions;
          //Filters
          directions = {
            start: document.getElementById("pamDirectionsStart"),
            end: document.getElementById("pamDirectionsEnd"),
            company: document.getElementById("pamDirectionsCompany")
          };
          if (_.isUndefined(directions.start) === false) {
            directions.options = {
              componentRestrictions: {
                country: 'fr'
              }
            };
            directions.autoCompleteStart = new google.maps.places.Autocomplete(directions.start, directions.options);
            directions.autoCompleteEnd = new google.maps.places.Autocomplete(directions.end, directions.options);
            directions.autoCompleteCompany = new google.maps.places.Autocomplete(directions.company, directions.options); // Maybe need to check if undefined or not
          }
          var inBound = function(data) {
            var b = map.getBounds();
            var x = {lat: function () {return this.a[1];}, lng: function () {return this.a[0];}, a: []};
            var inBounds = _.filter(data, function(c) {
              if (c.location) {
                x.a = c.location;
                x.lat = x.a[1];
                x.lng = x.a[0];
                return b.contains(x);
              }
              return false;
            });
            return inBounds;
          };
          $scope.$watchCollection('creches', function () {
            unionCreche($scope.creches);
            inBound(crechesData);
            $scope.filter();
          });
          function queryOnEvent() {
            if (crechesData.length !== $scope.total) {
              queryAndFilter();
            } else if (filterAllOnce === false) {
              queryAndFilter();
              filterAllOnce = true;
            } else {
              $scope.crechesMarker = inBound($scope.crechesFiltered);
              if ($scope.crechesMarker.length > 250) {
                $scope.clustersMarker = clusterize(clusterConf.minZone, clusterConf.maxZone, clusterConf.inc, clusterConf.minCluster);
                if ($scope.clustersMarker.length)
                  $scope.crechesMarker = [];
              } else {
                $scope.clustersMarker = [];
              }
            }
          }
          var tmpBound = null;
          map.addListener("dragstart", function () {
            $scope.closeWindowCreche();
            $scope.closeWindowCluster();
          });

          map.addListener("dragend", function () {
            tmpBound = map.getBounds();
            queryOnEvent();
          });
          map.addListener("zoom_changed", function () {
            $scope.closeWindowCreche();
            $scope.closeWindowCluster();
            clustersData = [];
            crechesFilteredRemaining = [];
            if (crechesData.length != $scope.total) {
              tmpBound = map.getBounds();
              queryOnEvent();
            }
          });
          map.addListener("idle", function() {
            if (tmpBound !== map.getBounds()) {
              queryOnEvent();
            }
          });


          //Radius
          $scope.radiuStroke = {
            color: '#3c8dbc',
            weight: 2,
            opacity: 1
          };
          $scope.radiusFill = {
            color: '#3c8dbc',
            weight: 2,
            opacity: 0.2
          };
          $scope.radiusList = [
            {
              value : 0,
              label : "Illimité"

            },
            {
              value : 500,
              label : "500 m"

            },
            {
              value : 1000,
              label : "1 km",
              zoom : 14

            },
            {
              value : 3000,
              label : "3 km"

            },
            {
              value : 5000,
              label : "5 km"

            },
            {
              value : 10000,
              label : "10 km"

            },
            {
              value : 30000,
              label : "30 km"

            }
          ];
          $scope.radius = $scope.radiusList[0];

          //Directions
          $scope.directionsModeList = [
            {
              value : google.maps.TravelMode.DRIVING,
              label : 'Voiture',
              is_active : true
            },
            {
              value : google.maps.TravelMode.WALKING,
              label : 'Marche',
              is_active : true
            },
            {
              value : google.maps.TravelMode.TRANSIT,
              label : 'Transport en commun',
              is_active : false
            }
          ];
          $scope.directions = {
            start : '',
            end : '',
            company : '',
            mode : $scope.directionsModeList[0],
            info : null,
            display : new google.maps.DirectionsRenderer({
              map: $scope.map.control.getGMap()
            })
          };
          $scope.getDirections = function() {
            if (directions) {
              $scope.directions.start = directions.start.value;
              $scope.directions.end = directions.end.value;
              $scope.directions.company = directions.company.value;
            }

            // Get checkBox value to enable/disable Third Field
            $scope.checkField = document.getElementById("checkBox").checked;

            if (!_.isEmpty($scope.directions.start) && !_.isEmpty($scope.directions.end)) {
              var directionWaypoint = null;
              var directionEnd = $scope.directions.end;

              // GoogleMaps can't manage to search a path with waypoint if mode is TRANSIT
              if (!_.isEmpty($scope.directions.company) && $scope.directions.mode.is_active && $scope.checkField) {
                directionWaypoint = _.clone($scope.directions.end);
                directionEnd = _.clone($scope.directions.company);
              }

              Direction.getDirections($scope.directions.start, directionEnd, directionWaypoint, $scope.directions.mode.value)
                .then(function (directions) {
                  $scope.directions.display.setDirections(directions);

                  if (directions.routes.length > 0) {
                    var route = directions.routes[0];
                    if (route.legs.length > 0 ) {
                      var leg = route.legs[0];
                      $scope.directions.start = leg.start_address;
                      $scope.directions.end = directionWaypoint ? directionWaypoint : leg.end_address;
                      $scope.directions.company = directionWaypoint ? leg.end_address : $scope.directions.company;
                      $scope.directions.info = leg.distance.text + ' - ' + leg.duration.text
                    }
                  }
                }, function(status) {
                  if (status == "NOT_FOUND") {
                    ngNotify.set("Aucun itinéraire n'a été trouvé. Essayer de préciser les adresses par exemple en rajoutant la ville.", {type:'warn', duration:5000});
                  }
                });
            }
          };
          $scope.removeDirections = function() {
            $scope.directions.start = '';
            $scope.directions.end = '';
            $scope.directions.company = '';
            $scope.directions.mode = $scope.directionsModeList[0];
            $scope.directions.info = null;
            $scope.directions.display.setDirections({routes:[]});
          };

          //Network
          $scope.networks = [];
          $scope.networkList = [];

          //Init networks
          Network.query({}, function (data) {
            data.sort(function (a, b) {
              return a.name > b.name;
            });
            $scope.networkList = data;

            if($scope.networkId){
              $scope.item = _.find($scope.networkList, function (item) {
                return item._id == $scope.networkId;
              });

              $scope.networks.push($scope.item);

              $scope.filter();
            }
          });

          $scope.searchNetworks = function (query) {
            query = query.toLowerCase();
            var deferred = $q.defer();
            var result = _.filter($scope.networkList, function (network) {
              return _.startsWith(network.name.toLowerCase(), query);
            });
            deferred.resolve(result);
            return deferred.promise;
          };

          // Status
          $scope.statuses = [];

          $scope.isActiveStatus = function(statuses) {
            if (_.intersection($scope.statuses, statuses).length > 0) {
              return true;
            } else {
              return false;
            }
          };

          $scope.filterByStatus = function(statuses) {
            if ($scope.isActiveStatus(statuses)) {
              //Remove
              $scope.statuses = _.difference($scope.statuses, statuses);
            } else {
              //Add
              $scope.statuses = _.concat($scope.statuses, statuses);
            }

            //Filter
            $scope.filter();
          };

          // Type
          $scope.types = [];

          $scope.isActiveType = function(types) {
            if (_.intersection($scope.types, types).length > 0) {
              return true;
            } else {
              return false;
            }
          };

          $scope.filterByType = function(types) {
            if ($scope.isActiveType(types)) {
              //Remove
              $scope.types = _.difference($scope.types, types);
            } else {
              //Add
              $scope.types = _.concat($scope.types, types);
            }

            //Filter
            $scope.filter();
          };



          // divide viewport in multiple zone
          function makeZones(numZone, bounds, data) {
            var zones = [];
            var offset = {x: bounds.b[0] - bounds.a[0], y: bounds.b[1] - bounds.a[1]};
            offset.x /= numZone;
            offset.y /= numZone;
            var begY = bounds.a[1];
            var endY = bounds.a[1] + offset.y;
            for (var y = 0; y < numZone; y++) {
              zones[y] = [];
              var begX = bounds.a[0];
              var endX = bounds.a[0] + offset.x;
              for (var x = 0; x < numZone; x++) {
                zones[y][x] = _.filter(data, function (e) {
                  if (e.location && begX <= e.location[0] && endX >= e.location[0]
                      && begY <= e.location[1] && endY >= e.location[1])
                    return true;
                  return false;
                });
                zones[y][x].location = {
                  begX: begX, endX: endX, begY: begY, endY: endY
                };
                begX = endX;
                endX += offset.x;
              }
              begY = endY;
              endY += offset.y;
            }
            return zones;
          }

          function newCluster() {
            return {
              location: [0, 0],
              labels: [
                ["Dédiée", 0],
                ["Prioritaire", 0],
                ["Disponible", 0],
                ["A valider", 0],
                ["Pas disponible", 0],
                ["A recontacter", 0],
                ["Identifier", 0],
                ["Concurrent", 0],
                ["Indéfini", 0]
              ],
              total: 0
            };

          }

          // clusterize markers to close to each other
          function clusterizeMarkers(markers) {
            var cluster = newCluster();
            var labelLength = cluster.labels.length;
            _.each(markers, function (m) {
              cluster.location[0] += m.location[0];
              cluster.location[1] += m.location[1];
              for (var i = 0; i < labelLength-1; i++) {
                if (m.status && cluster.labels[i][0] === m.status.label) {
                  cluster.labels[i][1] += 1;
                  break;
                }
                else if(!m.status) {
                  cluster.labels[labelLength-1][1] += 1;
                  break;
                }
              }
            });
            cluster.location[0] /= markers.length;
            cluster.location[1] /= markers.length;
            cluster._id = cluster.location.toString();
            _.each(cluster.labels, function (e) {
              cluster.total += e[1];
            });
            return cluster;
          }

          // fuse clusters too close to each other
          function fuseCluster(clusters) {
            var bounds = map.getBounds();
            var distance = google.maps.geometry.spherical.computeDistanceBetween(
              {lat: function() { return bounds.b.b; }, lng: function() { return bounds.f.f; } },
              {lat: function() { return bounds.b.f; }, lng: function() { return bounds.f.b; } }
            );
            distance *= 0.015;
            var len = clusters.length;
            var result = [];
            var x = 0;
            var used = [];
            for (var j = len - 1; j > 0; j--) {
              if (used.indexOf(clusters[j]) != -1)
                continue;
              var c = clusters[j];
              var toFuse = [];
              for (var i = j-1; i >= 0; i--) {
                if (used.indexOf(clusters[i]) != -1)
                  continue;
                var d = google.maps.geometry.spherical.computeDistanceBetween(c, clusters[i]);
                if (d < distance) {
                  toFuse = toFuse.concat(clusters[i]);
                  used.unshift(clusters[i]);
                }
              }
              if (toFuse.length) {
                used.unshift(clusters[j]);
                toFuse = toFuse.concat(clusters[j]);
                var cluster = newCluster();
                _.each(toFuse, function (c) {
                  cluster.location[0] += c.location[0];
                  cluster.location[1] += c.location[1];
                  for (var labelI = 0; labelI < cluster.labels.length; labelI++) {
                    cluster.labels[labelI][1] += c.labels[labelI][1];
                    cluster.total += c.labels[labelI][1];
                  }
                });
                cluster.location[0] /= toFuse.length;
                cluster.location[1] /= toFuse.length;
                result = result.concat(cluster);
              }
            }
            _.each(used, function (e) {
              _.remove(clusters, function (c) {
                return c === e;
              });
            });
            _.each(result, function (e) {
              e._id = e.location.toString();
              clusters.unshift(e);
            });
            clustersToMarker(result);
            return clusters;
          }

          var clusterIcon = new google.maps.MarkerImage('/public/image/creche_cluster.png', null,
                                                        new google.maps.Point(0, 0),
                                                        new google.maps.Point(22, 22)
                                                       );

          // add data needed for cluster to be displayed
          function clustersToMarker(clusters) {
            _.each(clusters, function (e) {
              e.icon = clusterIcon;
              var pos = 3;
              var n = e.total;
              while (n >= 10) {
                n /= 10;
                pos += 3;
              }
              e.options = {labelClass: 'mk-cluster', labelContent: e.total, labelAnchor: pos.toString() + " 7"};
              e.lat = function () {
                return e.location[1];
              };
              e.lng = function () {
                return e.location[0];
              };
            });
          }

          function clusterize(zoneNumMin, zoneNumMax, incZone, minCluster) {
            var clustersMarker = [];
            var bounds = map.getBounds();
            var a = [bounds.b.b, bounds.f.f];
            var b = [bounds.b.f, bounds.f.b];
            var zoneNum = zoneNumMin;
            var zones = [];
            var cInBound = [];
            if (crechesFilteredRemaining.length === 0) {
              cInBound = inBound($scope.crechesFiltered);
              crechesFilteredRemaining = _.difference($scope.crechesFiltered, cInBound);
            } else {
              cInBound = inBound(crechesFilteredRemaining);
              crechesFilteredRemaining = _.difference(crechesFilteredRemaining, cInBound);
            }
            do {
              clustersMarker = [];
              zones = makeZones(zoneNum, {a: a, b: b}, cInBound);
              for (var y = 0; y < zones.length; y++) {
                for (var x = 0; x < zones[y].length; x++) {
                  if (zones[y][x].length)
                    clustersData = clustersData.concat(clusterizeMarkers(zones[y][x]));
                }
              }
              zoneNum += incZone;
              clustersMarker = _.uniq(inBound(clustersData));
            } while (clustersMarker.length < minCluster && clustersMarker.length < $scope.crechesFiltered.length && zoneNum < zoneNumMax);
            clustersToMarker(clustersMarker);
            clustersMarker = fuseCluster(clustersMarker);
            return clustersMarker;
          }

          $scope.filter = function() {
            $scope.loading = true;

            $scope.crechesFiltered = crechesData;

            //Filter networks
            if (!_.isEmpty($scope.networks)) {
              var networksIds = _.map($scope.networks, '_id');
              $scope.crechesFiltered = _.filter($scope.crechesFiltered, function(creche) {
                if (creche.network && _.indexOf(networksIds, creche.network._id) > -1) {
                  return true;
                } else {
                  return false;
                }
              });
            }

            //Filter status
            if (!_.isEmpty($scope.statuses)) {
              $scope.crechesFiltered = _.filter($scope.crechesFiltered, function (creche) {
                if ($scope.types.includes(creche.type) && creche.status && creche.status.label && _.indexOf($scope.statuses, creche.status.label) > -1)
                  return true;
                else if(!creche.status && $scope.types.includes(creche.type))
                  return true;
                else
                  return false;
              });
            } else {
              $scope.crechesFiltered = $scope.crechesFiltered ? $scope.crechesFiltered  : [];
            }
            $scope.crechesMarker = inBound($scope.crechesFiltered);

            $scope.clustersMarker = [];
            clustersData = [];
            crechesFilteredRemaining = [];

            if (crechesData.length === $scope.total && $scope.crechesMarker.length > 250) {
              $scope.clustersMarker = clusterize(clusterConf.minZone, clusterConf.maxZone, clusterConf.inc, clusterConf.minCluster);

              if ($scope.clustersMarker.length)
                $scope.crechesMarker = [];
            }
            $scope.loading = false;
          };

          $scope.filterByStatus(['Dédiée', 'Prioritaire', 'Disponible','A valider','Pas disponible']);
          $scope.filterByType(['Crèche', 'Micro-crèche']);
          queryAndFilter();

          $('#microcrecheI').click(function() {
            $(this).toggleClass('fa fa-eye-slash');
            $(this).toggleClass('fa fa-eye');
          })
          $('#microcrecheA').click(function() {
            $('#microcrecheI').toggleClass('fa fa-eye-slash');
            $('#microcrecheI').toggleClass('fa fa-eye');
          })
        }
      });
    }
  });
}]);
