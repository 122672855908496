'use strict';

app.controller('CodirCtrl', ['$scope', '$window', '_', '$q', '$state', '$stateParams', 'Contract', 'Notif', 'Company', 'Network', 'Modal', 'moment', 'User', '$uibModal', 'Event',
    function ($scope, $window, _, $q, $state, $stateParams, Contract, Notif, Company, Network, Modal, moment, User, $uibModal, Event) {
        $scope.values = [];
        $scope.$state = $state;
        $scope.loader = false;
        $scope.pending = false;
        $scope.now = new Date();
        $scope.dateFormat = 'dd/MM/yyyy';
        $scope.dateOptions = {
            startingDay: 1
        };

        $scope.popups = [];
        $scope.open = function (idx) {
            console.log(idx);
            console.log($scope.popups);
            _.set($scope, 'popups[' + idx + '].opened', true);
        };

        $scope.pagination = {
            total: 0,
            skip: 0,
            currentPage: 1,
            limit: 20
        };

        $scope.params = {
            type: "family"
        };
        $scope.search = {};
        $scope.options = {
            sort: 'name',
            order: 'DESC'
        };

        $scope.mode = $stateParams.mode;
        $scope.query = {
            contracts: [],
            creches: [],
            companies: [],
            applications: [],
            statuses: [],
            users: [],
            sites: [],
            networks: [],
            date: {},
            locked: null,
            mail: null,
            states: []
        };

        $scope.initData = function () {
            // Display loader dot the list
            $scope.loader = true;

            $scope.loadContracts();

        };

        $scope.loadContracts = function () {
            $scope.loader = true;
            $scope.getCount(function () {
                loadStateParams();
                $scope.changeList();
                $scope.loader = false;
            });
        };

        $scope.exportList = function (format) {
            var options = encodeURIComponent(JSON.stringify($scope.options));
            var params = encodeURIComponent(JSON.stringify($scope.params));
            var search = encodeURIComponent(JSON.stringify($scope.search));

            $window.open('/api/codir/exportca.' + format + '?params=' + params + '&search=' + search + '&options=' + options, '_blank');
        };

        $scope.changeList = function (cb) {
            var options = _.clone($scope.options);
            options.skip = ($scope.pagination.currentPage - 1) * $scope.pagination.limit;
            $scope.pagination.skip = options.skip;
            options.limit = $scope.pagination.limit;
            options.populate = true;

            async.series([
                function (cb) {
                    Contract.query({
                        params: $scope.params,
                        search: $scope.search,
                        options: options
                    }, function (contracts) {
                        $scope.contracts = contracts;
                        cb();
                    }, cb);
                },
                function (cb) {
                    async.each($scope.contracts, function (contract, cb) {
                        const rythm = contract.rythm;
                        contract.price.buyPartial = calculatePartial(contract.price.buy, rythm);
                        contract.price.salePartial = calculatePartial(contract.price.sale, rythm);
                        contract.price.marge = contract.price.sale - contract.price.buy;
                        contract.price.margePartial = contract.price.salePartial - contract.price.buyPartial;
                        // console.log(contract);
                        cb();
                    }, cb);
                },
                function (cb) {
                    async.each($scope.contracts, function (contract, cb) {
                        getNetwork(contract, cb);
                        cb();
                    }, cb);
                },
                function(cb) {
                    async.each($scope.contracts, function(contract, cb){
                        getAffectedSale(contract, cb);
                    }, cb);
                },
            ], function (err) {
                if (err) Notif.error(err);
                $scope.loader = false;
            });
        };

        const getNetwork = function (contract, cb) {
            if (contract.creche) {
                Network.get({_id: contract.creche.network}, function (networkDoc) {
                    contract.creche.networkDoc = networkDoc;
                }, cb);
            }
        };

        const getAffectedSale = function ( contract, cb) {
            if (contract.family) {
                User.get({_id: contract.family.user}, function(userDoc) {
                    contract.family.affectedSale = userDoc;
                    cb();
                }, cb);
            } else {
                console.log('Pas de famille', contract);
            }
        };

        var getCount = function (cb) {
            Contract.count({params: $scope.params, search: $scope.search}, function (data) {
                if (!data) return cb();
                $scope.resetPaging(data.count);
                return cb();
            }, Notif.error);
        };

        const getCompany = function (contract, cb) {
            console.log(contract);
            Company.get({_id: contract.company}, function (companyDoc) {
                if (!companyDoc || !companyDoc._id) return cb();
                contract.companyName = companyDoc.name;
            }, cb);
        };

        $scope.getDisplayName = function (user) {
            return user ? user.firstname + " " + user.lastname : '-';
        }

        $scope.filterList = function () {
            _.each(['contracts', 'creches', 'companies', 'applications', 'statuses', 'users', 'sites', 'networks'], function (key) {
                $scope.params[key] = _.map($scope.query[key], '_id');
            });
            $scope.params.date = {
                start: $scope.query.date.start,
                end: $scope.query.date.end,
                month: $scope.query.date.month
            };
            console.log($scope.params);

            $scope.params.locked = getBoolean($scope.query.locked);
            $scope.params.mail = getBoolean($scope.query.mail);

            _.set($scope, 'params.states', _.get($scope, 'query.states', []));
            //$scope.params.state = $scope.query.states;

            buildStateParams();
            getCount(function () {
                $scope.changeList();
            });
        };

        var getBoolean = function (boolString) {
            switch (boolString) {
                case 'true':
                    return true;
                case 'false':
                    return false;
            }
            return undefined;
        };

        var buildStateParams = function () {
            var params = {};
            params.search = $scope.search.text;
            _.each(['creches', 'users', 'companies', 'applications', 'contracts', 'statuses', 'sites', 'networks'], function (key) {
                params[key] = _.get($scope.params, key, []).join(';');
            });

            params.dateStart = _.get($scope.params, 'date.start');
            params.dateEnd = _.get($scope.params, 'date.end');
            params.dateMonth = _.get($scope.params, 'date.month');
            if (params.dateStart) params.dateStart = moment(params.dateStart).startOf('day').format('DD-MM-YYYY');
            if (params.dateEnd) params.dateEnd = moment(params.dateEnd).startOf('day').format('DD-MM-YYYY');
            if (params.dateMonth) params.dateMonth = moment(params.dateMonth).startOf('month').format('MM-YYYY');
            params.locked = _.get($scope.params, 'locked');
            params.mail = _.get($scope.params, 'mail');

            //params.state = _.get($scope.params, 'state');
            params.state = '';
            if (!_.isEmpty($scope.params.states)) {
                angular.forEach($scope.params.states, function (item, key) {
                    if (key == 0) params.state += item;
                    else params.state += ';' + item;
                });
            }

            params.page = $scope.pagination.currentPage;
            params.limit = $scope.pagination.limit;
            params.sort = ($scope.options.order === 'DESC' ? '-' : '') + $scope.options.sort;

        };

        const calculatePartial = function (price, rythm) {
            let buyPartial = 0;
            switch (true) {
                case (rythm >= 5):
                    buyPartial = 0;
                    break;
                case (rythm >= 4):
                    buyPartial = price;
                    break;
                case (rythm >= 3 && rythm < 4):
                    buyPartial = price * 0.8;
                    break;
                case (rythm >= 2 && rythm < 3):
                    buyPartial = price * 0.6;
                    break;
                case (rythm >= 1 && rythm < 2):
                    buyPartial = price * 0.4;
                    break;
            }
            return buyPartial;
        };

        var loadStateParams = function () {
            $scope.pagination.limit = validateIntParams($stateParams.limit, $scope.pagination.limit);
            $scope.choices = _.range($scope.pagination.limit).map(function () {
                return false;
            });
            $scope.pagination.currentPage = validateIntParams($stateParams.page, 1);
            $scope.query.locked = $stateParams.locked;
            $scope.query.mail = $stateParams.mail;

            //$scope.query.state = $stateParams.state;
            if (!_.isUndefined($stateParams.state)) {
                if ($stateParams.state.indexOf(';') != -1) {
                    $scope.query.states = $stateParams.state.split(';');
                } else {
                    $scope.query.states = [$stateParams.state];
                }
            }

            if ($stateParams.dateStart) {
                var dStart = moment.utc($stateParams.dateStart, 'DD-MM-YYYY').toDate();
                _.set($scope.params, 'date.start', dStart);
                $scope.query.date.start = dStart;
            }
            if ($stateParams.dateEnd) {
                var dEnd = moment.utc($stateParams.dateEnd, 'DD-MM-YYYY').toDate();
                _.set($scope.params, 'date.end', dEnd);
                $scope.query.date.end = dEnd;
            }
            if ($stateParams.sort) {
                if ($stateParams.sort[0] === '-') {
                    $scope.options.order = 'DESC';
                    $scope.options.sort = $stateParams.sort.substr(1);
                } else {
                    $scope.options.order = 'ASC';
                    $scope.options.sort = $stateParams.sort;
                }
            }
            _.each(['creches', 'users', 'companies', 'applications', 'contracts', 'statuses', 'sites', 'networks'], function (key) {
                if ($stateParams[key]) $scope.params[key] = $stateParams[key].split(';');
                else $scope.params[key] = [];
            });
            if ($stateParams.search) $scope.search.text = $stateParams.search;
            if ($stateParams.locked) $scope.params.locked = $stateParams.locked;
            if ($stateParams.mail) $scope.params.mail = $stateParams.mail;
            if ($stateParams.state) $scope.params.states = $stateParams.state.split(';');

        };

        var validateIntParams = function (s, defaultValue) {
            defaultValue = (_.isUndefined(defaultValue) ? 1 : defaultValue);
            if (!s) return defaultValue;
            var v = parseInt(s);
            if (_.isNaN(v) || v < 1) v = defaultValue;
            return v;
        };

        $scope.getCount = function (cb) {
            Contract.count({params: $scope.params, search: $scope.search}, function (data) {
                if (data) {
                    $scope.resetPaging(data.count);
                    if (cb) cb();
                }
            }, Notif.error);
        };

        $scope.resetPaging = function (total) {
            $scope.pagination.total = total;
            $scope.pagination.skip = 0;
            $scope.pagination.currentPage = 1;
        };

        console.log('Dans CodirCtrl', $scope);
    }]);

/**
 Contract.query({
            params: {}, search: {}, options: {sort: 'name', order: 'ASC'}
        }, function(data) {
            $scope.contracts = data;
        });
 */