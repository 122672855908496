app.service('Geocoder', ['$q', 'uiGmapGoogleMapApi', function($q, uiGmapGoogleMapApi) {

    this.getFromAddress = function(address) {
        var deferred = $q.defer();
        uiGmapGoogleMapApi.then(function() {
            var geocoder = new google.maps.Geocoder();
            geocoder.geocode( { 'address': address}, function(results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    var result = results[0];
                    deferred.resolve(result);
                } else {
                    deferred.reject(google.maps.GeocoderStatus);
                }
            });
        });
        return deferred.promise;
    }

}]);
