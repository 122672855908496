'use strict';

app.controller('CrecheCtrl', ['$scope', '$anchorScroll', '$window', '_', 'Modal', 'Contact', 'Creche', 'Network', '$state', '$stateParams', 'Notif', '$q', '$filter', 'Geocoder', 'Comment', 'CrecheStatus', 'AuthService', 'Application', function ($scope, $anchorScroll, $window, _, Modal, Contact, Creche, Network, $state, $stateParams, Notif, $q, $filter, Geocoder, Comment, CrecheStatus, AuthService, Application) {
    /* Init variables */
    $scope.search = {};
    $scope.params = {};
    $scope.options = {};
    $scope.creches = [];
    $scope.$state = $state;

    $scope.networks = [];
    $scope.applications = [];
    $scope.editCreches = [];
    $scope.result = null;
    $scope.creche = null;
    $scope.crecheMainContact = null;
    $scope.loader = false;
    $scope.crecheSpinner = false;
    $scope.allCrecheChecked = false;
    $scope.isEdit = $stateParams.crecheId ? true : false;
    $scope.newComment = null;
    $scope.contact = {};
    $scope.contacts = [];
    $scope.isEditingContact = -1;

    $scope.options.sort = '_name';
    $scope.options.order = 'ASC';

    $scope.params.statuts = [];
    $scope.params.networks = [];
    $scope.params.zipCodes = [];

    $scope.paginationLimits = [20, 50, 100, 150, 200];

    /* List variable */
    $scope.pagination = {};
    $scope.pagination.total = 0;
    $scope.pagination.limit = $scope.paginationLimits[0];
    $scope.pagination.currentPage = 1;

    /* Regex for form */
    $scope.numberRegex = /^[0-9, .-]+$/;

    /* List page Method */
    $scope.days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    $scope.foodTypes = ['Repas', 'Lait'];
    $scope.tarification = ['PSU', 'PAJE - Forfait', 'PAJE - PSU Like'];

    /* Datepicker options */
    $scope.dateOptions = {
        startingDay: 1
    };

    function getContact(ref, cb) {
        Contact.query({params: {reference: ref._id}}, function (contacts) {
            var i = _.findIndex(contacts, {main: true});
            ref.contact = (i === -1 ? contacts[0] : contacts[i]);
            if (cb) cb();
        }, function (err) {
            if (cb) cb(err);
        });
    }

    /**
     * Load the PAM
     * @param creche
     */
    $scope.locate = function (creche) {
        var crecheAddress = (_.get(creche.address, 'street', "").toString() + " " + _.get(creche.address, 'zipCode', "").toString() + " " + _.get(creche.address, 'city', "").toString()).trim();

        if (creche.network) {
            $state.go('pam', {
                'networkId': creche.network._id,
                'address': crecheAddress
            })
        } else {
            $state.go('pam', {
                'address': crecheAddress
            });
        }
    };

    $scope.isNewState = function () {
        return $state.is('creche-detail.new');
    };

    /**
     * Init method to get all networks from the database
     */
    $scope.initCreches = function () {
        // Display loader dot the list
        $scope.loader = true;

        // Load networks list
        $scope.loadNetworks();

        // Get data source count
        $scope.getCount();

        //Load the statuses
        $scope.loadStatuses();

        //Load stateparams
        $scope.loadStateParams();
    };

    /**
     * Method called when the user changes the pagination
     */
    $scope.changeList = function (cb) {
        // Set options for query
        var options = _.clone($scope.options);
        options.skip = ($scope.pagination.currentPage - 1) * $scope.pagination.limit;
        options.limit = $scope.pagination.limit;
        options.populate = true;

        //Handle params
        var params = _.clone($scope.params);

        params.status = [];
        var successStatus = $scope.buildParams(params.statuts, params.status);
        if (successStatus) delete params.statuts;

        params.network = [];
        var successNetwork = $scope.buildParams(params.networks, params.network);
        if (successNetwork) delete params.networks;

        params.zipCode = [];
        var successZipCode = $scope.buildParams(params.zipCodes, params.zipCode);
        if (successZipCode) delete params.zipCodes;

        // Perform the query
        Creche.query({params: params, search: $scope.search, options: options}, function (data) {
            $scope.creches = data;
            angular.forEach($scope.creches, function (value, key) {
                getContact(value);
            });
            if (cb) cb();
        });
    };

    $scope.exportList = function (format) {
        // Set options for query
        var options = _.clone($scope.options);

        //Handle params
        var params = _.clone($scope.params);

        params.status = [];
        var successStatus = $scope.buildParams(params.statuts, params.status);
        if (!successStatus) delete params.status;

        params.network = [];
        var successNetwork = $scope.buildParams(params.networks, params.network);
        if (!successNetwork) delete params.network;

        params.zipCode = [];
        var successZipCode = $scope.buildParams(params.zipCodes, params.zipCode);
        if (!successZipCode) delete params.zipCode;
        delete params.zipCodes;

        var options = encodeURIComponent(JSON.stringify(options));
        var params = encodeURIComponent(JSON.stringify(params));
        var search = encodeURIComponent(JSON.stringify($scope.search));

        $window.open('/api/creche/export.' + format + '?params=' + params + '&search=' + search + '&options=' + options, '_blank');
    };

    /**
     * Return count of the datasource based on params
     */
    $scope.getCount = function () {
        //Handle params
        var params = _.clone($scope.params);
        params.status = [];
        var successStatus = $scope.buildParams(params.statuts, params.status);
        if (successStatus) delete params.statuts;

        params.network = [];
        var successNetwork = $scope.buildParams(params.networks, params.network);
        if (successNetwork) delete params.networks;

        params.zipCode = [];
        var successZipCode = $scope.buildParams(params.zipCodes, params.zipCode);
        if (successZipCode) delete params.zipCodes;

        // Perform the query
        Creche.count({params: params, search: $scope.search}, function (data) {
            //$scope.resetPaging(data.count);
            $scope.pagination.total = data.count;
        });
    };

    $scope.changeLimit = function () {
        $scope.buildUrlParams();
        $scope.loader = true;
        $scope.changeList(function () {
            $scope.loader = false;
        });
    }

    $scope.paginate = function () {
        $anchorScroll();
        $scope.buildUrlParams();
        $scope.loader = true;
        $scope.changeList(function () {
            $scope.loader = false;
        });
    }

    /**
     * Reset pagging method
     * @param total
     */
    // $scope.resetPaging = function (total) {
    //     $scope.pagination.total = total;
    //     $scope.pagination.offset = 0;
    //     $scope.pagination.currentPage = 1;
    // };

    /**
     * Filter list based on search and params
     */
    $scope.filterList = function () {
        // Display loader dot the list
        $scope.loader = true;

        // Get count
        $scope.getCount();

        // Change url to match filter parameters
        $scope.buildUrlParams();

        $scope.changeList(function () {
            $scope.loader = false;
        });
    };

    /**
     * Sort list by key
     * @param key
     */
    $scope.sortList = function (key) {
        $scope.loader = true;
        $scope.loader = true;
        $scope.options.sort = key;
        $scope.options.order = $scope.options.order == 'DESC' ? 'ASC' : 'DESC';

        $scope.changeList(function () {
            $scope.loader = false;
        });
    };

    /* Build params */
    $scope.buildParams = function (base, real) {
        if (!_.isUndefined(base) && base.length > 0) {
            angular.forEach(base, function (value) {
                if (!_.isUndefined(value._id)) real.push(value._id);
                else if (!_.isUndefined(value.text)) real.push(value.text);
                else if (!_.isUndefined(value)) real.push(value);
            });
            return true;
        }
        else {
            return false;
        }
    };

    /**
     * Load the params from the URL
     */
    $scope.loadStateParams = function () {
        if ($stateParams.limit) {
            var limit = parseInt($stateParams.limit);
            if (_.isNaN(limit) || limit < 1) limit = $scope.paginationLimits[0];
            $scope.pagination.limit = $scope.params.limit = limit;
        }
        if ($stateParams.page) {
            var page = parseInt($stateParams.page);
            if (_.isNaN(page) || page < 1) page = 1;
            $scope.pagination.currentPage = page;
        }

        // Handle async queries
        async.parallel([
            function (callback) {
                if (_.isUndefined($stateParams.networks)) return callback();

                if ($stateParams.networks.indexOf(';') != -1) {
                    var networkIds = $stateParams.networks.split(';');
                    Network.query({params: {ids: networkIds}}, function (data) {
                        angular.forEach(data, function (item, key) {
                            $scope.params.networks.push(item);
                        });
                        return callback();
                    });
                }
                else {
                    Network.get({_id: $stateParams.networks}, function (data) {
                        $scope.params.networks.push(data);
                        return callback();
                    });
                }
            },
            function (callback) {
                if (_.isUndefined($stateParams.status)) return callback();

                if ($stateParams.status.indexOf(';') != -1) {
                    var statusId = $stateParams.status.split(';');
                    CrecheStatus.query({params: {ids: statusId}}, function (items) {
                        angular.forEach(items, function (item, key) {
                            $scope.params.statuts.push(item);
                        });
                        return callback();
                    });
                }
                else {
                    CrecheStatus.get({_id: $stateParams.status}, function (item) {
                        $scope.params.statuts.push(item);
                    });
                    return callback();
                }
            },
            // Zip code
            function (callback) {
                if (!_.isUndefined($stateParams.zipCode)) {
                    if ($stateParams.zipCode.indexOf(';') != -1) {
                        var zipArray = $stateParams.zipCode.split(';');
                        $scope.params.zipCodes = zipArray;
                    } else {
                        $scope.params.zipCodes = [$stateParams.zipCode];
                    }
                }

                return callback();
            },
            function (callback) {
                if (_.isUndefined($stateParams.search)) return callback();

                $scope.search.text = $stateParams.search;
                return callback();
            }
        ], function (err, results) {
            // Get data source count
            $scope.getCount();

            $scope.changeList(function () {
                $scope.loader = false;
            });
        });
    };

    /**
     * Method used to build the url based on the filter params
     */
    $scope.buildUrlParams = function () {
        // Init params object
        var params = {};

        if (!_.isEmpty($scope.search)) {
            params.search = '';
            if (!_.isUndefined($scope.search.text) && $scope.search.text != '') {
                params.search = $scope.search.text;
            }
        }

        // Now go through all search params and add them to URL
        if (!_.isEmpty($scope.params)) {
            // Handle array values such as CA and Status
            params.networks = '';
            if (!_.isEmpty($scope.params.networks)) {
                angular.forEach($scope.params.networks, function (item, key) {
                    if (key == 0) params.networks += item._id;
                    else params.networks += ';' + item._id;
                });
            }

            params.status = '';
            if (!_.isEmpty($scope.params.statuts)) {
                angular.forEach($scope.params.statuts, function (item, key) {
                    if (key == 0) params.status += item._id;
                    else params.status += ';' + item._id;
                });
            }

            params.zipCode = '';
            if (!_.isEmpty($scope.params.zipCodes)) {
                angular.forEach($scope.params.zipCodes, function (zipCode, key) {
                    if (key == 0) params.zipCode += zipCode.text;
                    else params.zipCode += ';' + zipCode.text;
                });
            }
        }

        params.page = $scope.pagination.currentPage;
        params.limit = $scope.pagination.limit;

        $state.go('creche', params, {
            // prevent the events onStart and onSuccess from firing
            notify: false,
            // prevent reload of the current state
            reload: false,
            // replace the last record when changing the params so you don't hit the back button and get old params
            location: 'replace',
            // inherit the current params on the url
            inherit: true
        });
    };

    $scope.loadApplications = function(cb) {
      Application.query({}, function(data) {
        $scope.applications = data;
        if(cb) return cb();
      });
    };

    /**
     * Load list of all networks
     */
    $scope.loadNetworks = function () {

        Network.query({
            params: {}, search: {}, options: {sort: '_name', order: 'ASC'}
        }, function (data) {
            $scope.networks = data;
        });
    };

    /**
     * Source for the ngTagsInput
     */
    $scope.searchNetworks = function (query) {
        var deferred = $q.defer();
        deferred.resolve($filter('filter')($scope.networks, query));
        return deferred.promise;
    };

    /**
     * Source for the ngTagsInput
     */
    $scope.searchStatuses = function (query) {
        var deferred = $q.defer();
        deferred.resolve($filter('filter')($scope.statuses, query));
        return deferred.promise;
    };

    /* Add and edit Method */

    $scope.getCreche = function () {
        Creche.get({_id: $stateParams.crecheId, populate: true}, function (data) {
            // Set the creches object
            $scope.creche = data;

            $scope.$parent.creche = data;

            // Format the object for display purposes
            $scope.creche = $scope.formatCrecheEntity($scope.creche);
            $scope.legacy = JSON.stringify($scope.creche.legacy, null, 4);
        });
    };

    /**
     * Get the current network from db
     */
    $scope.initCreche = function () {
        // Get the current creche
        $scope.crecheSpinner = true;

        // Edition mode
        if ($stateParams.crecheId) {
            $scope.getCreche();
        }
        // Adding mode
        else {
            $scope.creche = {};
        }

        // Load all the networks
        $scope.loadNetworks();

        //Load the statuses
        $scope.loadStatuses();

        // Hide the loader
        $scope.crecheSpinner = false;
    };

    /**
     * Update location
     */
    $scope.updateLocation = function () {
        var address = (_.get($scope.creche, 'address.street', "").toString() + " " + _.get($scope.creche, 'address.zipCode', "").toString() + " " + _.get($scope.creche, 'address.city', "").toString()).trim();
        if (!_.isEmpty(address)) {
            Geocoder.getFromAddress(address)
                .then(function (result) {
                    var location = result.geometry.location;
                    var coordinates = [location.lng(), location.lat()];
                    $scope.creche.location = coordinates;
                });
        }
    };

    /**
     * Properly format the creche object for display in the edition page
     * @param creche
     */
    $scope.formatCrecheEntity = function (creche) {
        // Update the number of bed available
        $scope.updateNbBed(creche);

        // Format date for datepicker
        if (!_.isUndefined(creche.opening)) {
            var tmp = creche.opening.split(/[- :]/);
            var day = tmp[2].split(/[T]/)[0];
            var date = tmp[0] + '-' + tmp[1] + '-' + day;
            creche.opening = new Date(date);
        }

        // Set default values
        if (_.isUndefined(creche.criteria.length > 0 && creche.criteria[0].clean)) creche.criteria[0].clean = '';
        if (_.isUndefined(creche.criteria.length > 0 && creche.criteria[0].handicap)) creche.criteria[0].handicap = '';
        if (_.isUndefined(creche.criteria.length > 0 && creche.criteria[0].walk)) creche.criteria[0].walk = '';
        if (_.isUndefined(creche.schedules.length > 0 && creche.schedules[0].open)) creche.schedules[0].open = '';
        if (_.isUndefined(creche.schedules.length > 0 && creche.schedules[0].close)) creche.schedules[0].close = '';
        if (_.isUndefined(creche.food)) creche.food = {};
        if (_.isUndefined(creche.food.has)) creche.food.has = '';
        if (_.isUndefined(creche.food.mode)) creche.food.mode = '';
        if (_.isUndefined(creche.outside)) creche.outside = {};
        if (_.isUndefined(creche.outside && creche.outside.has)) creche.outside.has = '';
        if (_.isUndefined(creche.type)) creche.type = {};
        // Return creche object
        return creche;
    };
    /**
     * Method used to save a user on database
     * @param form
     * @param network
     */
    $scope.saveCreche = function (form, creche) {
        // Difference between create and update
        if (!_.isUndefined(creche._id)) {
            Creche.update(creche, function () {
                Notif.success('La crèche a bien été modifié !');
            }, Notif.error);
        }
        else {
            Creche.save(creche, function () {
                $state.go('creche');
                Notif.success('La crèche a bien été créé !');
            }, Notif.error);
        }
    };

    /**
     * Delete user
     * @param network
     */
    $scope.delete = function (creche) {
        Modal.confirm().then(function (result) {
            Creche.delete(creche, function () {
                Notif.success('La crèche a bien été supprimé !');
                $scope.initCreches();
            }, Notif.error);
        });
    };

    /**
     * Load the list of users with right 'appear in family list'
     */
    $scope.loadStatuses = function () {
        CrecheStatus.query({params: {}, search: {}, options: {}},
            function (data) {
                $scope.statuses = data;
            });
    };

    /**
     * Save comments in the family object
     */
    $scope.saveComment = function (comment) {
        var user = AuthService.getAuthenticatedUser();
        var commentToAdd = {type: 'creche', content: comment, user: user._id, reference: $scope.creche._id};
        $scope.comments.push(commentToAdd);
        Comment.save(commentToAdd, function () {
            //Load comments
            Comment.getByUserId({userId: $scope.creche._id}, function (data) {
                $scope.comments = data;
                Notif.success('Le commentaire à bien été ajouté !');
            });
            $scope.newComment = null;
        }, Notif.error);
    };

    $scope.updateCrecheContact = function (creche) {
        return function (contact) {
            creche.contacts = _.map($scope.contacts, '_id');
            creche.contacts.push(contact._id);
            Creche.update(creche, function () {
                Notif.success('Le réseau a bien été modifié !');
                $scope.isEditingContact = -1;
                $scope.contact = {};
                $scope.initCreche();
            });
        };
    };

    /**
     * Save contact in the family object
     */
    $scope.saveContact = function (form, creche) {
        if (form.$valid) {
            var up = function () {
                if ($scope.isEditingContact < 0) {
                    var contact = _.omit($scope.contact, '_id');
                    // set false previous main contact
                    Contact.save(contact, $scope.updateCrecheContact(creche), Notif.error);
                } else {
                    Contact.update($scope.contact, $scope.updateCrecheContact(creche), Notif.error);
                }
            };
            if ($scope.crecheMainContact && $scope.contact.main === true && $scope.isEditingContact !== $scope.crecheMainContact) {
                $scope.crecheMainContact.main = false;
                Contact.update($scope.crecheMainContact, up, Notif.error);
            } else {
                up();
            }
        }
    };

    /**
     * Delete the contact in the family object
     */
    $scope.deleteContact = function (creche, index) {
        Modal.confirm().then(function (result) {
            Contact.delete(creche.contacts[index], function () {
                creche.contacts.splice(index, 1);
                Creche.update(creche, function () {
                    Notif.success('Le contact a bien été Supprimer !');
                    if ($scope.isEditingContact === index)
                        $scope.isEditingContact = -1;
                }, function (err) {
                    Notif.error(err);
                    $scope.initCreche();
                });
            }, Notif.error);
        });
    };


    $scope.cancelContact = function () {
        $scope.contact = {};
        $scope.isEditingContact = -1;
    };

    $scope.editContact = function (creche, index) {
        $scope.contact = _.clone(creche.contacts[index]);
        $scope.isEditingContact = index;
    };

    /**
     * Add a row of schedule for the creche edition page
     */
    $scope.addScheduleRow = function ($event) {
        $event.currentTarget.remove();
        if (_.isUndefined($scope.creche.schedules)) $scope.creche.schedules = [];
        $scope.creche.schedules.push({'open': '', 'close': '', 'days': []});
    };

    /**
     * Add a row of criteria for the creche edition page
     */
    $scope.addCriteriaRow = function ($event) {
        if (_.isUndefined($scope.creche.criteria)) $scope.creche.criteria = [];
        $scope.creche.criteria.push({
            "capacity": 0,
            "clean": '',
            "handicap": '',
            "walk": '',
            "age": {
                "min": '',
                "max": ''
            }
        });
    };

    /**
     * Delete the selected row of schedule for the creche edition page
     */
    $scope.deleteScheduleRow = function ($event, index) {
        $scope.creche.schedules.splice(index, 1);
    };

    /**
     * Delete the selected row of criteria for the creche edition page
     */
    $scope.deleteCriteriaRow = function ($event, index) {
        $scope.creche.criteria.splice(index, 1);
    };

    /**
     * Update the number of bed available by adding all the creche.criteria.capacity
     */
    $scope.updateNbBed = function (creche) {
        creche.nbBed = 0;
        angular.forEach(creche.criteria, function (value, key) {
            creche.nbBed += parseInt(value.capacity);
        });
    };


    /* Datepicker methods */

    $scope.popup1 = {
        opened: false
    };

    $scope.open1 = function () {
        $scope.popup1.opened = true;
    };

    /* List edit page */

    /**
     * Open a modal for mass edit of the list
     */
    $scope.editList = function () {

    };

    /**
     * Add creche to the array of editable creches
     */
    $scope.addCrecheToEdit = function (creche, context) {
        var id = creche._id;
        if (!inArray(id, $scope.editCreches) && (context != 'all' || $scope.allCrecheChecked == false)) {
            $scope.editCreches.push(id);
        }
        else if (inArray(id, $scope.editCreches) && (context != 'all' || $scope.allCrecheChecked == true)) {
            var index = $scope.editCreches.indexOf(id);
            $scope.editCreches.splice(index, 1);
        }
    };

    /**
     * Add all creches displayed in the list to the editable array
     */
    $scope.addAllCrecheToEdit = function () {
        angular.forEach($scope.creches, function (value, key) {
            $scope.addCrecheToEdit(value, 'all');
        });

        $scope.allCrecheChecked = $scope.creches.length == $scope.editCreches.length;
    };
}]);

app.controller('ContractCrecheListCtrl', ['$scope', '_', '$state', '$stateParams', '$uibModal', 'Modal', 'Notif', 'Family', 'Creche', 'Contract', 'ContractStatus', 'AuthService', function ($scope, _, $state, $stateParams, $uibModal, Modal, Notif, Family, Creche, Contract, ContractStatus, AuthService) {
    $scope.now = new Date();
    $scope.loader = false;
    $scope.contracts = [];
    $scope.params = {
        creche: $stateParams.crecheId,
        type: 'family'
    };
    $scope.search = {};
    $scope.options = {
        sort: 'created',
        order: 'DESC'
    };
    $scope.pagination = {
        total: 0,
        skip: 0,
        currentPage: 1,
        limit: 20
    };

    $scope.statuses = [];

    var formatDate = function (date) {
        var dates = [
            'planned.start', 'planned.end', 'real.start', 'real.end',
            'cancelled', 'terminated', 'bdc', 'signed',
            'effective.start', 'effective.end', 'priceAnticipated',
            'free.start', 'free.end'
        ];
        _.each(dates, function (d) {
            var dt = _.get(date, d);
            if (dt) _.set(date, d, new Date(dt));
        });
    };


    var getCount = function (cb) {
        Contract.count({params: $scope.params}, function (data) {
            if (!data) return cb();
            resetPaging(data.count);
            return cb();
        }, Notif.error);
    };

    var resetPaging = function (total) {
        $scope.pagination.total = total;
        $scope.pagination.skip = 0;
        $scope.pagination.currentPage = 1;
    };

    var loadNbContract = function (contract) {
        Contract.count({params: {parent: contract._id}}, function (data) {
            contract.nbSubContracts = data;
        }, Notif.error);
    };

    $scope.getChildName = function (child, family) {
        var lastname = '';
        if (family) {
            _.each(family.parents, function (p) {
                if (!lastname) lastname = p.lastname;
            });
        }
        return _.get(child, 'firstname', '') + ' ' + lastname;
    };

    var getChildAndFamily = function (contract) {
        return async.waterfall([
            function (cb) {
                return Family.get({_id: contract.family}, function (family) {
                    _.set(contract, 'populated.family', family);
                    var app = _.find($scope.applications, {_id: _.get(family, 'application')});
                    if (app) _.set(contract, 'populated.family.application', app);
                    return cb(null, family);
                }, cb);
            },
            function (family, cb) {
                var child = _.find(family.children, {_id: contract.child});
                _.set(contract, 'populated.child', child);
                return cb(null, family, child);
            }
        ], function (err, family, child) {
            if (err) return Notif.error(err);
            return null;
        });
    };

    $scope.changeList = function () {
        var options = _.clone($scope.options);
        options.skip = ($scope.pagination.currentPage - 1) * $scope.pagination.limit;
        $scope.pagination.skip = options.skip;
        options.limit = $scope.pagination.limit;

        Contract.query({params: $scope.params, options: options}, function (contracts) {
            $scope.loadApplications(function () {
                $scope.contracts = contracts;
                _.each(contracts, getChildAndFamily);
                _.each(contracts, function (c) {
                    var app = _.find($scope.applications, {_id: c.application});
                    if (app) _.set(c, 'populated.application', app);
                    formatDate(c.date);
                });
            })
        }, Notif.error);
    };

    // FIXME: should be deleted
    var initParentCtrl = function () {
        Creche.get({_id: $stateParams.crecheId}, function (creche) {
            $scope.$parent.creche = creche;
        }, Notif.error);
    };

    var isStatusLost = function(currentStatus) {
        let lost = true;
        let statusesLost = ["58988b51924119d813a6c95a","58988b51924119d813a6c95b","58988b51924119d813a6c95c","58988b51924119d813a6c95d","58988b51924119d813a6c95e","58988b51924119d813a6c95f"];
        if(statusesLost.indexOf(currentStatus) === -1 )
            lost = false;
        return lost;
    }

    $scope.activeState = function (contract) {
        var state;
        if (!contract) return '';
        var date = contract.date;
        // BDC signe || envoye
        if (contract.status === '58988b51924119d813a6c964' || contract.status === '58988b51924119d813a6c965') {
            if (_.get(date, 'effective.end') > $scope.now) {
                if (_.get(date, 'effective.start') <= $scope.now) state = "Actif";
                else state = "Embarqué";
            } else {
                state = "Inactif";
            }
        }
        else if (isStatusLost(contract.status)){
            state = "Perdu";
        }
        else {
            state = "En cours";
        }
        return state;
    };

    $scope.getStatus = function (id) {
        var i = _.findIndex($scope.statuses, {_id: id});
        return (i === -1 ? '' : $scope.statuses[i]);
    };

    var loadStatuses = function () {
        if ($scope.statuses.length === 0) {
            ContractStatus.query({}, function (statuses) {
                $scope.statuses = statuses;
            }, Notif.error);
        }
    };

    $scope.initContracts = function () {
        if (!$stateParams.crecheId) return;
        initParentCtrl();
        loadStatuses();
        $scope.loader = true;
        getCount(function () {
            $scope.changeList();
            $scope.loader = false;
        });
    };

    $scope.deleteContract = function (contract) {
        Modal.confirm().then(function (result) {
            Contract.delete(contract, function () {
                Notif.success('Le contrat a bien été supprimé !');
                $scope.initContracts();
            }, Notif.error);
        });
    };
}]);

app.controller('CrecheEventCtrl', ['$scope', '_', '$q', '$state', '$stateParams', '$uibModal', 'Notif', 'Creche', 'Role', 'AuthService', 'Event', 'Application', 'Registration', 'Site', 'Form', 'Question', 'User', 'Contract', function ($scope, _, $q, $state, $stateParams, $uibModal, Notif, Creche, Role, AuthService, Event, Application, Registration, Site, Form, Question, User, Contract) {
    $scope.visits = [];
    $scope.visit = null;
    $scope.registration = null;
    $scope.site = null;
    $scope.forms = [];
    $scope.form = null;
    $scope.registrations = [];
    $scope.isRegistration = null;
    $scope.applications = [];
    $scope.application = null;
    $scope.questions = [];
    $scope.users = [];
    $scope.editOtherCrecheVisits = false;
    $scope.editFirstCrecheVisit = false;
    $scope.activeContracts = 0;

    $scope.statuses = [
        'Visite à effectuer',
        'Visite prévue',
        'Visite effectuée',
        'Visite annulée'
    ];

    $scope.states = {
        'visit': [
          { label: 'Obligatoire', value: 'mandatory', class: 'warning', htmlLabel: '<span class="label label-warning">&nbsp;</span> Obligatoire' },
          { label: 'Conseillé', value: 'unmandatory', class: 'info', htmlLabel: '<span class="label label-info">&nbsp;</span> Conseillé' },
          { label: 'Collaborateur', value: 'collaborator', class: 'default', htmlLabel: '<span class="label label-default">&nbsp;</span> Collaborateur'},
        ],
        'quality': []
      }

      $scope.getState = function(stateLabel) {
        return _.find($scope.states['visit'], { value: stateLabel });
      }

    // Application id of Icare
    var icareApp = '58ad59fabda3fdb4efba6d13';

    var setDate = function (ev) {
        if (!ev) return {};
        _.forEach(['planned'], function (e) {
            if (_.get(ev, 'dates[' + e + ']', null)) {
                ev.dates[e] = moment(ev.dates[e]).toDate();
            }
        });
        if (!_.get(ev, 'user._id', null)) _.set(ev, 'user._id', ev.user);
        return ev;
    };

    var initFirstVisits = AuthService.initFirstVisits;

    var initVisitsCtrl = function (cb) {
        Event.query({
            params: {type: 'visit', ref: $stateParams.crecheId},
            options: {sort: 'dates.planned', order: 'DESC'}
        }, function (data) {
            initFirstVisits(data, function (visits) {
                $scope.visits = visits;
                if(cb) return cb();
            });
        }, Notif.error);
    };

    $scope.visitIsNotEditable = function (visit) {
        if (!visit || !Object.keys(visit).length) return false;
        var isFirstVisit = !!visit.isFirstVisit;
        return (isFirstVisit && !$scope.editFirstCrecheVisit) || (!isFirstVisit && !$scope.editOtherCrecheVisits);
    };

    // $scope.editVisits = function (visits) {
    //     _.forEach(visits, function())
    //     return (visits.some(function (visit) {
    //         return !$scope.visitIsNotEditable(visit);
    //     }))
    // };

    $scope.getForm = function(formId) {
        return _.find($scope.forms, function(f) {
            if(!f || !f._id || !formId) return;
            return f._id.toString() === formId.toString();
        })
    }

    $scope.initVisits = function () {

        AuthService.initVisitRights($scope);
        if (!$stateParams.crecheId) return;
        async.series([
            function (cb) {
                Site.query({params: {'key': 'visit-mpec', active: true}}, function (data) {
                    $scope.site = data[0];
                    if ($scope.site) {
                        $scope.loadForms($scope.site._id, cb);
                    } else return cb();
                });
            },
            function (cb) {
                Creche.get({_id: $stateParams.crecheId}, function (creche) {
                    $scope.$parent.creche = creche;
                    return cb();
                });
            },
            function(cb) {
              var q = { type: 'family', state: 'Actif', creches: [$scope.$parent.creche._id]};
              Contract.count({params: q }, function (data) {
                $scope.activeContracts = data.count || 0;
                cb();
              })
            }
        ], function() {
            initVisitsCtrl();
        });
    };

    var initVisitRegistration = function () {
        async.series([
            $scope.loadApplications,
            function (cb) {
                // Load registration
                $scope.application = _.find($scope.applications, {name: 'Icare'});
                $scope.loadRegistrations($scope.registration ? $scope.registration : null, function () {
                    var defaultSite = $scope.site;
                    var defaultRegistration = {
                        score: 0,
                        answers: [],
                        application: $scope.application._id,
                        site: defaultSite._id,
                        form: {}
                    }

                    var registration = defaultRegistration;
                    if ($scope.registrations[0]) registration = $scope.registrations[0];
                    $scope.registration = registration;
                    cb();
                });
            },
            function (cb) {
                // get Form
                if (!$scope.registration.form._id) {
                    Form.get({_id: $scope.visit.form}, function (form) {
                        if (form) {
                            $scope.form = form;
                            $scope.registration.form = $scope.form;
                        }
                        cb();
                    })
                }
                else cb();
            },
            function (cb) {
                // Load questions & answers
                $scope.$watch($scope.registration.form, function (ov, nv) {
                    $scope.loadQuestions($scope.site._id, $scope.registration.form._id, function (questions) {
                        $scope.questions = questions;
                        _.forEach(questions, function (question, key) {
                            var answer = _.find($scope.registration.answers, function (a) {
                                return a.question._id === question._id;
                            });
                            if (answer) return;
                            $scope.registration.answers.splice(key, 0, {question: question, value: undefined});
                        })
                    })
                })

                //Handles checkbox cases when values hasn't been initialized or has been initialized as a single String
                _.forEach($scope.registration.answers, function (answer) {
                    if (answer.question.type === "checkbox" && !(_.isArray(answer.value)))
                        answer.value = (_.isUndefined(answer.value)) ? [] : [answer.value];
                });
                cb();
            }
        ])
    }

    $scope.initVisit = function () {
        AuthService.initVisitRights($scope);
        if (!$stateParams.crecheId || !$stateParams.eventId) return;
        Creche.get({_id: $stateParams.crecheId}, function (creche) {
            $scope.$parent.creche = creche;
            Event.query({params: {_id: $stateParams.eventId}, options: {limit: 1}}, function (visits) {
                $scope.visit = setDate(visits[0]);

                Event.query({params: {ref: visits[0].ref}}, function (allVisits) {
                    initFirstVisits(allVisits, function (visits) {
                        $scope.visit = visits.find(function (visit) {
                            return visit._id == $scope.visit._id;
                        });
                        $scope.registration = $scope.visit.registration;
                        $scope.isRegistration = $scope.registration ? true : false;

                        //When reload page
                        if (_.isEmpty($scope.users)) {
                            $scope.initUsers();
                            $scope.searchUsers = makeSearchFun(User, {application: icareApp}, setDisplayUser);
                        }
                        $scope.site = Site.get({_id: $scope.visit.site }, function (data) {
                            initVisitRegistration();
                        });
                    });
                }, Notif.error);
            }, Notif.error);
        }, Notif.error);
    }


    var setDisplayUser = function (users) {
        _.each(users, function (user) {
            if (user.firstname && user.firstname[0]) {
                user['display'] = user.firstname[0].toUpperCase() + '. ' + _.capitalize(user.lastname);
            }
        });
    };

    var makeSearchFun = function (Model, params, func) {
        return function (query) {
            var deferred = $q.defer();
            Model.query({
                params: params,
                search: {text: query},
                options: {limit: 20, sort: 'firstname', order: 'ASC'}
            }, function (docs) {
                if (func) func(docs);
                deferred.resolve(docs);
            }, Notif.error);
            return deferred.promise;
        };
    };

    $scope.initUsers = function () {
        if (!_.isNull($scope.visit.user) && !_.isNull($scope.visit.user._id)) {
            $scope.users.push($scope.visit.user);
        }
        if (!_.isEmpty($scope.visit.users)) {
            $scope.users = _.concat($scope.users, $scope.visit.users);
        }
        setDisplayUser($scope.users);
    }

    $scope.loadRegistrations = function (registrationId, cb) {
        if (registrationId) {
            Registration.query({params: {'_id': registrationId}, search: {}, options: {}},
                function (data) {
                    $scope.registrations = data;
                    if (cb) return cb();
                });
        }
        else {
            if (cb) return cb();
        }
    };

    $scope.loadQuestions = function (siteId, formId, cb) {
        Question.query({params: {site: siteId, form: formId}, search: {}, options: {}}, cb);
    }
    $scope.loadForms = function (siteId, cb) {
        Form.query({params: {site: siteId}, search: {}, options: {}},
            function (data) {
                $scope.forms = data;
                return cb();
            });
    };


    $scope.loadApplications = function (cb) {
        Application.query({}, function (data) {
            $scope.applications = data;
            if (cb) return cb();
        });
    };

    /**
     * Method used to update a registration on database
     * @param registration object
     */
    $scope.saveRegistration = function (registration) {
        var rgt = angular.copy(registration);
        rgt.answers = _.filter(rgt.answers, function (a) {
            return (a.question.type !== 'title' && a.value);
        });

        var request = Registration.update;
        if (!registration._id) request = Registration.save;
        request(rgt, function (data) {
            if (!$scope.visit.status || $scope.visit.status === 'Visite à effectuer') {
                Notif.error('Le champ \'Statut\' doit être rempli et différent de \'Visite à effectuer\'');
                return;
            }
            if ((_.isUndefined($scope.visit.dates) || _.isUndefined($scope.visit.dates.planned) || _.isNull($scope.visit.dates.planned))) {
                Notif.error('Le champ \'Date\' doit être rempli');
                return;
            }
            $scope.visit.registration = data._id;
            //if ($scope.visit.dates && !_.isUndefined($scope.visit.dates.planned)) $scope.visit.dates.real = $scope.visit.dates.planned;
            var users = _.cloneDeep($scope.users);
            $scope.visit.user = !_.isEmpty(users) ? users.shift() : null;
            $scope.visit.users = users;
            Event.update($scope.visit, function () {
                if (!$scope.isRegistration) {
                    Notif.success('Le questionnnaire a bien été enregistré');
                } else {
                    Notif.success('Le questionnnaire a bien été mis à jour');
                }
                $scope.initVisit();
            }, Notif.error);
        }, Notif.error)
    }

    $scope.save = function (event) {
        if (!event || !event.status || event.status === '' || event.status === 'Visite à effectuer') {
            Notif.error('Le champ \'Statut\' doit être rempli et différent de \'Visite à effectuer\'');
            return;
        }
        event.type = 'visit';
        if (event._id) {
            Event.update(event, function (ev) {
                if (!ev) return;
                Notif.success("Le formulaire a bien été modifié");
                $scope.initVisits();
            }, Notif.error);
        }
    };

    $scope.arrayContains = function (array, el) {
        if (_.indexOf(array, el) === -1) return false;
        else return true;
    };

    $scope.checkedValues = function (qanswer, values) {
        if (_.isUndefined(values.value)) {
            values.value = [];
        }
        if (!$scope.arrayContains(values.value, qanswer)) {
            values.value.push(qanswer);
        } else if ($scope.arrayContains(values.value, qanswer)) {
            _.pull(values.value, qanswer);
        }
    };

    // This openModal() is here for the modal of contract.
    $scope.openModal = function (ev, mode) {
        var options = {
            animation: false,
            backdrop: 'static',
            keyboard: true,
            size: 'md',
            templateUrl: '/partials/ui/crecheEvent.html',
            resolve: {
                Context: function () {
                    return {
                        event: ev,
                        mode: mode,
                        creche: $scope.$parent.creche,
                        scope: $scope,
                        statuses: $scope.statuses,
                        forms: $scope.forms.map(function (form) {
                            form.isEditable = form.key && (AuthService.editFirstCrecheVisit() && form.key === "carnet-creche-1ere-visite" ||
                                AuthService.editOtherCrecheVisits() && form.key !== "carnet-creche-1ere-visite");
                            return form;
                        }),
                    };
                }
            },
            controller: 'ContractEventModalInstanceCtrl'
        };
        var modalInstance = $uibModal.open(options);

        modalInstance.result.then(function (obj) {
            var isFillForm = _.get(obj, 'isFillForm', false);
            var visit = _.get(obj, 'visit', null);
            if (isFillForm && visit.status === 'Visite effectuée') {
                $state.go('creche-detail.visit-profile', {crecheId: $scope.$parent.creche._id, eventId: visit._id});
            }
            else {
                $scope.initVisits();
            }
        });
    };

    $scope.getActiveBerceauxCount = function() {
      
    }
}]);
