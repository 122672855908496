// app.directive('a', ['_', '$rootScope', function (_, $rootScope) {
//   return ({
//     restrict: 'E',
//     link: function link($scope, elm, attr) {
//       elm.on('click', function($event) {
//         if(_.startsWith(attr.href, 'tel:')) {
//           $rootScope.dialer.visible = true;
//           $rootScope.dialer.ts = new Date().getTime();
//           $rootScope.dialer.phone = attr.href.substr(4);
//           $rootScope.$apply();
//           $event.preventDefault();
//           return false;
//         }
//       })
//     }
//   })
// }])
