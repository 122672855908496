'use strict';

app.controller('LinkCtrl', ['$scope', '_', 'Modal', 'Link', 'Family', '$state', '$stateParams', 'ngNotify', function ($scope, _, Modal, Link, Family, $state, $stateParams, ngNotify) {
    /* Init variables */
    $scope.search = {};
    $scope.params = {};
    $scope.options = {};
    $scope.links = [];
    $scope.link = null;
    $scope.linkParams = {};

    $scope.loader = false;
    $scope.options.sort = 'created';
    $scope.options.order = 'DESC';

    /* List variable */
    $scope.pagination = {};
    $scope.pagination.total = 0;
    $scope.pagination.limit = 20;
    $scope.pagination.currentPage = 1;

    /* Regex for form */
    $scope.numberRegex = /^[0-9, .-]+$/;
    $scope.emailRegex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,6}/i;

    /* List page Method */

    /**
     * Init method to get all links from the database
     */
    $scope.initLinks = function () {
        // Display loader
        $scope.loader = true;

        // Get data source count
        $scope.getCount();

        // Get data
        $scope.changeList(function() {
          $scope.loader = false;
        });
    };

    /**
     * Reset pagging method
     * @param total
     */
    $scope.resetPaging = function (total) {
        $scope.pagination.total = total;
        $scope.pagination.offset = 0;
        $scope.pagination.currentPage = 1;
    };

    /**
     * Method called when the user changes the pagination
     */
    $scope.changeList = function (cb) {
        // Set options for query
        var options = _.clone($scope.options);
        options.skip = ($scope.pagination.currentPage - 1) * $scope.pagination.limit;
        options.limit = $scope.pagination.limit;

        var search = _.clone($scope.search);
        if (!_.isUndefined(search.text)) search.text = slug(search.text);

        //Perform the query
        Link.query({params: $scope.params, search: search, options: options},
            function (data) {
                $scope.links = data;
                angular.forEach($scope.links, function (value, key) {
                  if(value.highlight && value.params && value.params.mpec_name)
                    value._color = '#' + string_to_color(value.params.mpec_name);
                  var params = _.transform(value.params, function(result, value, key) {
                    result[key.replace(/^mpec_/, 'meta.')] = value;
                  }, {})
                  Family.count({params: params }, function (data) {
                      value.families = data.count || 0;
                  });
                });
                if(cb) return cb();
            }
        );
      }
    /**
     * Return count of the datasource based on params
     */
    $scope.getCount = function () {
        // Perform the query
        Link.count({params: $scope.params, search: $scope.search},
            function (data) {
                $scope.resetPaging(data.count);
            });
    };
    /**
     * Filter list based on search and params
     */
    $scope.filterList = function () {
        // Display loader dot the list
        $scope.loader = true;

        // Get count
        $scope.getCount();

        // Get data
        setTimeout(function () {
            $scope.changeList();
            $scope.loader = false;
        }, 500);
    };


    /**
     * Sort list by key
     * @param key
     */
    $scope.sortList = function (key) {
        $scope.loader = true;
        $scope.options.sort = key;
        $scope.options.order = $scope.options.order == 'DESC' ? 'ASC' : 'DESC';

        //Get data
        setTimeout(function () {
            $scope.changeList();
            $scope.loader = false;
        }, 500);
    };

    /**
     * Get the current link from db
     */
    $scope.initLink = function () {
        // Get the current network
        if (!_.isUndefined($stateParams.linkId)) {
          Link.get({_id: $stateParams.linkId}, function(data){
          // Set isEdit flag
          $scope.isEdit = true;
          if(data && data.params && data.params.mpec_name)
            data._color = '#' + string_to_color(data.params.mpec_name);
          $scope.link = data;
          $scope.linkParams = [];
          angular.forEach($scope.link.params, function(v, k) {
            $scope.linkParams.push({ name: k, value: v });
          })
        });
      }
      else {
        $scope.link = { url: 'http://www.maplaceencreche.com/inscription-creche/' };
        $scope.linkParams = [
          { name: 'mpec_name', value: ''},
          { name: 'mpec_source', value: ''},
          { name: 'mpec_medium', value: ''},
          { name: 'mpec_transform', value: ''},
        ];
      }

    };

    $scope.addParameter = function() {
      $scope.linkParams.push({ name: 'mpec_', value: '' });
    }

    $scope.deleteParameter = function(param) {
      var index = $scope.linkParams.indexOf(param);
      if(index > -1) $scope.linkParams.splice(index, 1);
    }

    $scope.refreshKey = function() {
      Link.key(function (data) {
        $scope.link.key = data.key;
      });
    }

    /**
     * Method used to save a user on database
     * @param form
     * @param network
     */
    $scope.saveLink = function (form, link) {
        if (form.$valid) {
          // Difference between create and update
          link.params = {};
          angular.forEach($scope.linkParams, function(p) {
            link.params[p.name] = p.value;
          })
          if (!_.isUndefined(link._id)) {
              Link.update(link, function () {
                  ngNotify.set('Le lien a bien été modifié !', 'success');
                  $state.go('link');
              }, function (err) {
                  ngNotify.set(err, 'error');
              });
          }
          else {
              Link.save(link, function () {
                  $state.go('link');
                  ngNotify.set('Le lien a bien été créé !', 'success');
              }, function (err) {
                  ngNotify.set(err.data.message, 'error');
              });
          }
        }
    };
}]);
