'use strict';

app.controller('UserCtrl', ['$scope', '$window', '_', 'User', 'Role', 'Modal', 'AuthService', '$state', '$stateParams', 'ngNotify', '$q', '$filter', '$uibModal', 'Upload', 'Application', function ($scope, $window, _, User, Role, Modal, AuthService, $state, $stateParams, ngNotify, $q, $filter, $uibModal, Upload, Application) {
    /* Init variables */
    var state = $state.$current.name.split('.');
    var currentState = state[state.length-1];
    $scope.user = {};
    $scope.userProfile = {};
    $scope.search = {};
    $scope.params = {};
    $scope.options = {};
    $scope.users = [];
    $scope.roleList = [];
    $scope.poleList = [];
    $scope.newPassword = undefined;
    $scope.isEdit = $stateParams.userId ? true : false;
    $scope.userLoading = false;
    $scope.usersActive = [];
    $scope.profilePic = undefined;
    $scope.applications = [];

    /* List variable */
    $scope.pagination = {};
    $scope.pagination.total = 0;
    $scope.pagination.limit = 20;
    $scope.pagination.currentPage = 1;
    $scope.options.sort = '_id';
    $scope.options.order = 'DESC';

    /* Regex for form */
    $scope.numberRegex = /^[0-9, .-]+$/;
    $scope.emailRegex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,6}/i;

    /* List method */

    /**
     * Method used to refresh the user list's data, called in ng-init of list.html
     */
    $scope.initUsers = function (flag) {
        $scope.userLoading = true;
        //Get count
        $scope.getCount(flag);

        //Load list of roles
        $scope.loadRoles(flag);

        //Get data
        setTimeout(function () {
            $scope.changeList();
          $scope.userLoading = false;
        }, 500);
    };


    /**
     * Reset pagging method
     * @param total
     */
    $scope.resetPaging = function(total){
        $scope.pagination.total = total;
        $scope.pagination.offset = 0;
        $scope.pagination.currentPage = 1;
    };



    /**
     * Source for the ngTagsInput
     */
    $scope.searchApplications = function (query) {
        var deferred = $q.defer();
        Application.query({search: {text: query}, options: {limit: 30, sort: '_name', order: 'ASC'}}, function (applications) {
            _.each(applications, function (c) {
                c.display = c.name;
            });
            deferred.resolve(applications);
        });
        return deferred.promise;
    };


    /**
     * Method called when the user changes the pagination
     */
    $scope.changeList = function (cb) {

        //filter selected
        var options = _.clone($scope.options);
        options.skip = ($scope.pagination.currentPage - 1) * $scope.pagination.limit;
        options.limit = $scope.pagination.limit;

        Application.query({params: {name: "Icare"}}, function(icareApp){
            var q = {params: {roles: $scope.params.roles}, search: {text: $scope.search.text || ''}, options: options};
            if(currentState === 'repertoire') {
                q.params.active = true;
                q.params.application = icareApp[0]._id;
                if($scope.search.application && _.isArray($scope.search.application) && $scope.search.application.length > 0)
                    q.params.application = _.map($scope.search.application, '_id');
            }
            User.query(q, function (data) {
                $scope.users = data;
                if(cb) return cb();
            });
        })
    };

    /**
     * Return count of the datasource based on params
     */
    $scope.getCount = function(flag) {
        Application.query({params: {name: "Icare"}}, function(icareApp){
            var q = {params: {roles: $scope.params.roles}, search: {text: $scope.search.text || ''}};
            if(currentState === 'repertoire') {
                q.params.active = true;
                q.params.application = icareApp[0]._id;
                if($scope.search.application && _.isArray($scope.search.application) && $scope.search.application.length > 0)
                    q.params.application = _.map($scope.search.application, '_id');
            }
            User.count(q, function (data) {
                $scope.resetPaging(data.count);
            });
        })
    };
    /**
     * Filter list based on search and params
     */
    $scope.filterList = function() {

        // Display loader dot the list
        $scope.userLoading = true;

        // Get count

        // Load the tags inputs networks
        $scope.params.roles = [];
        if (!_.isUndefined($scope.params.role) && $scope.params.role.length > 0) {
            angular.forEach($scope.params.role, function (value) {
                if(!_.isUndefined(value._id)) $scope.params.roles.push(value._id);
            });
        }
        else {
            delete $scope.params.roles;
        }

        $scope.getCount();
        // Get data
        setTimeout(function () {
            $scope.changeList(function() {
              $scope.userLoading = false;
            });

        }, 500);
    };

    /**
     * Sort list by key
     * @param key
     */
    $scope.sortList = function (key) {
        $scope.userLoading = true;
        $scope.options.sort = key;
        $scope.options.order = $scope.options.order == 'DESC' ? 'ASC' : 'DESC';

        //Get data
        setTimeout(function () {
            $scope.changeList(function() {
              $scope.userLoading = false;
            });
        }, 500);
    };

    /**
     * Delete user
     * @param user
     */
    $scope.delete = function (user) {
        Modal.confirm().then(function (result) {
            User.delete(user, function () {
                ngNotify.set("L'utilisateur a bien été supprimé", 'success');
                $scope.refreshUsers();
            });
        });
    };

    $scope.exportList = function (format) {
      var options = encodeURIComponent(JSON.stringify($scope.options));
      var params = encodeURIComponent(JSON.stringify($scope.params));
      var search = encodeURIComponent(JSON.stringify($scope.search));

      $window.open('/api/user/export.' + format + '?params=' + params + '&search=' + search + '&options=' + options, '_blank');
    };

    /**
     * Load a list of roles for ngTagsInput
     */
    $scope.loadRoles = function (flag) {

      var params = {};
      if(currentState === 'repertoire') params.active = true;

      Role.query({params: null, search: null, options: null}, function (data) {
        $scope.roleList = data;
      });
    };

    /* Add and Edit method */

    /**
     * Method used to save a user on database
     * @param form
     * @param user
     */
    $scope.saveUser = function (form, user) {
        if (form.$valid) {
            // Difference between create and update
            if (!_.isUndefined(user._id)) {
                // If new password is set, link it to the current user
                if(!_.isUndefined($scope.newPassword)) user.password = $scope.newPassword;
                user.poles = _.map(user.poles, "text");
                User.update(user, function () {
                    ngNotify.set("L'utilisateur a bien été modifié", 'success');
                    if (AuthService.getAuthenticatedUser().user._id === $scope.user._id)
                      AuthService.setUser($scope.user);
                    $state.go('user');
                }, function (err) {
                    ngNotify.set('Attention ! Une erreur est survenue.', 'error');
                });
            }
            else {
                user.password = $scope.newPassword;
                User.save(user, function () {
                    ngNotify.set("L'utilisateur a bien été créé", 'success');
                    $state.go('user');
                }, function (err) {
                    ngNotify.set('Attention ! Une erreur est survenue.', 'error');
                });
            }
        }
    };

    $scope.saveImage = function(file, user) {
        if (file) {
            file.upload = Upload.upload({
                url: '/api/user/' + user._id + '/upload',
                data: { file: file }
            }).then(function(resp) {
                $scope.user = resp.data;
                if (AuthService.getAuthenticatedUser().user._id === $scope.user._id)
                  AuthService.setUser($scope.user);
                $state.reload();
            }, function(resp) {
                ngNotify.set("Échec de la sauvegarde du fichier", 'error');
            });
        }
   };

   $scope.loadApplications = function() {
     Application.query({}, function(data) {
       $scope.applications = data;
     });
   };

    /**
     * Init method when editing a user, called in ng-init of edit.html
     */
    $scope.initUser = function () {
        var userId = $stateParams.userId;
        if (!_.isUndefined($stateParams.userId)) $scope.user = User.get({_id: userId});

        $scope.loadApplications();

        Role.query({params: null, search: null, options: null},
            function (data) {
                $scope.roleList = data;

                var userRoles = [];
                if(!_.isUndefined( $scope.user.roles)) {
                    $scope.user.roles.forEach(function(userRole) {
                        var role = $filter('getObjectInArrayByProperty')($scope.roleList, '_id', userRole.label);
                        if (role) {
                            userRoles.push(role)
                        }
                    });
                }
                $scope.user.roles = userRoles;
            }
        );

        User.distinct({params: {distinct: ['poles']}, search: null, options: null},
            function (data) {
                $scope.poleList = data;
            }
        );
    };
    /* Profile method */

    /**
     * Init method when editing a user, called in ng-init of profile.html
     */
    $scope.initProfile = function () {
      var userId = $stateParams.userId;
      $scope.user = User.get({_id: userId});
    };

    $scope.searchRoles = function(query) {
      var deferred = $q.defer();
      deferred.resolve($filter('filter')($scope.roleList, query));
      return deferred.promise;
    };

    $scope.searchPoles = function(query) {

      var deferred = $q.defer();
      deferred.resolve(_.filter($scope.poleList, function(pole) {
       return pole.toLowerCase().contains(query.toLowerCase());
      }));
      return deferred.promise;
    };

    $scope.openProfileModal = function (user) {
    var modalInstance = $uibModal.open({
      templateUrl: "partials/ui/repProfile.html",
      controller: 'ProfileModalInstanceCtrl',
      size: 'lg',
      resolve: {
        ActualUser: function () {
          return user;
        }
      }
    });

    // mod`alInstance.result.then(function (user) {
    //     $scope.userProfile = user;
    // })
  };
}]);

app.controller('ProfileModalInstanceCtrl', ['$scope', '$uibModalInstance', 'ActualUser', 'AuthService', function ($scope, $uibModalInstance, ActualUser, AuthService) {
  $scope.user = ActualUser;
  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };
}]);
