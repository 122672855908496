'use strict';

app.controller('AuthCtrl', ['$scope', '$state', 'Notif', 'AuthService', 'isModal', 'message', function ($scope, $state, Notif, AuthService, isModal, message) {
    $scope.email = null;
    $scope.password = null;

    if (message) {
        Notif.set(message);
    }

    $scope.login = function() {
        AuthService.login($scope.email, $scope.password)
            .then(
                function() {
                    if (isModal) {
                        //Close Modal
                        $scope.$close();
                    } else {
                        //Redirect to home page
                        $state.go('dashboard');
                    }
                },
                function(err) {
                    Notif.error(err);
                }
            );
    }
}]);
