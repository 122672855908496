'use strict';

app.controller('SpancoCtrl', ['$scope', 'FamilyStatistic', '$state', 'AuthService', 'Right', 'User', '$stateParams', function ($scope, FamilyStatistic, $state, AuthService, Right, User, $stateParams) {
    // Users
    $scope.users = [];
    $scope.loadUsers = function(cb) {
      Right.getById({ _id: 'COMMERCIAL_HANDLE_FAMILY', populate: true }, function (data) {
        var assignRight = data[0]._id;
        User.query({ params: { active: true }, search: {}, options: { populate: true, sort: '_lastname', order: 'ASC' } }, function (users) {
          angular.forEach(users, function (user, key) {
            var keepLooping = true;
            angular.forEach(user.roles, function (role, key) {
              if (keepLooping) {
                if (_.indexOf(role.rights, assignRight) != -1) {
                  $scope.users.push(user);
                  keepLooping = false;
                }
              }
            });
          });
          cb();
        });
      });
    }

    // Dates
    $scope.today = {
        start: moment().startOf('day'),
        end: moment().endOf('day')
    };

    $scope.currentMonth = {
        start: moment().startOf('month'),
        end: moment().endOf('month')
    };

    $scope.lastMonth = {
        start: moment().subtract(1,'months').startOf('month'),
        end: moment().subtract(1,'months').endOf('month')
    };

    $scope.lastLastMonth = {
        start: moment().subtract(2,'months').startOf('month'),
        end: moment().subtract(2,'months').endOf('month')
    };

    $scope.currentYear = {
        start: moment().startOf('year'),
        end: moment().endOf('year')
    };

    $scope.lastYear = {
        start: moment().subtract(1,'years').startOf('year'),
        end: moment().subtract(1,'years').endOf('year')
    };

    // Spanco
    $scope.formSpanco = {};
    $scope.formSpanco.user = null;
    $scope.canChangeList = true;
    $scope.loadingSpanco = false;
    $scope.cpanco = [];
    $scope.totalSpanco = {
        opportunite : {
            'today' : 0,
            'currentMonth' : 0,
            'lastMonth' : 0,
            'lastLastMonth' : 0,
            'currentYear' : 0,
            'otherYears' : 0
        },
        end_term : {
            'today' : 0,
            'currentMonth' : 0,
            'lastMonth' : 0,
            'lastLastMonth' : 0,
            'currentYear' : 0,
            'otherYears' : 0
        }
    };

    //Right
    // Remove the check: isAuthorized(['COMMERCIAL_HANDLE_FAMILY']) ?
    if (!AuthService.isAuthorized(['COMMERCIAL_MANAGE']) && AuthService.isAuthorized(['COMMERCIAL_HANDLE_FAMILY'])) {
        $scope.formSpanco.user = _.find($scope.users, {'_id' : AuthService.getAuthenticatedUser()._id});
        $scope.canChangeList = false;
    }

  $scope.changeSpanco = function () {
    var opt = {ca: null};
    if ($scope.formSpanco.user)
      opt.ca = $scope.formSpanco.user._id;
    $state.go('spanco', opt);
  };

    /**
     * Get the stats
     */
    $scope.getSpanco = function() {
        $scope.loadingSpanco = true;
        $scope.totalSpanco = {
          sales : {
              'today' : 0,
              'currentMonth' : 0,
              'lastMonth' : 0,
              'lastLastMonth' : 0,
              'currentYear' : 0,
              'otherYears' : 0
          },
            opportunite : {
                'today' : 0,
                'currentMonth' : 0,
                'lastMonth' : 0,
                'lastLastMonth' : 0,
                'currentYear' : 0,
                'otherYears' : 0
            },
            end_term : {
                'today' : 0,
                'currentMonth' : 0,
                'lastMonth' : 0,
                'lastLastMonth' : 0,
                'currentYear' : 0,
                'otherYears' : 0
            }

        };

        var params = {};
        params.application = '';
        if($scope.formSpanco.user) params.userId = $scope.formSpanco.user._id;

        FamilyStatistic.spanco(params, function(data) {
          $scope.spanco = data;

          $scope.spanco.sales.forEach(function(item) {
              if (item.today) $scope.totalSpanco.sales.today += item.today;
              if (item.currentMonth) $scope.totalSpanco.sales.currentMonth += item.currentMonth;
              if (item.lastMonth) $scope.totalSpanco.sales.lastMonth += item.lastMonth;
              if (item.lastLastMonth) $scope.totalSpanco.sales.lastLastMonth += item.lastLastMonth;
              if (item.currentYear) $scope.totalSpanco.sales.currentYear += item.currentYear;
              if (item.otherYears) $scope.totalSpanco.sales.otherYears += item.otherYears;
          });

          $scope.spanco.opportunite.forEach(function(item) {
              if (item.today) $scope.totalSpanco.opportunite.today += item.today;
              if (item.currentMonth) $scope.totalSpanco.opportunite.currentMonth += item.currentMonth;
              if (item.lastMonth) $scope.totalSpanco.opportunite.lastMonth += item.lastMonth;
              if (item.lastLastMonth) $scope.totalSpanco.opportunite.lastLastMonth += item.lastLastMonth;
              if (item.currentYear) $scope.totalSpanco.opportunite.currentYear += item.currentYear;
              if (item.otherYears) $scope.totalSpanco.opportunite.otherYears += item.otherYears;
          });

          $scope.spanco.end_term.forEach(function(item) {
              if (item.today) $scope.totalSpanco.end_term.today += item.today;
              if (item.currentMonth) $scope.totalSpanco.end_term.currentMonth += item.currentMonth;
              if (item.lastMonth) $scope.totalSpanco.end_term.lastMonth += item.lastMonth;
              if (item.lastLastMonth) $scope.totalSpanco.end_term.lastLastMonth += item.lastLastMonth;
              if (item.currentYear) $scope.totalSpanco.end_term.currentYear += item.currentYear;
              if (item.otherYears) $scope.totalSpanco.end_term.otherYears += item.otherYears;
          });

          $scope.loadingSpanco = false;
        });
    };

    /**
     * Method used to load stateparams if there exists
     */
    $scope.loadStateParams = function () {
        var userId = null;
        if (!_.isUndefined($stateParams.ca)) userId = $stateParams.ca;

        $scope.formSpanco.user = _.find($scope.users, {'_id' : userId});
        $scope.getSpanco();
    };

    /**
     * Redirect the user to the family list page with params
     * @param status
     * @param dateStart
     * @param dateEnd
     */
    $scope.goToFamily = function (status, dateStart, dateEnd) {
      var userId = null;
      if($scope.formSpanco.user) userId = $scope.formSpanco.user._id;
      if(_.isArray(status)) status = _.map(status, '_id').join(';');
      var params = { status: status, ca : userId };
      var dateStart = moment(dateStart).startOf('day');
      var dateEnd = moment(dateEnd).endOf('day');
      if(dateStart && dateStart.isValid()) params.dateStart = dateStart.format('YYYY-MM-DD HH:mm:ss');
      if(dateEnd && dateEnd.isValid()) params.dateEnd = dateEnd.format('YYYY-MM-DD HH:mm:ss');
      $state.go('family', params);
    };

    //Init
    $scope.loadUsers(function() {
      // Laod params in url if there exists
      $scope.loadStateParams();
    });

}]);
