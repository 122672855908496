'use strict';

app.controller('NetworkEvenstListCtrl', ['$scope', '$window', '_', 'Event', 'Network', 'Contract', '$state', '$stateParams', '$q', function ($scope, $window, _, Event, Network, Contract, $state, $stateParams, $q) {

  

  $scope.loader = false;
  $scope.options = {
    sort: 'dates.planned',
    order: 'ASC',
    populate: true
  };
  $scope.params = {
    type: 'visit',
    status: ['Visite à effectuer', 'Visite prévue']
  };

  $scope.query = { states: ['mandatory'] };

  $scope.vstates = {
    mandatory: { label: 'Obligatoire', value: 'mandatory', class: 'warning', htmlLabel: '<span class="label label-warning">&nbsp;</span> Obligatoire' },
    unmandatory: { label: 'Conseillé', value: 'unmandatory', class: 'info', htmlLabel: '<span class="label label-info">&nbsp;</span> Conseillé' },
    collaborator: { label: 'Collaborateur', value: 'collaborator', class: 'default', htmlLabel: '<span class="label label-default">&nbsp;</span> Collaborateur'},
  }

  $scope.vstatesList = Object.values($scope.vstates);

  $scope.networks = {};
  $scope.contracts = {};
  $scope.visitsByNetwork = [];
  var qstates = [];

  $scope.initVisits = function() {
    $scope.loader = true;
    qstates = $scope.query.states || [];
    if(!$scope.query.states || !$scope.query.states.length)
      qstates = Object.keys($scope.vstates);
    var events = events;
    var nids = [];
    async.series([
      function(cb) {
        Event.query({params: $scope.params, search: $scope.search, options: $scope.options}, function (data) {
          events = data || [];
          _.forEach(events, function(e) {
            if(e.state && qstates.indexOf(e.state) > -1)
              nids.push(_.get(e, 'ref.network').toString());
          })
          return cb();
        })
      },
      function(cb) {
        Network.query({params: { ids: _.compact(_.uniq(nids))}, search: $scope.search, options: $scope.options}, function (networks) {
          _.forEach(networks, function(n) {
            $scope.networks[n._id.toString()] = n;
          })
          return cb();
        })
      },
      function(cb) {
        Contract.query({params: { states: 'Actif', networks: _.compact(_.uniq(nids))}, options: { populate: true }}, function (contracts) {
          _.forEach(contracts, function(c) {
            var nid = _.get(c, 'creche.network', '').toString();
            if(nid === '' || !$scope.networks[nid]) return;
            if(!$scope.networks[nid].activeContracts) $scope.networks[nid]['activeContracts'] = 1;
            else $scope.networks[nid]['activeContracts'] += 1;
          })
          return cb();
        })
      }
    ], function(err) {
      formatVisitsByNetwork(events);
      $scope.loader = false;
    })
  }

  var formatVisitsByNetwork = function(events) {
    var vbns = [];
    var networksFound = [];
    _.forEach(events, function(e) {
      if(!e.state || qstates.indexOf(e.state) === -1) return;
      var nid = _.get(e, 'ref.network', '').toString();
      if(nid !== '') {
        if(networksFound.indexOf(nid) === -1) {
          networksFound.push(nid);
          var visits = _.filter(events, function(v) {
            var vnid = _.get(v, 'ref.network', '').toString();
            if(vnid === nid) return v;
          })
          
          var vbn = { 
            count: visits.length || 0,
            network: { 
              _id: nid,  
              name: _.get($scope.networks, [nid, 'name'], ''), 
              count:  _.get($scope.networks, [nid, 'activeContracts'], 0)
            },  
          };
          _.forEach(visits, function(v, i) {
            if(i === 0) vbn.date = _.get(v, 'dates.planned', v.state);
            if(v.state === $scope.vstates.mandatory.value) vbn.state = v.state;
            else if(vbn.state !== $scope.vstates.mandatory.value && v.state === $scope.vstates.unmandatory.value)
              vbn.state = v.state;
            else if(vbn.state !== $scope.vstates.mandatory.value && vbn.state !== $scope.vstates.unmandatory.value && v.state === $scope.vstates.collaborator.value)
              vbn.state = v.state;
          })
          if(vbn.date && vbn.network && vbn.state) vbns.push(vbn);
        }
      }
    })
    $scope.visitsByNetwork = _.sortBy(vbns, 'date');
  }
}]);


app.controller('EventListCtrl', ['$scope', '$anchorScroll', '$window', '_', 'Event', 'Creche', 'Network', '$state', '$stateParams', 'Notif', '$q', '$filter', 'Role', 'User', 'AuthService', 'Application', 'Family', 'Contract', 'ContractStatus', '$uibModal', function ($scope, $anchorScroll, $window, _, Event, Creche, Network, $state, $stateParams, Notif, $q, $filter, Role, User, AuthService, Application, Family, Contract, ContractStatus, $uibModal) {
  /* Init variables */
  $scope.applications = {};
  $scope.families = {};
  $scope.contractStatus = {};
  $scope.family;
  $scope.search = {};
  $scope.params = {
    type: $stateParams.eventType
  };
  $scope.options = {};
  $scope.$state = $state;

  $scope.users = [];
  $scope.creches = {};
  $scope.networks = {};
  $scope.events = [];
  $scope.eventType = $state.current.data.eventType;
  $scope.isTypeEventValid = false;

  $scope.eventEditor = {
    visit: {},
    quality: {}
  }

  $scope.states = {
    'visit': [
      { label: 'Obligatoire', value: 'mandatory', class: 'warning', htmlLabel: '<span class="label label-warning">&nbsp;</span> Obligatoire' },
      { label: 'Conseillé', value: 'unmandatory', class: 'info', htmlLabel: '<span class="label label-info">&nbsp;</span> Conseillé' },
      { label: 'Collaborateur', value: 'collaborator', class: 'default', htmlLabel: '<span class="label label-default">&nbsp;</span> Collaborateur'},
    ],
    'quality': []
  }
  $scope.statuses = {
    'visit' : ['Visite à effectuer', 'Visite prévue', 'Visite effectuée', 'Visite annulée'],
    'quality' : ['Appel à passer', 'Appel complété', 'Messagerie', 'Mail/SMS envoyé', 'Ne pas appeler']
  }

  $scope.results = {
    visit: [],
    quality: [
      { text: 'Satisfait', icon: 'fa-smile-o', color: 'text-green'},
      { text: 'Neutre', icon: 'fa-meh-o', color: 'text-light-blue' },
      { text: 'Non satisfait', icon: 'fa-frown-o', color: 'text-red' }
    ]
  }

  $scope.loader = false;
  $scope.options.sort = 'dates.planned';
  $scope.options.order = 'ASC';

  $scope.params.statuts = [];
  $scope.params.results = [];
  $scope.params.states = [];
  $scope.params.users = [];

  $scope.query = {type: $scope.eventType, statuses: [], results: [],  states: [], users: [], creches: [], networks: [], date: {}, zipCodes: []};


  $scope.paginationLimits = [20, 50, 100, 150, 200];

  /* List variable */
  $scope.pagination = {};
  $scope.pagination.total = 0;
  $scope.pagination.limit = $scope.paginationLimits[0];
  $scope.pagination.currentPage = 1;

  $scope.datePopups = { _base: {opened: false} };

  /* Regex for form */
  $scope.numberRegex = /^[0-9, .-]+$/;

  $scope.content = {
    'title': {
      'visit' : "Visites qualité",
      'quality' : "Appels qualité"
    },
    'c1': {
      'visit' : "Crèche",
      'quality' : "Famille"
    },
    'c2': {
      'visit' : "Statut",
      'quality' : "Statut"
    },
    'c3': {
      'visit' : "Visitée par",
      'quality' : "Appelant"
    },
    'c4': {
      'visit' : "Date de la visite",
      'quality' : "Date de l'appel"
    }
  };


  /* Datepicker options */
  $scope.dateFormat = 'dd/MM/yyyy';
  $scope.dateOptions = {
    startingDay: 1
  };

  $scope.popups = [];
  $scope.open = function (idx) {
    _.set($scope, 'popups[' + idx + '].opened', true);
  };


  var eventTypes = ['visit', 'quality'];
  // Application id of Icare
  var icareApp = '58ad59fabda3fdb4efba6d13';

  $scope.getState = function(type, stateLabel) {
    return _.find($scope.states[type], { value: stateLabel });
  }

  var checkEventType = function () {
    _.each(eventTypes, function (type) {
      if (type === $scope.eventType) {
        $scope.isTypeEventValid = true;
        return;
      }
    })
  }


  var setDisplayUser = function (users) {
    _.each(users, function (user) {
      user['display'] = user.firstname[0].toUpperCase() + '. ' + _.capitalize(user.lastname);
    });
  };

  var setDisplayNetwork = function (networks) {
    _.each(networks, function (network) {
      network['display'] = network._name;
    });
  };

  $scope.formatDate = function (dateToFormate) {
    if (dateToFormate) {
      var tmp = dateToFormate.split(/[- :]/);
      var day = tmp[2].split(/[T]/)[0];
      var date = tmp[0] + '-' + tmp[1] + '-' + day;
      return date;
    } else {
      return null;
    }

  }

  $scope.loadApplications = function(cb) {
    Application.query({}, function(data) {
      _.forEach(data, function(app) {
        $scope.applications[app._id.toString()] = app;
      })
      if(cb) return cb();
    });
  };

  $scope.loadNetworks = function(ids, cb) {
    if(ids.length === 0) {
      if(cb) return cb();
      else return;
    }
    Network.query({ids: ids}, function(data) {
      _.forEach(data, function(n) {
        $scope.networks[n._id] = n;
      })
      if(cb) return cb();
    })
  }

  $scope.initFamilyEvents = function () {
    $scope.loader = true;

    Family.getById({ _id: $stateParams.familyId, populate: true }, function(data) {
      $scope.family = data[0] || {};
      var contractIds = [];
      var children = _.get($scope.family, 'children', []);
      $scope.family.children = {};
      _.forEach(children, function(c) {
        $scope.family.children[c._id] = c;
      })
      async.parallel([
        function(cb) {
          return $scope.loadApplications(cb);
        },
        function(cb) {
          return loadUsers(cb);
        },
        function(cb) {
          return ContractStatus.query({params: {}}, function (data) {
            _.forEach(data, function(cs) {
              $scope.contractStatus[cs._id] = cs;
            })
            return cb();
          })
        },
        function(cb) {
          var params = { type: 'family', family: $stateParams.familyId };
          $scope.family.contracts = {};
          var networkIds = [];
          async.series([
            function(cb) {
              Contract.query({params: params, options: { populate: true }}, function (data) {
                _.forEach(data || [], function(c) {
                  $scope.family.contracts[c._id] = c;
                  contractIds.push(c._id.toString());
                  networkIds.push(_.get(c, ['creche', 'network'], ''));
                })
                networkIds = _.compact(networkIds);
                contractIds = _.compact(contractIds);
                return cb();
              })
            },
            function(cb) {
              Network.query({params: { ids: networkIds }}, function (data) {
                _.forEach(data || [], function(n) {
                  _.forEach($scope.family.contracts, function(c) {
                    if(c.creche && c.creche.network && c.creche.network.toString() === n._id.toString())
                      c.creche.network = n;
                  })
                })
                return cb()
              })
            }
          ], cb)
        }
      ], function() {
        if(contractIds.length === 0) {
          $scope.events = [];
          $scope.loader = false;
          return;
        }
        var options = _.clone($scope.options);
        options.populate = true;
        Event.query({params: { refs: contractIds }, search: {}, options: options}, function (data) {
          $scope.events = data;
          $scope.loader = false;
        })
      })
    })
  }

  $scope.editEvent = function(e) {
    var id = e._id || '_new';
    if($scope.eventEditor[e.type][id])
      delete $scope.eventEditor[e.type][id];
    else {
      $scope.datePopups[id] = angular.copy($scope.datePopups._base);
      var ev = angular.copy(e);
      //if(ev.user && ev.user._id) ev.user = ev.user._id;
      $scope.eventEditor[e.type][id] = ev;
      if(e.type === 'quality') {
        _.set($scope.eventEditor, [$scope.eventType, id, 'locked'], false);
        if(e.status && e.status === 'Appel complété')
          _.set($scope.eventEditor, [$scope.eventType, id, 'locked'], true);
        var d = _.get($scope.eventEditor, [$scope.eventType, id, 'dates', 'planned']);
        if(d) _.set($scope.eventEditor, [$scope.eventType, id, 'dates', 'planned'], new Date(d));
      }
    }
  }

  $scope.saveEvent = function(e) {
    if(e && e.type === 'quality') {
      var action = 'save';
      if (e._id) action = 'update';
      if (e.status === 'Appel complété' && !e.result) {
        return  Notif.error("Appreciation de la famille manquante");
      }
      Event[action](e, function(ev) {
        if (!ev) return Notif.error;
        Notif.success("Appel qualité enregistré avec succès");
        var nb = Object.keys($scope.eventEditor.quality).length;
        $scope.editEvent(e);
        if(nb > 1) {
          if(action === 'save') $scope.events.push(ev);
          for(var i=0; i<$scope.events.length; i++) {
            var event = $scope.events[i];
            if(event._id.toString() === ev._id.toString()) {
              if(ev.user && !ev.user._id) {
                _.forEach($scope.users, function(u) {
                  if(ev.user.toString() === u._id.toString())
                    ev.user = angular.copy(u);
                })
              }
              $scope.events[i] = ev;
              break;
            }
          }
        }
        else $scope.initFamilyEvents();
      }, Notif.error)
    }
  }

  /**
  * Init method to get all networks from the database
  */
  $scope.initEvents = function () {
    // Display loader dot the list
    $scope.loader = true;

    $scope.loadApplications(function() {
      // Check if eventType from stateParams is valid.
      checkEventType();

      //Load stateparams
      $scope.loadStateParams();
    })
  };

  /**
  * Method called when the user changes the pagination
  */
  $scope.changeList = function (cb) {
    // Set options for query
    var options = _.clone($scope.options);
    options.skip = ($scope.pagination.currentPage - 1) * $scope.pagination.limit;
    options.limit = $scope.pagination.limit;
    options.populate = true;

    //Handle params
    var params = _.clone($scope.params);
    

    params.status = [];
    var successStatus = $scope.buildParams(params.statuts, params.status);
    if(successStatus) delete params.statuts;

    params.result = [];
    var successResult = $scope.buildParams(params.results, params.result);
    if(successResult) delete params.results;

    params.state = [];
    var successState = $scope.buildParams(params.states, params.state);
    if(successState) delete params.states;

    params.user = [];
    var successUser = $scope.buildParams(params.users, params.user);
    if(successUser) delete params.users;

    params.zipCode = [];
    var successZipCode = $scope.buildParams(params.zipCodes, params.zipCode);
    if (!successZipCode) delete params.zipCode;
    delete params.zipCodes;

    var cids = [];

    // Perform the query
    async.series([
      function(cb) {
        if ($scope.eventType === 'visit' && !_.isUndefined(params.zipCode)) {
          var params2 = {zipCode: params.zipCode }
          if(params.creches && !_.isEmpty(params.creches)) params2.ids = params.creches;
          if(params.networks && !_.isEmpty(params.networks)) params2.network = params.networks;
          Creche.query({params: params2}, function (creches) {
            _.each(creches, function (value, key) {
              creches[key] = value._id;
            });
            params.creches = _.concat(params.creches, creches);
            return cb();
          })
        }
        else {
          return cb();
        }
      },
      function(cb) {
        Event.query({params: params, search: $scope.search, options: options}, function (data) {
          $scope.events = data || [];
          if($scope.eventType === 'visit') {
            cids = _.compact(_.uniq(_.compact(_.uniq(_.map($scope.events, function(e) {
              return _.get(e, 'ref._id', '').toString();
            })))))
          }
          return cb();
        })
      },
      function(cb) {
        if(!$scope.eventType === 'visit' || !$scope.events.length || cids.length === 0) return cb();
        Creche.query({params: { ids: cids }}, function (creches) {
          _.each(creches, function (c) {
            $scope.creches[c._id] = c;
          });
          return cb();
        })
      },
      function(cb) {
        if(!$scope.eventType === 'visit' || !$scope.events.length || cids.length === 0) return cb();
        Contract.query({params: { states: 'Actif', creches: cids }, options: {}}, function (contracts) {
          _.forEach(contracts, function(c) {
            var cid = _.get(c, 'creche', '').toString();
            if(cid === '' || !$scope.creches[cid]) return;
            if(!$scope.creches[cid].activeContracts) $scope.creches[cid]['activeContracts'] = 1;
            else $scope.creches[cid]['activeContracts'] += 1;
          })
          return cb();
        })
      },
      function(cb) {
        if($scope.eventType === 'quality') return cb();
        var ids = _.map($scope.events, function(e) {
          return e.ref.network;
        })
        $scope.loadNetworks(ids, cb);
      },
      function(cb) {
        if($scope.eventType === 'quality') {
          var ids = [];
          _.forEach($scope.events, function(e) {
            var id = _.get(e, ['ref', 'family'])
            if(id) ids.push(id);
          })
          ids = _.compact(ids);
          Family.query({ params: { _id: { $in: ids } } }, function(data) {
            _.forEach(data, function(f) {
              _.forEach($scope.events, function(e) {
                if(f._id.toString() === _.get(e, ['ref', 'family'], '').toString())
                  $scope.families[e._id] = f;
              })
            })
            return cb();
          })
        }
        else return cb();
      }
    ], cb);
  };


  /*$scope.exportList = function(format) {
    // Set options for query
    var options = _.clone($scope.options);

    //Handle params
    var params = _.clone($scope.params);

    var options = encodeURIComponent(JSON.stringify(options));
    var params = encodeURIComponent(JSON.stringify(params));
    var search = encodeURIComponent(JSON.stringify($scope.search));

    $window.open('/api/creche/export.' + format + '?params=' + params + '&search=' + search + '&options=' + options, '_blank');
  };*/

  /**
  * Return count of the datasource based on params
  */
  $scope.getCount = function () {
    //Handle params
    var params = _.clone($scope.params);

    params.status = [];
    var successStatus = $scope.buildParams(params.statuts, params.status);
    if(successStatus) delete params.statuts;

    params.result = [];
    var successResult = $scope.buildParams(params.results, params.result);
    if(successResult) delete params.results;

    params.state = [];
    var successState = $scope.buildParams(params.states, params.state);
    if(successState) delete params.states;

    params.user = [];
    var successUser = $scope.buildParams(params.users, params.user);
    if(successUser) delete params.users;


    params.zipCode = [];
    var successZipCode = $scope.buildParams(params.zipCodes, params.zipCode);
    if (!successZipCode) delete params.zipCode;
    delete params.zipCodes;

    // Perform the query
    async.series([
      function(cb) {
        if ($scope.eventType === 'visit' && !_.isUndefined(params.zipCode)) {
          var params2 = {zipCode: params.zipCode}
          if(params.creches && !_.isEmpty(params.creches)) params2.ids = params.creches;
          if(params.networks && !_.isEmpty(params.networks)) params2.network = params.networks;
          Creche.query({params: params2}, function (creches) {
            _.each(creches, function (value, key) {
              creches[key] = value._id;
            });
            params.creches = _.concat(params.creches, creches);
            cb();
          })
        }
        else {
          cb();
        }
      },
      function(cb) {
        Event.count({params: params, search: $scope.search}, function (data) {
          //$scope.resetPaging(data.count);
          $scope.pagination.total = data.count;
          cb();
        });
      }
    ]);
  };

  $scope.changeLimit = function () {
    $scope.buildUrlParams();
    $scope.loader = true;
    $scope.changeList(function () {
      $scope.loader = false;
    });
  }

  $scope.paginate = function () {
    $anchorScroll();
    $scope.buildUrlParams();
    $scope.loader = true;
    $scope.changeList(function () {
      $scope.loader = false;
    });
  }

  /**
  * Reset pagging method
  * @param total
  */
  // $scope.resetPaging = function (total) {
  //     $scope.pagination.total = total;
  //     $scope.pagination.offset = 0;
  //     $scope.pagination.currentPage = 1;
  // };

  /**
  * Filter list based on search and params
  */
  $scope.filterList = function () {
    // Display loader dot the list
    $scope.loader = true;

    $scope.pagination.currentPage = 1;

    $scope.params = _.cloneDeep($scope.query);

    if (!_.isEmpty($scope.params.creches)) delete $scope.params.creches;
    if (!_.isEmpty($scope.params.networks)) delete $scope.params.networks;
    //if (!_.isEmpty($scope.params.results)) delete $scope.params.results;

    _.each(['creches', 'networks'], function (key) {
      $scope.params[key] = _.map($scope.query[key], '_id');
    });

    // Get count
    $scope.getCount();

    // Change url to match filter parameters
    $scope.buildUrlParams();

    $scope.changeList(function() {
      $scope.loader = false;
    });
  };

  /**
  * Sort list by key
  * @param key
  */
  $scope.sortList = function (key) {
    $scope.loader = true;
    $scope.options.sort = key;
    $scope.options.order = $scope.options.order == 'DESC' ? 'ASC' : 'DESC';

    $scope.changeList(function () {
      $scope.loader = false;
    });
  };

  /* Build params */
  $scope.buildParams = function (base, real) {
    if (!_.isUndefined(base) && base.length > 0) {
      angular.forEach(base, function (value) {
        if (!_.isUndefined(value._id)) real.push(value._id);
        else if (!_.isUndefined(value.text)) real.push(value.text);
        else if (!_.isUndefined(value)) real.push(value);
      });
      return true;
    }
    else {
      return false;
    }
  };

  /**
  * Load the params from the URL
  */
  $scope.loadStateParams = function (cb) {
    if($stateParams.limit) {
      var limit = parseInt($stateParams.limit);
      if(_.isNaN(limit) || limit < 1) limit = $scope.paginationLimits[0];
      $scope.pagination.limit = $scope.params.limit = limit;
    }
    if($stateParams.page) {
      var page = parseInt($stateParams.page);
      if(_.isNaN(page) || page < 1) page = 1;
      $scope.pagination.currentPage = page;
    }

    if ($stateParams.dateStart) {
      var dStart = moment.utc($stateParams.dateStart, 'DD-MM-YYYY').toDate();
      _.set($scope.params, 'date.start', dStart);
      $scope.query.date.start = dStart;
    }
    if ($stateParams.dateEnd) {
      var dEnd = moment.utc($stateParams.dateEnd, 'DD-MM-YYYY').toDate();
      _.set($scope.params, 'date.end', dEnd);
      $scope.query.date.end = dEnd;
    }

    _.each(['creches', 'networks'], function (key) {
      if ($stateParams[key]) $scope.params[key] = $stateParams[key].split(';');
      else $scope.params[key] = [];
    });

    // Handle async queries
    async.parallel([
      // Users
      function (callback) {
        if (_.isUndefined($stateParams.users)) {
          if($scope.eventType === 'quality') $stateParams.users = _.get(AuthService.getAuthenticatedUser(), ['user', '_id']);
          $state.go('event-list-'+$scope.eventType, $stateParams, { notify: false, reload: false, location: 'replace', inherit: true });
        }

        if ($stateParams.users && $stateParams.users.indexOf(';') != -1) {
          var userIds = _.compact($stateParams.users.split(';'));
          User.query({params: {ids: userIds}}, function (data) {
            angular.forEach(data, function (item, key) {
              $scope.query.users.push(item);
            });
            setDisplayUser($scope.query.users);
            return callback();
          });
        }
        else if($stateParams.users) {
          User.get({_id: $stateParams.users}, function (data) {
            $scope.query.users.push(data);
            setDisplayUser($scope.query.users);
            return callback();
          });
        } else return callback();
      },
      function (cb) {
        if (!$scope.params.creches.length) return cb();
        return Creche.query({params: {ids: $scope.params.creches}}, function (creches) {
          $scope.query.creches = creches;
          cb();
        }, cb);
      },
      function  (cb) {
        if (!$scope.params.networks.length) return cb();
        return Network.query({params: {ids: $scope.params.networks}}, function (networks) {
          setDisplayNetwork(networks);
          $scope.query.networks = networks;
          cb();
        }, cb);
      },

      // Status
      function (callback) {
        if (_.isUndefined($stateParams.status)) {
          var status = _.get($scope, ['statuses', $scope.eventType , '0']);
          if(status) status += ';';
          status += _.get($scope, ['statuses', $scope.eventType , '1']);
          $stateParams.status = status;
          
          $state.go('event-list-'+$scope.eventType, $stateParams, { notify: false, reload: false, location: 'replace', inherit: true });
        }

        if ($stateParams.status.indexOf(';') != -1) {
          var status = $stateParams.status.split(';');
          $scope.query.statuts = status;
        } else {
          $scope.query.statuts = [$stateParams.status];
        }

        return callback();
      },

      // appreciation
      function (callback) {
        if (!_.isUndefined($stateParams.result)) {
          if ($stateParams.result.indexOf(';') != -1) {
            var result = $stateParams.result.split(';');
            $scope.query.results = result;
          } else {
            $scope.query.results = [$stateParams.result];
          }
        }

        return callback();
      },

      // État
      function (callback) {
        if (!_.isUndefined($stateParams.state)) {
          if ($stateParams.state.indexOf(';') != -1) {
            var state = $stateParams.state.split(';');
            $scope.query.states = state;
          } else {
            $scope.query.states = [$stateParams.state];
          }
        }

        return callback();
      },

      // zipCode
      function (callback) {
        if (!_.isUndefined($stateParams.zipCode)) {
          if ($stateParams.zipCode.indexOf(';') != -1) {
            var zipArray = $stateParams.zipCode.split(';');
            $scope.query.zipCodes = zipArray;
          } else {
            $scope.query.zipCodes = [$stateParams.zipCode];
          }
        }

        return callback();
      },
      function (callback) {
        if (_.isUndefined($stateParams.search)) return callback();

        $scope.search.text = $stateParams.search;
        return callback();
      }
    ], function (err, results) {
      $scope.params = _.cloneDeep($scope.query);
      if (!_.isEmpty($scope.params.creches)) delete $scope.params.creches;
      if (!_.isEmpty($scope.params.networks)) delete $scope.params.networks;

      _.each(['creches', 'networks'], function (key) {
        $scope.params[key] = _.map($scope.query[key], '_id');
      });

      // Get data source count
      $scope.getCount();
      loadUsers(function () {
        // init in init Company for rolesId
        $scope.searchUsers = makeSearchFun(User, {roles: $scope.rolesId, application: icareApp}, '_firstname', setDisplayUser);
      });

      $scope.changeList(function () {
        $scope.loader = false;
      });
    });
  };

  /**
  * Method used to build the url based on the filter params
  */
  $scope.buildUrlParams = function () {
    // Init params object
    var params = {};

    if (!_.isEmpty($scope.search)) {
      params.search = '';
      if (!_.isUndefined($scope.search.text) && $scope.search.text != '') {
        params.search = $scope.search.text;
      }
    }

    // Now go through all search params and add them to URL
    if (!_.isEmpty($scope.params)) {
      // Handle array values such as User and Status
      params.users = '';
      if (!_.isEmpty($scope.params.users)) {
        angular.forEach($scope.params.users, function (item, key) {
          if (key == 0) params.users += item._id;
          else params.users += ';' + item._id;
        });
      }

      _.each(['creches', 'networks'], function (key) {
        params[key] = _.get($scope.params, key, []).join(';');
      });

      params.status = '';
      if (!_.isEmpty($scope.params.statuts)) {
        angular.forEach($scope.params.statuts, function (item, key) {
          if (key == 0) params.status += item.text;
          else params.status += ';' + item.text;
        });
      }

      params.result = '';
      if (!_.isEmpty($scope.params.results)) {
        angular.forEach($scope.params.results, function (item, key) {
          if (key == 0) params.result += item.text;
          else params.result += ';' + item.text;
        });
      }

      params.state = '';
      if (!_.isEmpty($scope.params.states)) {
        angular.forEach($scope.params.states, function (item, key) {
          if (key == 0) params.state += item;
          else params.state += ';' + item;
        });
      }

      params.zipCode = '';
      if (!_.isEmpty($scope.params.zipCodes)) {
        angular.forEach($scope.params.zipCodes, function (zipCode, key) {
          if (key == 0) params.zipCode += zipCode.text;
          else params.zipCode += ';' + zipCode.text;
        });
      }

      params.dateStart = _.get($scope.params, 'date.start');
      params.dateEnd = _.get($scope.params, 'date.end');
      if (params.dateStart) params.dateStart = moment(params.dateStart).startOf('day').format('DD-MM-YYYY');
      if (params.dateEnd) params.dateEnd = moment(params.dateEnd).startOf('day').format('DD-MM-YYYY');
    }

    params.page = $scope.pagination.currentPage;
    params.limit = $scope.pagination.limit;
    $state.go('event-list-'+$scope.eventType, params, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
  };



  var makeSearchFun = function (Model, params, optSort, func) {
    return function (query) {
      var deferred = $q.defer();
      Model.query({params: params, search: {text: query}, options: {limit: 20, sort: optSort, order: 'ASC'}}, function (docs) {
        if (func) func(docs);
        deferred.resolve(docs);
      }, Notif.error);
      return deferred.promise;
    };
  };

  $scope.searchCreches = makeSearchFun(Creche, {}, '_name');
  $scope.searchNetworks = makeSearchFun(Network, {}, '_name', setDisplayNetwork);

  /**
   * Load list of all users
   */
  var loadUsers = function (cb) {
    if (!cb) cb = Notif.error;
    if ($scope.eventType == 'quality') {
      Role.query({ params: {label: 'Réseau Crèche'}}, function (roles) {
        $scope.rolesId = _.map(roles, '_id');
        User.query({params: {roles: $scope.rolesId, application: icareApp}, options: {sort: 'firstname', order: 'ASC'}}, function (users) {
          $scope.users = users;
          if (cb !== Notif.error) cb();
        }, cb);
      }, cb);
    } else {
      User.query({params: {application: icareApp}, options: {sort: 'firstname', order: 'ASC'}}, function (users) {
        $scope.users = users;
        if (cb !== Notif.error) cb();
      }, cb);
    }
  };

  /**
   * Source for the ngTagsInput
   */
  $scope.searchStatuses = function (query) {
    //return $scope.statuses[$scope.eventType];
    var deferred = $q.defer();
    deferred.resolve($filter('filter')($scope.statuses[$scope.eventType], query));
    return deferred.promise;
  };

  /**
   * Source for the ngTagsInput
   */
  $scope.searchResults = function (query) {
    var deferred = $q.defer();
    deferred.resolve($filter('filter')($scope.results[$scope.eventType], query));
    return deferred.promise;
  };

  $scope.exportList = function(format) {
    var params = _.clone($scope.params);
    
    params.status = [];
    var successStatus = $scope.buildParams(params.statuts, params.status);
    if(successStatus) delete params.statuts;

    params.result = [];
    var successResult = $scope.buildParams(params.results, params.result);
    if(successResult) delete params.results;

    params.state = [];
    var successState = $scope.buildParams(params.states, params.state);
    if(successState) delete params.states;

    params.user = [];
    var successUser = $scope.buildParams(params.users, params.user);
    if(successUser) delete params.users;

    params.zipCode = [];
    var successZipCode = $scope.buildParams(params.zipCodes, params.zipCode);
    if (!successZipCode) delete params.zipCode;
    delete params.zipCodes;

    var options = _.clone($scope.options);

    var options = encodeURIComponent(JSON.stringify(options));
    var params = encodeURIComponent(JSON.stringify(params));
    var search = encodeURIComponent(JSON.stringify($scope.search));

    $window.open('/api/event/export-quality.' + format + '?params=' + params + '&search=' + search + '&options=' + options, '_blank');
  };

  $scope.locate = function () {
    //Handle params
    var events = [];
    var params = _.clone($scope.params);

    params.status = [];
    var successStatus = $scope.buildParams(params.statuts, params.status);
    if(successStatus) delete params.statuts;

    params.result = [];
    var successResult = $scope.buildParams(params.results, params.result);
    if(successResult) delete params.results;

    params.state = [];
    var successState = $scope.buildParams(params.states, params.state);
    if(successState) delete params.states;

    params.user = [];
    var successUser = $scope.buildParams(params.users, params.user);
    if(successUser) delete params.users;


    params.zipCode = [];
    var successZipCode = $scope.buildParams(params.zipCodes, params.zipCode);
    if (!successZipCode) delete params.zipCode;
    delete params.zipCodes;

    // Perform the query
    async.series([
      function(cb) {
        if ($scope.eventType === 'visit' && !_.isUndefined(params.zipCode)) {
          var params2 = {zipCode: params.zipCode}
          Creche.query({params: params2}, function (creches) {
            _.each(creches, function (value, key) {
              creches[key] = value._id;
            });
            params.creches = _.concat(params.creches, creches);
            cb();
          })
        }
        else {
          cb();
        }
      },
      function(cb) {
        Event.query({params: params, search: $scope.search}, function (data) {
          events = data;
          cb();
        });
      }
    ], function() {
      $state.go('pam', {'crecheId': _.uniq(_.map(events, 'ref._id')).join(';')});
    });
  };
}]);
