'use strict';

app.controller('DashboardCtrl', ['$scope', 'FamilyStatistic', 'Right', '$state', 'AuthService', 'User', '$stateParams', function ($scope, FamilyStatistic, Right, $state, AuthService, User, $stateParams) {
    // Users
    $scope.users = [];
    $scope.application = "icare";
    $scope.applicationClass = "btn-primary";

    $scope.loadUsers = function(cb) {
      Right.getById({ _id: 'COMMERCIAL_HANDLE_FAMILY', populate: true }, function (data) {
        var assignRight = data[0]._id;
        User.query({ params: { active: true }, search: {}, options: { populate: true, sort: '_lastname', order: 'ASC' } }, function (users) {
          angular.forEach(users, function (user, key) {
            var keepLooping = true;
            angular.forEach(user.roles, function (role, key) {
              if (keepLooping) {
                if (_.indexOf(role.rights, assignRight) != -1) {
                  $scope.users.push(user);
                  keepLooping = false;
                }
              }
            });
          });
          cb();
        });
      });
    };

    $scope.changeApplication = function() {
       if ( $scope.application === "icare") {
           $scope.application = null;
           $scope.applicationClass = "btn-default";
       } else {
           $scope.application = "icare";
           $scope.applicationClass = "btn-primary";
       }
       console.log( $scope.application);
       console.log( $scope.applicationClass);
    };

    //$scope.users.unshift({_id: -1, firstname: 'A', lastname : "Distribuer"})
    $scope.noneStatus = 'Non traitées';

    // Priorites
    $scope.formPriorities = {};
    $scope.formPriorities.user = null;
    $scope.canChangeList = true;
    $scope.loadingPriorities = false;
    $scope.priorities = [];
    $scope.totalPriorities = {
        'late' : 0,
        'today' : 0,
        'future' : 0,
        'none' : 0,
        'total' : 0
    };

    //Right
    if (!AuthService.isAuthorized(['COMMERCIAL_MANAGE']) && AuthService.isAuthorized(['COMMERCIAL_HANDLE_FAMILY'])) {
        $scope.formPriorities.user = _.find($scope.users, {'_id' : AuthService.getAuthenticatedUser()._id});
        $scope.canChangeList = false;
    }

    /**
     * Get the stats
     */
    $scope.getPriorities = function() {
        $scope.loadingPriorities = true;
        $scope.totalPriorities = {
            'late' : 0,
            'today' : 0,
            'future' : 0,
            'none' : 0,
            'total' : 0
        };

        var params = {};
        params.application = $scope.application;
        console.log(params);
        if($scope.formPriorities.user) params.userId = $scope.formPriorities.user._id;

        FamilyStatistic.priorities(params, function(data) {
          $scope.priorities = data;
          $scope.priorities.forEach(function(priority) {
              if (priority.late) $scope.totalPriorities.late += priority.late;
              if (priority.today) $scope.totalPriorities.today += priority.today;
              if (priority.future) $scope.totalPriorities.future += priority.future;
              if (priority.none) $scope.totalPriorities.none += priority.none;
              if (priority.total) $scope.totalPriorities.total += priority.total;
          });

          $scope.loadingPriorities = false;

        })
    };

    /**
     * Method used to load stateparams if there exists
     */
    $scope.loadStateParams = function () {
        var userId = null;
        if (!_.isUndefined($stateParams.ca)) userId = $stateParams.ca;
        else userId = JSON.parse(window.localStorage['auth_user'])._id;

        $scope.formPriorities.user = _.find($scope.users, {'_id' : userId});
        $scope.getPriorities();
    };

    /**
     * Redirect the user to the family list page with params
     * @param status
     * @param nextStep
     */
    $scope.goToFamily = function (status, nextStep) {
        var userId = null;
        if(!_.isNull($scope.formPriorities.user)) userId = _.get($scope.formPriorities, 'user._id', null);
        $state.go('family', {'ca': userId, 'status': status, 'nextStep': nextStep});
        //return 'family('+{address: favoriteFullAddress, familyId: family._id}.toString()+')';
    };

    $scope.loadUsers(function() {
      // Laod params in url if there exists
      $scope.loadStateParams();
    });
}]);
