app.filter('capitalize', ['_', function (_) {
        return function (input) {
            if (_.isString(input))
                return _.capitalize(input);
            return input;
        };
    }])
    .filter('uppercase', ['_', function (_) {
        return function (input) {
            if (_.isString(input))
                return _.toUpper(input);
            return input;
        };
    }])
    .filter('lowercase', ['_', function (_) {
        return function (input) {
            if (_.isString(input))
                return _.toLower(input);
            return input;
        };
    }])
    .filter('slugify', ['_', function (_) {
        return function (input) {
            if (_.isString(input))
                return _.kebabCase(_.deburr(input));
            return input;
        };
    }])
    .filter('getObjectInArrayByProperty', [function () {
      return function (collection, propertyName, propertyValue) {
          var i = 0, len = collection.length;
          for (; i < len; i++) {
              if (collection[i][propertyName] == propertyValue) {
                  return collection[i];
              }
          }
          return null;
      };
  }])
  .filter('earnings', ['_', function (_) {
      return function (parents) {
        var earnings = 0;
        _.forEach(parents, function(p) {
          if(p.earning) earnings += p.earning;
        });
        return earnings;
      };
  }])
  .filter('linkUrlWithParams', ['_', function (_) {
      return function (link) {
        var i = 0;
        var url = link.url;
        _.forEach(link.params, function(param, key) {
          if(i === 0) url += '?';
          else url += '&';
          url += key + '=' + param;
          i++;
        });
        return url;
      };
  }])
  .filter('colorize', ['string_to_color', function (GeoPattern) {
      return function (str) {
        return '#' + string_to_color(str);
      };
  }])
  .filter('arrToString', ['_', function (_) {
    return function (arr) {
      return _.map(arr, function(elem) {
        return elem.toString();
      }).join(", ");
    };
  }])
  .filter('valToTime', [function () {
    return function (t) {
      var h = Math.floor(t / 60);
      var min = t - (h * 60);
      function padding(x) {
        return (x < 10 ? "0" + x.toString() : x.toString());
      }
      return padding(h) + "h" + padding(min);
    };
  }])
  .filter('valToAge', [function () {
    return function (a) {
      var age = ["6 semaines", "10 semaines", "12 semaines", "4 mois", "5 mois", "6 mois"];
      if (a >= 1 && a <= age.length)
        return age[a - 1];
      return "";
    };
  }])
  .filter('gender', [function () {
    return function (gender) {
      switch (gender) {
      case 'm':
        return 'Mr';
      case 'f':
        return 'Mme';
      default:
        return '';
      }
    };
  }])
  .filter('phone', [function () {
    return function (phone) {
      var s = "";
      if (phone) {
        var len = phone.length;
        for (var i = 0; i < len; i++) {
          s += phone[i];
          if (i % 2 === 1) s += ' ';
        }
      }
      return s;
    };
  }])
  .filter('_get', ['_', function (_) {
    return function (obj, key, def) {
      return _.get(obj, key, def);
    }
  }])
  .filter('contractState', ['moment', '_', function (moment, _) {
    return function (contract) {
      if (!contract) return '';
      var state;
      var now = moment();
      var des = moment(_.get(contract, 'date.effective.start', moment.invalid()));
      var dee = moment(_.get(contract, 'date.effective.end', moment.invalid()));
    if (["58988b51924119d813a6c95a","58988b51924119d813a6c95b","58988b51924119d813a6c95c","58988b51924119d813a6c95d","58988b51924119d813a6c95e","58988b51924119d813a6c95f"].indexOf(contract.status) !== -1){
            state = 'Perdu';
    }
     else if(['58988b51924119d813a6c964', '58988b51924119d813a6c965'].indexOf(_.get(contract, 'status', 'none')) > -1) {
        if(dee.isAfter(now)) {
          if(des.isSameOrBefore(now)) state = 'Actif';
          else state = 'Embarqué';
        } else state = 'Inactif';
      }
      else state = 'En cours';
      return state;
    };
  }]);
