app.directive("rights", ['AuthService', function(AuthService) {
    return ({
        restrict: "A",
        scope : {
            rights: '='
        },
        link: function link($scope, element, attributes) {
            if (!AuthService.isAuthorized($scope.rights)) {
                element.remove();
            }
        }
    })
}]);