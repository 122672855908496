app.service('Direction', ['$q', 'uiGmapGoogleMapApi', function($q, uiGmapGoogleMapApi) {

    this.getDirections = function(startAddress, endAdrress, wayptAddress, travelMode) {
        var deferred = $q.defer();
        uiGmapGoogleMapApi.then(function() {
            var waypoint = wayptAddress ? [{
              location: wayptAddress,
              stopover: false
            }] : [];
            var request = {
                destination: endAdrress,
                origin: startAddress,
                waypoints: waypoint,
                travelMode: travelMode
            };
            var directionsService = new google.maps.DirectionsService();
            directionsService.route(request, function(response, status) {
                if (status == google.maps.DirectionsStatus.OK) {
                    deferred.resolve(response);
                }else {
                    deferred.reject(status);
                }
            });
        });
        return deferred.promise;
    }

}]);
