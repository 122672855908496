'use strict';

app.controller('GACIdSourceUpdateCtrl', ['$scope', '_', 'ngNotify', 'Family', 'Modal', function ($scope, _, ngNotify, Family, Modal) {
  $scope.sources = ['GA_Paid_Search', 'GA_Organic_Search', 'GA_Direct', 'GA_Social', 'GA_Display', 'GA_Referral', 'GA_Email'];
  $scope.gacids = '';
  $scope.source = $scope.sources[0];
  $scope.report = '';

 $scope.update = function() {
   var gacids = $scope.gacids.split('\n');
   $scope.report += 'Ga ClientIds : ' + gacids.length + '\n';
   var families = [];
   var params = { 'meta.gacid': gacids };
   Family.query({ params: params, search: {}, options: {} }, function(data) {
     var families = data;
     $scope.report += 'Familles : ' + families.length + '\n';
     Modal.confirm().then(function (result) {
       async.eachSeries(gacids, function(gacid, cb) {
         var line = gacid + ' => ';
         var family = _.filter(families, function(f) {
           return f.meta && f.meta.gacid && f.meta.gacid === gacid;
         });
         if(family.length === 0) {
           line += 'X\n';
           $scope.report += line;
           return cb();
         }
         else {
           async.eachSeries(family, function(f, cb) {
             line = gacid + ' => ' + f._id;
             _.set(f, ['meta', 'source'], $scope.source);
             Family.update(f, function (data) {
               if(data && data._id) line += ' - OK\n';
               else line += ' - NOK\n';
               $scope.report += line;
               return cb();
             })
           }, cb)
         }
       }, function() {
         $scope.report += '###################\n';
         ngNotify.set('Mise à jour terminée', 'info');
       })
     })
   })
 }

}])
