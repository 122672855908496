app.controller('ContractModalInstanceCtrl', ['$scope', '$uibModalInstance', '$uibModal', '$state', 'Notif', 'Event', 'Contract', 'Site', 'Network', 'Creche', 'Company', 'Application', 'ContractStatus', 'ContractHistory', 'User', 'Context', 'Role', 'AuthService','Family', '_', function ($scope, $uibModalInstance, $uibModal, $state, Notif, Event, Contract, Site, Network, Creche, Company, Application, ContractStatus, ContractHistory, User, Context, Role, AuthService, Family, _) {
    $scope.pending = false;
    $scope.context = Context;
    $scope.mode = Context.mode;
    $scope.contracts = [];
    $scope.contractsCompany = [];
    $scope.contract = null;
    $scope.dateOptions = {startingDay: 1};
    $scope.popups = [];
    $scope.family = Context.family;
    $scope.history = [];
    $scope.applications = _.get(Context, 'scope.applications', []);

    $scope.networkId = null;
    $scope.crecheId = null;

    $scope.networks = [];
    $scope.creches = [];
    $scope.companies = [];
    $scope.users = [];
    $scope.events = [];
    $scope.statuses = _.get(Context, 'statuses', []);

    // for lock and delete
    $scope.confirmTrigger = null;

    $scope.now = new Date();

    $scope.billings = ['annuel', 'semestriel', 'trimestriel', 'mensuel'];

    $scope.categories = [
        "new business",
        "renouvellement",
        "mouvement",
        "intercrèche"
    ];

    $scope.saleTypes = [
        "Adressé",
        "Commission"
    ];

    $scope.pipes = [
        "cycle court",
        "cycle long",
        "réseau"
    ];

    $scope.reasons = [
        "Déménagement-autre crèche trouvée",
        "Déménagement-perte du contrat",
        "Fin contrat salarié",
        "Autre mode de garde",
        "Place mairie",
        "Jamais entré",
        "Pb ouverture crèche",
        "Tarification ",
        "Congé parental",
        "Exigence des parents",
        "Crèche trop loin",
        "Pas de dispo immédiate",
        "Refus du rythme par le gestionnaire",
        "Reste dans crèche initiale",
        "Enfant entre à l'école",
        "Non reconduction par l'entreprise",
        "Annulation gestionnaire",
        "Annulation entreprise",
        "Raison de santé (Décès enfant, grande prématurité)",
        "0 info",
        "Déménagement à l'étranger",
        "Parents non payeurs",
        "Naissance N-1/ naissance prévue",
        "Insatisfaction des parents vis à vis de la crèche"
    ];

    $scope.capTerminates = [
        "aucune",
        "n'importe quand - avec préavis de 3 mois",
        "n'importe quand - avec préavis de 6 mois",
        "à chaque fin d'année scolaire - avec préavis de 6 mois",
        "à chaque fin d'année scolaire - avec préavis de 3 mois",
        "au terme du contrat - avec préavis de 3 mois",
        "au terme du contrat - avec préavis de 6 mois"
    ];

    $scope.prices = [
        'Paris + 92', 'Province', 'Ile de France (hors 92)',
        'Grandes Villes de Province (10 villes)', 'Petite Couronne (contrat avant 01/01/2017)',
        'Grande Couronne (contrat avant 01/01/2017)'
    ];

    $scope.statuses = [];
    // $scope.statuses = [
    //     'Appel à passer',
    //     'Appel complété',
    //     'Messagerie',
    //     'Mail/SMS envoyé'
    // ];

    $scope.dateValidated = {
        bool: false,
        text: "(Théorique)"
    };

    var user = AuthService.getAuthenticatedUser().user._id;

    var notifObjLabel = (Context.type === 'family' ? 'berceau' : 'contrat');

    $scope.canAffectContract = function (contract) {
        var user = AuthService.getAuthenticatedUser();
        return _.indexOf(user.rights, 'CONTRACT_EDIT') !== -1;
    };

    var loadStatuses = function () {
        if ($scope.statuses.length === 0) {
            ContractStatus.query({}, function (statuses) {
                $scope.statuses = statuses;
            }, Notif.error);
        }
    };

    var loadUsers = function () {
        if ($scope.users.length === 0) {
            Role.query({params: {label: 'Réseau Crèche'}}, function (roles) {
                var rolesId = _.map(roles, '_id');
                User.query({
                    params: {roles: rolesId, active: true},
                    options: {sort: 'lastname', order: 'ASC'}
                }, function (users) {
                    $scope.users = users;
                });
            });
        }
    };

    var getCrecheAndNetwork = function (contract) {
        if (!contract.creche) return null;
        return async.waterfall([
            function (cb) {
                return Creche.get({_id: contract.creche}, function (creche) {
                    _.set(contract, 'populated.creche', creche);
                    return cb(null, creche);
                }, cb);
            },
            function (creche, cb) {
                if (!_.get(creche, '_id')) return cb()
                return Network.get({_id: creche.network}, function (network) {
                    _.set(contract, 'populated.network', network);
                    return cb(null, creche, network);
                });
            }
        ], function (err, creche, network) {
            if (err) return Notif.error(err);
            return null;
        });
    };

    var loadSingleCreche = function (id) {
        if (id) {
            Creche.get({_id: id}, function (creche) {
                if (creche) {
                    $scope.creches = [creche];
                    $scope.networkId = creche.network;
                }
            }, Notif.error);
        }
    };

    $scope.changeChild = function (contract, child) {
        contract.child = child._id;
        contract.date.planned.start = child.startdate;
        contract.date.planned.end = null;
        contract.rythm = child.rythm;
    };

    var newContract = function () {
        var status = _.find($scope.statuses, {label: ''}); //////
        var c = {
            application: _.get(Context, 'family.application', _.get(Context, 'scope.defaultApp')),
            attachment: [],
            name: null,
            family: _.get(Context, 'family._id', null),
            child: null,
            num: null,
            rythm: null,
            status: (status ? status._id : null),
            creche: null,
            company: _.get(Context, 'companyId', null),
            site: _.get(Context, 'family.site', null),
            parent: null,
            rc: null,
            price: {
                buy: 0,
                sale: 0,
                prorata: 0,
                average: 0,
                interest: 0,
                billing: $scope.billings[0],
                anticipated: false,
                free: false
            },
            date: {
                signed: null,
                bdc: null,
                planned: {start: null, end: null},
                real: {start: null, end: null},
                cancelled: null,
                terminated: null
            },
            //category: null,//$scope.categories[0],
            type: Context.type,
            //pipe: null,//$scope.pipes[0],
            //saleType: null,
            locked: false,
            mail: false,
            capTerminate: $scope.capTerminates[0],
            reason: undefined,
            mulitple_prices: false
        };
        $scope.changeChild(c, _.get(Context, 'family.children[0]', {}));
        return c;
    };

    var formatDate = function (date) {
        var dates = [
            'planned.start', 'planned.end', 'real.start', 'real.end',
            'cancelled', 'terminated', 'bdc', 'signed',
            'effective.start', 'effective.end', 'priceAnticipated',
            'free.start', 'free.end', 'call', 'interest', 'validated.manager',
            'validated.commercial', 'validated.update'
        ];
        _.each(dates, function (d) {
            var dt = _.get(date, d);
            if (dt) _.set(date, d, new Date(dt));
        });
    };

    var resetContract = function (contract) {
        $scope.networkId = null;
        $scope.crecheId = null;
        if (contract) {
            var c = _.cloneDeep(contract);
            formatDate(c.date);
            if (c.creche) loadSingleCreche(c.creche);
            if (c.company) $scope.loadContracts(c.company, 'company');
            return c;
        }
        return newContract();
    };

    var setupNetworkData = function (contract) {
        var n = _.find($scope.networks, function (network) {
            return network._id === $scope.networkId;
        }) || {};
        contract.price.interest = n.interest || contract.price.interest;
        contract.date.interest = new Date(n.interestDate) || contract.price.interest;
    };

    $scope.loadCreches = function () {
        if ($scope.contract) $scope.contract.creche = null;
        Creche.query({params: {network: $scope.networkId}, options: {sort: '_name', order: 'ASC'}}, function (data) {
            $scope.creches = data;
            setupNetworkData($scope.contract);
        }, Notif.error);
    };

    var loadNetworks = function () {
        Network.query({options: {sort: '_name', order: 'ASC', populate: true}}, function (data) {
            $scope.networks = data;
        }, Notif.error);
    };

    var loadCompanies = function () {
        var companyIds = [];
        _.each(_.get(Context, 'family.parents'), function (parent) {
            var id = _.get(parent, 'company.reference');
            if (id) companyIds.push(id);
        });
        if (companyIds.length) {
            Company.query({params: {ids: companyIds}}, function (data) {
                $scope.companies = data;
            }, Notif.error);
        }
    };

    $scope.setContract = function (contract) {
        $scope.contract = resetContract(contract);
    };

    $scope.loadContracts = function (id, type) {
        var params = {};
        params['type'] = type;
        params[type] = id;
        if ($scope.contract) $scope.contract.parent = null;
        Contract.query({params: params, options: {sort: 'name', order: 'ASC'}}, function (contracts) {
            if (type === 'company') {
                $scope.contractsCompany = contracts;
            } else if (type === 'family') {
                $scope.contracts = contracts;
                _.each(contracts, function (c) {
                    formatDate(c.date);
                });
                _.each(contracts, getCrecheAndNetwork);
                Context.nbContracts.count = contracts.length;
            }
        });
    };

    $scope.getParentContract = function(parent) {
        return _.find($scope.contractsCompany, {_id: parent});
      }

    var loadContract = function (cb) {
        Contract.get({_id: $scope.contract._id}, function (contract) {
            $scope.contract = contract;
            $scope.$parent.mode = $stateParams.mode;
            $scope.$parent.contract = contract;
            return cb();
        });
    };

    $scope.getDate = function (date) {
        var loc = moment.locale();
        moment.locale('fr');
        var newDate = moment(date).format('dddd D MMMM YYYY');
        moment.locale(loc);
        return newDate;
    };

    $scope.save = function (event) {
        event.type = 'quality';
        event.ref = $scope.contract._id;
        if (!_.isUndefined(event.dates.planned) && !event.dates.planned.toString().length) {
            delete event.dates.planned;
        }
        if (!_.isUndefined(event.status) && event.status.length) {
            _.set(event, 'dates.real', event.dates.planned);
        }
        if (_.isEmpty(event.user._id) || _.isNull(event.user._id)) {
            event.user = null;
        }

        if (event._id) {
            Event.update(event, function (ev) {
                if (!ev) return;
                Notif.success("L'appel a bien été enregistré");
                $scope.initEvents();
            }, Notif.error);
        }
        else {
            Event.save(event, function (ev) {
                if (ev && ev._id) {
                    Notif.success("L'appel a bien été créé");
                    $scope.initEvents();
                }
                else return;
            }, Notif.error);
        }
    };

    $scope.initEvents = function () {
        $scope.editing = false;
        $scope.dateValidated = {
            bool: true,
            text: "(Validée)"
        };
        async.series([
            function (cb) {
                loadUsers();
                cb();
            },
            function (cb) {
                var contractId = $scope.contract._id;
                Event.query({
                    params: {type: 'quality', ref: contractId},
                    options: {sort: 'created', order: 'DESC'}
                }, function (events) {
                    $scope.events = events;
                    $scope.event = (events.length && _.get($scope, 'events[0].status') == 'Appel à passer') ? $scope.events[0] : null;
                    if (_.isNull($scope.event)) {
                        $scope.event = {};
                        if (_.indexOf($scope.users, user) > -1) $scope.event.user = user;
                        var dateMetric = !events.length ? 'month' : 'year';
                        var dateRef = !events.length ? $scope.contract.date.effective.start : $scope.events[0].dates.real;
                        _.set($scope.event, 'dates.planned', moment(dateRef).add(1, dateMetric).toDate());
                        $scope.dateValidated.text = "(Théorique)";
                        $scope.dateValidated.bool = false;
                    }
                    else {
                        _.forEach(['planned', 'real'], function (e) {
                            if (_.get($scope.event, 'dates[' + e + ']', null)) {
                                $scope.event.dates[e] = moment($scope.event.dates[e]).toDate();
                            }
                        });
                        if (!_.get($scope.event, 'user._id', null)) _.set($scope.event, 'user._id', $scope.event.user);
                    }
                    return cb();
                });
            }
        ]);
    };

    // This openModal() is here for the modal of contract.
    $scope.openModal = function (ev, mode) {
        var options = {
            animation: false,
            backdrop: 'static',
            keyboard: true,
            size: 'md',
            templateUrl: '/partials/ui/contractEvent.html',
            resolve: {
                Context: function () {
                    return {
                        event: ev,
                        mode: mode,
                        contract: $scope.contract,
                        scope: $scope,
                        statuses: $scope.statuses,
                        users: $scope.users
                    };
                }
            },
            controller: 'ContractEventModalInstanceCtrl'
        };
        var modalInstance = $uibModal.open(options);

        modalInstance.result.then(function () {
            $scope.initEvents();
        });
    };

    $scope.initContracts = function () {
        $scope.contract = resetContract(Context.contract);
        $scope.contracts = $scope.loadContracts(Context.id, 'family');
        loadNetworks();
        loadCompanies();
        loadStatuses();
        loadUsers();
    };


    $scope.getUser = function (id) {
        var i = _.findIndex($scope.users, {_id: id});
        return (i === -1 ? '' : $scope.users[i].firstname[0].toUpperCase() + ' ' + _.capitalize($scope.users[i].lastname) );
    };

    $scope.getChild = function (id) {
        var i = _.findIndex(Context.family.children, {_id: id});
        return (i === -1 ? {} : Context.family.children[i]);
    };

    $scope.getStatus = function (id) {
        var i = _.findIndex($scope.statuses, {_id: id});
        return (i === -1 ? '' : $scope.statuses[i]);
    };

    $scope.getCompany = function (id) {
        var i = _.findIndex($scope.companies, {_id: id});
        return (i === -1 ? '' : $scope.companies[i].name || $scope.companies[i].denomination);
    };

    $scope.newContract = function () {
        $scope.mode = 'add';
        $scope.contract = resetContract();
    };

    $scope.isMultiplePriceSales = function (contract) {
        var c = contract || $scope.contract;
        var p = _.get(c, ['price', 'sales']);
        return (p && p.length > 0);
    }

    $scope.switchPriceSaleMode = function (contract) {
        var c = contract || $scope.contract;
        if ($scope.isMultiplePriceSales()) {
            _.set(c, ['price', 'sale'], 0);
            _.unset(c, ['price', 'sales']);
        }
        else {
            _.set(c, ['price', 'sales'], []);
            _.forEach($scope.prices, function (p) {
                c.price.sales.push({label: p, value: 0});
            })
            _.unset(c, ['price', 'sale']);
        }
    }

    $scope.viewList = function () {
        $scope.contracts = $scope.loadContracts(Context.id, 'family');
        $scope.contract = null;
        $scope.mode = 'list';
    };

    $scope.eventsView = function (contract) {
        $scope.mode = 'event';
        $scope.contract = contract;
        $scope.initEvents();
    };

    $scope.view = function (contract) {
        $scope.contract = resetContract(contract);
        $scope.mode = 'view';
    };

    $scope.edit = function (contract) {
        $scope.contract = resetContract(contract);
        $scope.mode = 'edit';
    };

    $scope.delete = function (contract) {
        $scope.contract = contract;
        $scope.mode = 'confirm';
        $scope.confirmTrigger = $scope.confirmDelete;
    };

    $scope.getHistory = function (contract) {
        $scope.contract = contract;
        $scope.mode = 'history';
        ContractHistory.query({contract: contract._id}, function (history) {
            $scope.history = history;
        }, Notif.error);
    };

    $scope.confirmDelete = function () {
        Contract.delete($scope.contract, function () {
            Notif.success('Le ' + notifObjLabel + ' a bien été supprimé');
            $scope.loadContracts(Context.id, Context.type);
            $scope.viewList();
        }, Notif.error);
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

    $scope.checkParentContract = function (contract) {
        var check = !(contract.company && contract.parent);

        if (check) {
            Notif.error("Il est nécessaire de préciser une entreprise et un contrat pour créer/modifier un berceau");
            return false;
        } else return true;
    };


    var save = function (quit) {
        if ($scope.isMultiplePriceSales()) {
            _.remove(_.get($scope.contract, ['price', 'sales'], []), function (s) {
                return s.value === '0';
            })
        }
        else {
            if (_.get($scope.contract, ['price', 'sale'], 0) === '0')
                _.set($scope.contract, ['price', 'sale'], null);
        }
        $scope.pending = true;
        Contract.save(_.omit($scope.contract, 'populated', 'mulitple_prices'), function (contract) {
            $scope.pending = false;
            Notif.success('Le ' + notifObjLabel + ' a bien été crée');
            $scope.loadContracts(Context.id, Context.type);
            if (quit) $uibModalInstance.close('confirm');
            else $scope.viewList();
        }, function(err) {
            $scope.pending = false;
            Notif.error(err);
        });
    };

    $scope.update = function (contract) {
        if ($scope.isMultiplePriceSales()) {
            _.remove(_.get($scope.contract, ['price', 'sales'], []), function (s) {
                return s.value === 0;
            })
        }
        else {
            if (_.get($scope.contract, ['price', 'sale'], 0) === 0)
                _.set($scope.contract, ['price', 'sale'], null);
        }
        _.each(['company', 'parent', 'status', 'creche', 'user'], function (key) {
            if (!contract[key]) contract[key] = null;
        });
        $scope.pending = true;
        Contract.update(_.omit(contract, 'populated', 'mulitple_prices'), function (contract) {
            $scope.pending = false;
            Notif.success('Le ' + notifObjLabel + ' a bien été modifié');
        }, function(err) {
            $scope.pending = false;
            Notif.error(err);
        });
    };

    var update = function (quit) {
        $scope.pending = true;
        Contract.update(_.omit($scope.contract, 'populated'), function (contract) {
            $scope.pending = false;
            var i = _.findIndex($scope.contracts, {_id: contract._id});
            $scope.contracts[i] = contract;
            Notif.success('Le ' + notifObjLabel + ' a bien été modifié');
            if (quit) $uibModalInstance.close('confirm');
            else $scope.viewList();
        }, function(err) {
            $scope.pending = false;
            Notif.error(err);
        });
    };

    $scope.canRegister = function () {
        var auth = AuthService.getAuthenticatedUser();
        var can = _.indexOf(auth.rights, 'CONTRACT_EDIT') !== -1;
        var commercial = _.get($scope.contract, ['validated', 'commercial']);
        var manager = _.get($scope.contract, ['validated', 'manager'])
        if (commercial || manager)
            can = can && _.indexOf(auth.rights, 'CONTRACT_VALIDATE') !== -1
        return can;
    }

    $scope.contractSelect = function () {
        var c = $scope.contract;
        var p = _.find($scope.contractsCompany, {_id: c.parent});
        var toCopyArr = [
            'price.sale',
            'price.sales',
            'date.signed',
            'price.billing'
        ];
        _.each(toCopyArr, function (toCopy) {
            _.set(c, toCopy, _.get(p, toCopy));
        });
    };

    $scope.close = function () {
        $uibModalInstance.close();
    };

    var isNotNull = function (val) {
        return !_.isNull(val);
    }

    var isNotUndefined = function (val) {
        return !_.isUndefined(val);
    }

    var isNotEmpty = function (val) {
        return !_.isEmpty(val);
    }

    var isNotNumber = function (val) {
        return !_.isNumber(val);
    }

    var isValidPrice = function (val) {
        return (isNotNull(val) && isNotUndefined(val) && isNotNumber(val) && val > 0);
    }

    var checkStatus = function (val) {
        var valid = true;
        var statuses = ["58988b51924119d813a6c963", "58988b51924119d813a6c964", "58988b51924119d813a6c965"];
        var status = _.get($scope, ['contract', 'status'], '');
        if (statuses.indexOf(status) !== -1 && (_.isNull(val) || _.isUndefined(val) || val === ''))
            valid = false;
        return valid;
    }

    var isBDC = function(val){
        let itIs = true;
        let bdc = ["58988b51924119d813a6c964", "58988b51924119d813a6c965"]; // 1st: n__bdr-envoye  2nd: o__bdc-envoye 3rd: p__bdc-signe
        let stat = _.get($scope, ['contract', 'status'], '');
        if(bdc.indexOf(stat) === -1 ){
            itIs = false;
        }
        return itIs;
    }

    //return true if status is = I or J
    var isStatusLost = function(currentStatus) {
        let lost = true;
        let statusesLost = ["58988b51924119d813a6c95a","58988b51924119d813a6c95b","58988b51924119d813a6c95c","58988b51924119d813a6c95d","58988b51924119d813a6c95e","58988b51924119d813a6c95f"];
        if(statusesLost.indexOf(currentStatus) === -1 )
            lost = false;
        return lost;
    }

    var checkPrices = function (val) {
        var valid = false;
        if ($scope.isMultiplePriceSales()) {
            _.forEach(_.get($scope.contract, ['price', 'sales'], []), function (s) {
                if (!valid) valid = isValidPrice(s.value);
            })
        }
        else valid = isValidPrice(_.get($scope.contract, ['price', 'sale']));
        return valid;
    }

    var checkDates = function (val) {
        var flag = true;

        if (!_.isNull(val))
            flag = moment(val).isSameOrAfter(moment($scope.contract.date.effective.start));

        return (flag);
    }

    var setEffectiveStart = function (date) {
        var attrs = ['planned.start', 'real.start'];
        var d;
        _.each(attrs, function (attr) {
          var v = _.get(date, attr);
          if (v) d = v;
        });
        if (d) _.set(date, 'effective.start', d);
      }
    
      var setEffectiveEnd = function (date) {
        var attrs = [ "planned.end", "real.end", "cancelled" ];
        var d;
        _.each(attrs, function (attr) {
          var v = _.get(date, attr);
          if (v) d = v;
        });
        if (d) _.set(date, 'effective.end', d);
      }

    var isBDC = function(val){
        let itIs = true;
        let bdcr = ["58988b51924119d813a6c964", "58988b51924119d813a6c965"]; // 1st: o__bdc-envoye 2nd: p__bdc-signe
        let stat = _.get($scope, ['contract', 'status'], '');
        if(bdcr.indexOf(stat) === -1 ){
            itIs = false;
        }
        return itIs;
    }

    //if the contract is edited to BDC then flag becomes automatically attributaire
    $scope.isAttributaire = function(){
        if(isBDC($scope.contract)){
            $scope.family.flag = "attributaire";
            Family.update($scope.family);
        }
        return true;
    }

    $scope.canSubmit = function () {
        var submit = true;
        if ($scope.contract.date) {
            setEffectiveStart($scope.contract.date);
            setEffectiveEnd($scope.contract.date);
        }

        var validations = _.filter([
            {key: 'pipe', label: 'Canal de vente', type: 'company', func: [isNotNull, isNotUndefined, isNotEmpty]},
            {key: 'date.signed', label: 'Signature du contrat', type: 'company', func: [isNotNull, isNotUndefined]},
            {key: 'price.sale', label: 'Prix de vente annuel unitaire (ETP)', type: 'company', func: checkPrices},
            {
                key: 'price.buy',
                label: 'Prix d\'achat annuel (ETP)',
                type: 'family',
                func: checkStatus,
                mess: 'Données manquantes pour valider ce statut'
            },
            {
                key: 'price.sale',
                label: 'Prix de vente annuel (ETP)',
                type: 'family',
                func: checkStatus,
                mess: 'Données manquantes pour valider ce statut'
            },
            {
                key: 'date.planned.start',
                label: 'Date d\'entrée (réelle)',
                type: 'family',
                func: checkStatus,
                mess: 'Données manquantes pour valider ce statut'
            },
            {
                key: 'date.planned.end',
                label: 'Date de sortie (prévue)',
                type: 'family',
                func: checkStatus,
                mess: 'Données manquantes pour valider ce statut'
            },
            {
                key: 'company',
                label: 'Entreprise',
                type: 'family',
                func: checkStatus,
                mess: 'Données manquantes pour valider ce statut'
            },
            {
                key: 'creche',
                label: 'Crèche',
                type: 'family',
                func: checkStatus,
                mess: 'Données manquantes pour valider ce statut'
            },
            {
                key: 'category',
                label: 'Catégorie',
                type: 'family',
                func: checkStatus,
                mess: 'Données manquantes pour valider ce statut'
            },
            /*{
                key: 'date.effective.end',
                label: 'Date de sortie (effective)',
                type: 'family',
                func: checkDates,
                mess: 'La date de sortie effective doit être supérieure à la date effective d\'entrée'
            }*/
        ], {type: _.get($scope.contract, 'type')})
        for (var i = 0; i < validations.length; i++) {
            var v = validations[i];
            var val = _.get($scope.contract, v.key);
            if (!_.isArray(v.func)) v.func = [v.func];
            for (var j = 0; j < v.func.length; j++) {
                if (v.func[j](val) === false) {
                    submit = false;
                    break;
                    //j = v.func.length;
                }
            }
            if (!submit) {
                var message = 'Le champ "' + v.label + '" est obligatoire, merci de le renseigner.';
                if (v.mess) message = v.mess + ' ("' + v.label + '" doit être renseigné)';
                Notif.set(message, 'warn');
                i = validations.length;
            }
        }
        //if commission && BDC envoye/signe && num crèche virtuelle not set then false
        if(_.get($scope.contract, 'num')== null && isBDC($scope.contract) && _.get($scope.contract, 'saleType') === "Commission"){
            submit = false;
            var msg = 'Le champ Numéro de berceaux (crèche virtuelle) est obligatoire, merci de le renseigner.';
            Notif.set(msg, 'warn');
        }
        return submit;
    }

    $scope.confirm = function (quit) {
        _.each(['company', 'parent', 'status', 'creche', 'user', 'application'], function (key) {
            if (!$scope.contract[key]) $scope.contract[key] = null;
        });

        if ($scope.mode === 'add') save(quit);
        else if ($scope.mode === 'edit') update(quit);
    };

    $scope.open = function (idx) {
        _.set($scope, 'popups[' + idx + '].opened', true);
    };

    $scope.confirmLock = function () {
        $scope.contract.locked = true;
        Contract.update({_id: $scope.contract._id, locked: true}, function () {
            Notif.success('Le ' + notifObjLabel + ' a bien été verrouillé');
            $scope.viewList();
        }, Notif.error);
    };

    $scope.confirmUnlock = function () {
        $scope.contract.locked = false;
        Contract.update({_id: $scope.contract._id, locked: false}, function () {
            Notif.success('Le ' + notifObjLabel + ' a bien été déverrouillé');
            $scope.viewList();
        }, Notif.error);
    };

    $scope.unlock = function (contract) {
        $scope.contract = contract;
        $scope.mode = 'confirm';
        $scope.confirmTrigger = $scope.confirmUnlock;
    };

    $scope.lock = function (contract) {
        $scope.contract = contract;
        $scope.mode = 'confirm';
        $scope.confirmTrigger = $scope.confirmLock;
    };

    $scope.getName = function (item) {
        if (item.type === 'assign') {
            var user = _.find($scope.users, {_id: item.reference});
            if (user) return user.firstname[0] + '. ' + _.capitalize(user.lastname);
        } else if (item.type === 'status') {
            var o = _.find($scope.statuses, {_id: item.reference});
            return (o ? o.label : 'Inconnu');
        }
        return "Inconnu";
    };

    $scope.getType = function (type) {
        return (type === 'assign' ? 'Assignation' : 'Changement de statut');
    };

    $scope.getLastname = function (family) {
        var lastname = _.get(family, 'parents[0].lastname', '');
        if (_.isEmpty(lastname)) lastname = _.get(family, 'parents[1].lastname', '');
        return _.capitalize(lastname);
    };

    $scope.activeState = function (contract) {
        var state;
        if (!contract) return '';
        var date = contract.date;
        // BDC signe || envoye
        if (contract.status === '58988b51924119d813a6c964' || contract.status === '58988b51924119d813a6c965') {
            if (_.get(date, 'effective.end') > $scope.now) {
                if (_.get(date, 'effective.start') <= $scope.now) state = "Actif";
                else state = "Embarqué";
            } else {
                state = "Inactif";
            }
        }
        else if (isStatusLost(contract.status)){
            state = "Perdu";
        }
        else {
            state = "En cours";
        }
        return state;
    };

    $scope.duplicate = function (contract) {
        $scope.mode = 'add';
        $scope.contract = resetContract(contract);
        delete $scope.contract.name;
        delete $scope.contract._id;
    };

    $scope.calcProrata = function (type) {
        var p = _.get($scope.contract, 'price.' + type, 0);
        var r = _.get($scope.contract, 'rythm') || 5;
        return p / 5.0 * r;
    };

}]);

app.controller('ContractValidationModalInstanceCtrl', ['$scope', '$uibModalInstance', '$state', 'Notif', 'Contract', 'Site', 'Network', 'Creche', 'Company', 'Application', 'User', 'Context', 'Role', 'AuthService', '_', function ($scope, $uibModalInstance, $state, Notif, Contract, Site, Network, Creche, Company, Application, User, Context, Role, AuthService, _) {
    $scope.context = Context;
    $scope.contract = Context.contract;
    $scope.users = {};

    var loadUsers = function (cb) {
        var users = [];
        var commercial = _.get($scope.contract, ['validated', 'commercial']);
        var manager = _.get($scope.contract, ['validated', 'manager']);
        if (commercial) users.push(commercial);
        if (manager) users.push(manager);
        if (users.length > 0) {
            User.query({params: {ids: users}}, function (users) {
                _.forEach(users, function (u) {
                    u.display = _.get(u, 'firstname.0', '').toUpperCase();
                    if (u.display && !_.isEmpty(u.display)) u.display += '. ';
                    u.display += _.get(u, 'lastname', '').toUpperCase();
                    if (u._id === commercial) $scope.users.commercial = u;
                    if (u._id === manager) $scope.users.manager = u;
                })
                if (cb) return cb($scope.users);
            });
        }
    }
    loadUsers();

    $scope.close = function () {
        $uibModalInstance.close();
    }

    var invalidate = function (role) {
        _.set($scope.contract, ['validated', 'commercial'], null);
        _.set($scope.contract, ['date', 'validated', 'commercial'], null);

        Contract.update(_.omit($scope.contract, 'populated'), function (contract) {
            var msg;
            if (role === 'commercial') msg = 'La demande de validation du contrat a bien été annulée';
            if (role === 'manager') msg = 'La validation du contrat a bien été prise en compte';
            Notif.success(msg);
            $scope.context.reloadContracts();
            $scope.close();
        }, Notif.error);
    }

    var validate = function (role) {
        _.set($scope.contract, ['validated', role], AuthService.getAuthenticatedUser().user._id);
        _.set($scope.contract, ['date', 'validated', role], new Date());

        Contract.update(_.omit($scope.contract, 'populated'), function (contract) {
            var msg;
            if (role === 'commercial') msg = 'La demande de validation du contrat a bien été prise en compte';
            if (role === 'manager') msg = 'La validation du contrat a bien été prise en compte';
            Notif.success(msg);
            $scope.context.reloadContracts();
            $scope.close();
        }, Notif.error);
    }

    $scope.validateCommercial = function () {
        return validate('commercial');
    }

    $scope.validateManager = function () {
        return validate('manager');
    }

    $scope.invalidateCommercial = function () {
        return invalidate('commercial');
    }

    $scope.invalidateManager = function () {
        return invalidate('manager');
    }

    $scope.isValidateCommercialOwner = function () {
        var auth = AuthService.getAuthenticatedUser();
        return auth.user._id === _.get($scope.contract, ['validated', 'commercial']);
    }

}]);

app.controller('ContractEventModalInstanceCtrl', ['$scope', '$uibModalInstance', '$q', '$state', 'Notif', 'Event', 'Site', 'Role', 'User', 'Context', 'AuthService', '_', function ($scope, $uibModalInstance, $q, $state, Notif, Event, Site, Role, User, Context, AuthService, _) {
    $scope.context = Context;
    $scope.contract = _.get(Context, 'contract', null);
    $scope.creche = _.get(Context, 'creche', null);
    $scope.users = _.get(Context, 'users', []);
    $scope.usersCA = [];
    $scope.statuses = _.get(Context, 'statuses', []);
    $scope.forms = _.get(Context, 'forms', []);
    $scope.form = null;
    $scope.popup = false;
    $scope.isFillForm = false;
    $scope.editOtherCrecheVisits = false;
    $scope.editFirstCrecheVisit = false;
    $scope.site = {};


    /* Datepicker options */
    $scope.dateOptions = {
        startingDay: 1
    };

    var mode = Context.mode;
    // Application id of Icare
    var icareApp = '58ad59fabda3fdb4efba6d13';
    // setDate is used when assigning a obj (with a Date field), it is used to transform Date to allow assign
    var setDate = function (ev) {
        if (!ev) return {};
        _.forEach(['planned', 'real'], function (e) {
            if (_.get(ev, 'dates[' + e + ']', null)) {
                ev.dates[e] = moment(ev.dates[e]).toDate();
            }
        });
        if (!_.get(ev, 'user._id', null)) _.set(ev, 'user._id', ev.user);
        return ev;
    };

    $scope.event = setDate(Context.event);

    var setDisplayUser = function (users) {
        _.each(users, function (user) {
            if (user.firstname && user.firstname[0]) {
                user['display'] = user.firstname[0].toUpperCase() + '. ' + _.capitalize(user.lastname);
            }
        });
    };

    var makeSearchFun = function (Model, params, func) {
        return function (query) {
            var deferred = $q.defer();
            Model.query({
                params: params,
                search: {text: query},
                options: {limit: 20, sort: 'firstname', order: 'ASC'}
            }, function (docs) {
                if (func) func(docs);
                deferred.resolve(docs);
            }, Notif.error);
            return deferred.promise;
        };
    };

    $scope.searchUsers = makeSearchFun(User, {application: icareApp}, setDisplayUser);

    $scope.visitIsNotEditable = function (visit) {
        if (!visit || !Object.keys(visit).length) return false;
        var isFirstVisit = !!visit.isFirstVisit;
        return (isFirstVisit && !$scope.editFirstCrecheVisit) || (!isFirstVisit && !$scope.editOtherCrecheVisits);
    };

    $scope.initUsers = function () {
        $scope.form = ($scope.event && $scope.event.form) ? $scope.event.form: null;
        AuthService.initVisitRights($scope);
        if($scope.event.user && $scope.event.user._id) {
            $scope.usersCA.push($scope.event.user);
            if (!_.isEmpty($scope.event.users)) {
                $scope.usersCA = _.concat($scope.usersCA, $scope.event.users);
            }
            setDisplayUser($scope.usersCA);
        }
    }

    $scope.initUsersInactive = function () {
        if (!_.isUndefined($scope.event.user)) {
            if (!_.isNull($scope.event.user) && !_.isNull($scope.event.user._id) && _.isBoolean($scope.event.user.active) && !$scope.event.user.active) {
                $scope.users.push($scope.event.user);
            }
        }
    }

    $scope.disabled = (Context.mode == 'editCom') ? (!_.isUndefined($scope.event.status) && !_.isEmpty($scope.event.status)) : false;

    $scope.cancel = function () {
        var obj = {isFillForm: $scope.isFillForm, visit: $scope.event, form: $scope.form};
        if (mode === 'visit') $uibModalInstance.close(obj);
        else $uibModalInstance.close();
    };

    $scope.save = function (event, mode, b) {
        if (mode === 'edit' || mode === 'editCom') {
            saveQuality(event);
        }
        else if (mode === 'visit') {
            saveVisit(event, b);
        }
    };

    var saveQuality = function (event) {
        event.type = 'quality';
        event.ref = $scope.contract._id;
        if (!_.isUndefined(event.dates.planned) && !event.dates.planned.toString().length) {
            delete event.dates.planned;
        }
        if (!_.isUndefined(event.status) && event.status.length) {
            _.set(event, 'dates.real', event.dates.planned);
        }
        if (_.isEmpty(event.user._id) || _.isNull(event.user._id)) {
            event.user = null;
        }

        if (event._id) {
            Event.update(event, function (ev) {
                if (!ev) return;
                Notif.success("L'appel a bien été enregistré");
                $scope.cancel();
            }, Notif.error);
        }
        else {
            Event.save(event, function (ev) {
                if (ev && ev._id) {
                    Notif.success("L'appel a bien été créé");
                    $scope.cancel();
                }
                else return;
            }, Notif.error);
        }
    };

    var getSite = function(cb) {
      if($scope.site && $scope.site._id) return cb($scope.site);
      Site.query({params: {'key': 'visit-mpec', active: true}}, function (data) {
        $scope.site = data[0] || {};
        return cb($scope.site);
      })
    }

    var saveVisit = function (event, isFillForm) {
        if (_.isEmpty($scope.form) && event.status === 'Visite effectuée') {
            Notif.error('Le champ \'Formulaire\' doit être rempli');
            return;
        }
        if (!event.status || event.status === 'Visite à effectuer') {
            Notif.error('Le champ \'Statut\' doit être rempli et différent de \'Visite à effectuer\'');
            return;
        }
        if ((_.isUndefined(event.dates) || _.isUndefined(event.dates.planned) || _.isNull(event.dates.planned))) {
            Notif.error('Le champ \'Date\' doit être rempli');
            return;
        }
        if (!event.state) {
            Notif.error('Le champ \'État\' doit être rempli');
            return;
        }
        event.type = 'visit';
        event.ref = $scope.creche._id;
        var users = _.cloneDeep($scope.usersCA);
        event.user = !_.isEmpty(users) ? users.shift() : null;
        event.users = users;
        if (event.dates && !_.isUndefined(event.dates.planned)) event.dates.real = event.dates.planned;
        $scope.isFillForm = isFillForm;
        event.form = $scope.form;

        getSite(function() {
          event.site = _.get($scope.site, '_id');
          // if (isFillForm) event.form = $scope.form;
          if (event._id) {
              Event.update(event, function (ev) {
                  if (!ev) return;
                  Notif.success("Le suivi visite a bien été modifié");
                  if (isFillForm) $scope.event = setDate(ev);
                  $scope.cancel();
              }, Notif.error);
          }
          else {
            Event.save(event, function (ev) {
              if (ev && ev._id) {
                  Notif.success("Le suivi visite a bien été enregistré");
                  $scope.event = setDate(ev);
                  $scope.cancel();
              }
              else return;
            }, Notif.error);
          }
        })
    };
}]);

app.controller('ContractMultipleEditModalInstanceCtrl', ['$scope', '$uibModalInstance', '$state', 'Notif', 'Contract', 'Site', 'Network', 'Creche', 'Company', 'Application', 'User', 'Context', 'Role', 'AuthService', '_', 'Modal', function ($scope, $uibModalInstance, $state, Notif, Contract, Site, Network, Creche, Company, Application, User, Context, Role, AuthService, _, Modal) {
    $scope.context = Context;
    $scope.users = Context.users;
    $scope.mode = Context.mode;
    $scope.statuses = Context.scope.statuses;
    $scope.contracts = Context.contracts;
    $scope.getLastname = Context.scope.getLastname;
    $scope.activeState = Context.scope.activeState;
    $scope.getStatus = Context.scope.getStatus;

    $scope.rc = null;
    $scope.status = null;

    $scope.close = function () {
        $uibModalInstance.close();
    };

    $scope.removeContract = function (index) {
        $scope.contracts.splice(index, 1);
    };

    $scope.getRC = function (contract) {
        var id = _.get(contract, 'rc._id', contract.rc);
        var i = _.findIndex($scope.users, {_id: id});
        return (i === -1 ? '' : $scope.users[i].firstname[0].toUpperCase() + '. ' + _.capitalize($scope.users[i].lastname));
    };

    $scope.editContracts = function (rc, status) {
        Modal.confirm().then(function () {
            var nrc = null;
            if (!_.isNull(rc) && !_.isEmpty(rc)) {
                nrc = rc;
            }
            var nstatus = null;
            if (!_.isNull(status) && !_.isEmpty(status)) {
                nstatus = status;
            }
            async.forEach($scope.contracts, function (c) {
                if (!c.locked) {
                    c.rc = nrc || c.rc;
                    c.status = nstatus || c.status;
                    Context.scope.save(c);
                }
            });
        });
    };


}]);
