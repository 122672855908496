'use strict';

app.controller('RoleCtrl', ['$scope', '_', 'Modal', 'Role', 'RightCategory', 'ngNotify', function ($scope, _, Modal, Role, RightCategory, ngNotify) {
    // array of all roles with a slug for dom id ref and a label
    $scope.roles = [];

    // array of all rightCategories
    $scope.rightCategories = [];

    // array of all roles with matching rights array in it
    $scope.roleRights = [];
    $scope.newRole = {};

    /**
     * Method to recover all the roles from the database
     */
    $scope.initRole = function() {
        Role.query(function (roles) {
            $scope.roles = roles;

            roles.forEach(function(role) {
                role.rights.forEach(function(right) {
                    if (_.isUndefined($scope.roleRights[role._id])) {
                        $scope.roleRights[role._id] = {};
                    }
                    $scope.roleRights[role._id][right._id] = true;
                })
            })
        });
    };

    /**
     * Method called when the user creates a new role
     */
    $scope.addRole = function () {
        if (typeof $scope.newRole != 'undefined') {
            // TODO: should be selected via a select tag
            $scope.newRole.application = '58aab0527178d4d6bee57104';
            Role.save($scope.newRole, function () {
                ngNotify.set('Bravo !, Le rôle a bien été créé.', 'success');
                $scope.newRole = {};
                $scope.initRole();
            }, function () {
                ngNotify.set('Attention ! Une erreur est survenue.', 'error');
            });
        }
    };

    /**
     * Save the array of user roles
     */
    $scope.updateRole = function (role) {
        role.rights = [];

        for (var key in $scope.roleRights[role._id]) {
            if ($scope.roleRights[role._id][key]) {
                role.rights.push(key);
            }
        }

        Role.update(role, function () {
            ngNotify.set("Les droits du role " + role.label + " ont bien été modifié", 'success');
        }, function (err) {
            ngNotify.set('Attention ! Une erreur est survenue.', 'error');
        });
    };

    /**
     * Method used to delete a role on the database
     * @param role
     */
    $scope.deleteRole = function(role){
        Modal.confirm().then(function (result) {
            role.$delete(function () {
                $scope.initRole();
            });
        });
    };

    /**
     * Get all rights from DB
     */
    $scope.getRightCategories = function () {
        RightCategory.query(function (rightCategories) {
            $scope.rightCategories = rightCategories;
        });
    };

    $scope.getRightCategories();

    /**
     * Method used to link the name of the role and the link of the tab
     * @param str
     * @returns {string}
     */
    $scope.slugify = function (str) {
        var $slug = '';
        var trimmed = $.trim(str);
        $slug = trimmed.replace(/[^a-z0-9-]/gi, '-').replace(/-+/g, '-').replace(/^-|-$/g, '');
        return $slug.toLowerCase();
    };
}]);
