app.factory('User', ['$resource', function ($resource) {
    return $resource('/api/user/:_id', {_id: '@_id'},
        {
            get: {method: 'GET', isArray: false},
            query: {method: 'GET', isArray: true},
            count: {method: 'GET', isArray: false, url: '/api/user/count' },
            save: {method: 'POST'},
            update: {method: 'PUT'},
            delete: {method: 'DELETE'},
            distinct: {method: 'GET', isArray: true, url: '/api/user/distinct' }
        }
    )
}]);

app.factory('Role', ['$resource', function ($resource) {
    return $resource('/api/role/:_id', {_id: '@_id'},
        {
            get: {method: 'GET', isArray: false},
            save: {method: 'POST'},
            update: {method: 'PUT'},
            delete: {method: 'DELETE'}
        }
    )
}]);


app.factory('RightCategory', ['$resource', function ($resource) {
    return $resource('/api/right/category/:_id', {_id: '@_id'},
        {
            get: {method: 'GET', isArray: false}
        }
    )
}]);

app.factory('Right', ['$resource', function ($resource) {
    return $resource('/api/right/:_id', {_id: '@_id'},
        {
            getById: {method: 'GET', isArray: true, url: '/api/right/get'},
        }
    )
}]);

app.factory('Network', ['$resource', function ($resource) {
    return $resource('/api/network/:_id', {_id: '@_id'},
        {
            get: {method: 'GET', isArray: false},
            count: {method: 'GET', isArray: false, url: '/api/network/count' },
            save: {method: 'POST'},
            update: {method: 'PUT'},
            delete: {method: 'DELETE'}
        }
    )
}]);

app.factory('Contact', ['$resource', function ($resource) {
  return $resource('/api/contact/:_id', {_id: '@_id'},
      {
        query: {method: 'GET', isArray: true},
        save: {method: 'POST'},
        update: {method: 'PUT'},
        delete: {method: 'DELETE'},
        count: {method: 'GET', isArray: false, url: '/api/contact/count'},
        flags: {method: 'GET', isArray: true, url: '/api/contact/flags'}
      }
  );
}]);

app.factory('Creche', ['$resource', function ($resource) {
    return $resource('/api/creche/:_id', {_id: '@_id'},
        {
            get: {method: 'GET', isArray: false},
            count: {method: 'GET', isArray: false, url: '/api/creche/count' },
            save: {method: 'POST'},
            update: {method: 'PUT'},
            delete: {method: 'DELETE'},
            query: {method: 'GET', isArray: true}
        }
    )
}]);

app.factory('Family', ['$resource', function ($resource) {
    return $resource('/api/family/:_id', {_id: '@_id'},
        {
            get: {method: 'GET', isArray: false},
            getById: {method: 'GET', isArray: true, url: '/api/family/get'},
            count: {method: 'GET', isArray: false, url: '/api/family/count' },
            save: {method: 'POST'},
            update: {method: 'PUT'},
            delete: {method: 'DELETE'}
        }
    )
}]);

app.factory('FamilyStatus', ['$resource', function ($resource) {
    return $resource('/api/familyStatus/:_id', {_id: '@_id'},
        {
            get: {method: 'GET', isArray: false}
        }
    )
}]);

app.factory('CrecheStatus', ['$resource', function ($resource) {
    return $resource('/api/crecheStatus/:_id', {_id: '@_id'},
        {
            get: {method: 'GET', isArray: false}
        }
    )
}]);

app.factory('Sector', ['$resource', function ($resource) {
    return $resource('/api/sector/:_id', {_id: '@_id'},
        {
            get: {method: 'GET', isArray: false}
        }
    )
}]);

app.factory('Comment', ['$resource', function ($resource) {
    return $resource('/api/comment/:_id', {_id: '@_id'},
        {
            getByUserId: {method: 'GET', isArray: true, url: '/api/comment/get'},
            save: {method: 'POST'},
            update: {method: 'PUT'},
            delete: {method: 'DELETE'}
        }
    )
}]);

app.factory('Company', ['$resource', function ($resource) {
  return $resource('/api/company/:_id', {_id: '@_id'}, {
    get: {method: 'GET', isArray: false},
    count: {method: 'GET', isArray: false, url: '/api/company/count'},
    save: {method: 'POST'},
    update: {method: 'PUT'},
    delete: {method: 'DELETE'}
  });
}]);

app.factory('FamilyHistory', ['$resource', function ($resource) {
    return $resource('/api/familyHistory/:_id', {_id: '@_id'},
        {
            get: {method: 'GET', isArray: false},
            save: {method: 'POST'},
            update: {method: 'PUT'},
            delete: {method: 'DELETE'}
        }
    )
}]);

app.factory('Notification', ['$resource', function ($resource) {
    return $resource('/api/notification/:_id', {_id: '@_id'},
        {
            get: {method: 'GET', isArray: false},
            count: {method: 'GET', isArray: false, url: '/api/notification/count' },
            save: {method: 'POST'},
            update: {method: 'PUT'},
            delete: {method: 'DELETE'}
        }
    )
}]);

app.factory('Link', ['$resource', function ($resource) {
    return $resource('/api/link/:_id', {_id: '@_id'},
        {
            get: {method: 'GET', isArray: false},
            count: {method: 'GET', isArray: false, url: '/api/link/count' },
            key: {method: 'GET', isArray: false, url: '/api/link/key' },
            save: {method: 'POST'},
            update: {method: 'PUT'},
            delete: {method: 'DELETE'}
        }
    )
}]);

app.factory('LinkStatistic', ['$resource', function ($resource) {
    return $resource('/api/statistic/link', {},
        {
          count: {method: 'GET', isArray: false, url: '/api/statistic/link/count' },
        }
    )
}]);

app.factory('FamilyStatistic', ['$resource', function ($resource) {
    return $resource('/api/statistic/family', {},
        {
          spanco: {method: 'GET', isArray: false, url: '/api/statistic/family/portfolio' },
          priorities: {method: 'GET', isArray: true, url: '/api/statistic/family/priorities' },
        }
    )
}]);

app.factory('Contract', ['$resource', function ($resource) {
  return $resource('/api/contract/:_id', {_id: '@_id'}, {
    get: {method: 'GET', isArray: false},
    count: {method: 'GET', isArray: false, url: '/api/contract/count'},
    save: {method: 'POST'},
    update: {method: 'PUT'},
    delete: {method: 'DELETE'}
  });
}]);

app.factory('ContractStatus', ['$resource', function ($resource) {
  return $resource('/api/contractStatus/:_id', {_id: '@_id'}, {
    get: {method: 'GET', isArray: false}
  });
}]);

app.factory('ContractHistory', ['$resource', function ($resource) {
  return $resource('/api/contractHistory/:_id', {_id: '@_id'}, {
    query: {method: 'GET', isArray: true}
  });
}]);


app.factory('Application', ['$resource', function ($resource) {
  return $resource('/api/application/:_id', {_id: '@_id'}, {
    query: {method: 'GET', isArray: true },
    get: {method: 'GET', isArray: false },
    count: {method: 'GET', isArray: false, url: '/api/registration/count' },

  });
}]);

app.factory('Registration', ['$resource', function ($resource) {
	return $resource('/api/registration/:_id', { _id: '@_id' },
		{
			get: {method: 'GET', isArray: false},
			count: {method: 'GET', isArray: false, url: '/api/registration/count' },
			save: {method: 'POST'},
			update: {method: 'PUT'},
			delete: {method: 'DELETE'}
		});
}]);

app.factory('Site', ['$resource', function ($resource) {
	return $resource('/api/site/:_id', { _id: '@_id' },
		{
			get: {method: 'GET', isArray: false},
			save: {method: 'POST'},
			update: {method: 'PUT'},
			delete: {method: 'DELETE'}
		});
}]);

app.factory('Form', ['$resource', function ($resource) {
	return $resource('/api/form/:_id', { _id: '@_id' },
		{
			get: {method: 'GET', isArray: false},
			save: {method: 'POST'},
			update: {method: 'PUT'},
			delete: {method: 'DELETE'}
		});
}]);

app.factory('Question', ['$resource', function ($resource) {
	return $resource('/api/question/:_id', { _id: '@_id' },
		{
			get: {method: 'GET', isArray: false},
			save: {method: 'POST'},
			update: {method: 'PUT'},
			delete: {method: 'DELETE'}
		});
}]);

app.factory('Template', ['$resource', function ($resource) {
	return $resource('/api/template/:_id', { _id: '@_id' },
		{
			get: {method: 'GET', isArray: false},
			save: {method: 'POST'},
			update: {method: 'PUT'}
		});
}]);

app.factory('Email', ['$resource', function ($resource) {
	return $resource('/api/email/:_id', { _id: '@_id' },
		{
			get: {method: 'GET', isArray: false},
			save: {method: 'POST'},
			update: {method: 'PUT'}
		});
}]);

app.factory('Setting', ['$resource', function ($resource) {
	return $resource('/api/setting/:_id', { _id: '@_id' },
		{
			get: {method: 'GET', isArray: false},
			save: {method: 'POST'},
			update: {method: 'PUT'},
      delete: {method: 'DELETE'}
		});
}]);

app.factory('Event', ['$resource', function ($resource) {
	return $resource('/api/event/:_id', { _id: '@_id' },
		{
			get: {method: 'GET', isArray: false},
      count: {method: 'GET', isArray: false, url: '/api/event/count'},
			save: {method: 'POST'},
			update: {method: 'PUT'},
      delete: {method: 'DELETE'}
		});
}]);
