'use strict';

app.controller('ProfileCtrl', ['$scope', '_', 'User', 'Role', 'Modal', '$state', '$stateParams', 'ngNotify', '$q', '$filter', 'AuthService', function ($scope, _, User, Role, Modal, $state, $stateParams, ngNotify, $q, $filter, AuthService) {
    /* Init variables */
    $scope.user = {};
    $scope.search = {};
    $scope.params = {};
    $scope.options = {};
    $scope.users = [];
    $scope.roleList = [];
    $scope.foo = {};
    $scope.foo.newPassword = undefined;
    $scope.userLoading = false;
    $scope.isEdit = false;

    /* List variable */
    $scope.pagination = {};
    $scope.pagination.total = 0;
    $scope.pagination.limit = 20;
    $scope.pagination.currentPage = 1;

    /* Regex for form */
    $scope.numberRegex = /^[0-9, .-]+$/;
    $scope.emailRegex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,6}/i;

    /* Add and Edit method */

    /**
     * Method used to save a user on database
     * @param form
     * @param user
     */
    $scope.saveUser = function (user) {
        // If new password is set, link it to the current user
        if(!_.isUndefined($scope.foo.newPassword) && $scope.foo.newPassword != '')  {
            user.password = $scope.foo.newPassword;
        }
        User.update(user, function (data) {
            ngNotify.set("Votre profil a bien été modifié", 'success');
            $scope.auth.user = data;
            $scope.isEdit = false;
        }, function (err) {
            ngNotify.set('Attention ! Une erreur est survenue.', 'error');
        });
    };

    /* Profile method */

    /**
     * Init method when editing a user, called in ng-init of profile.html
     */
    $scope.initProfile = function () {
        $scope.auth = AuthService.getAuthenticatedUser();
    };
}]);
