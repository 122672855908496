'use strict'

app.controller('BillCtrl', ['$scope', '$q', '_', '$state', '$interval', '$stateParams', 'Notif', 'Contract', 'Application', 'Creche', 'Company', 'Network', 'Site', 'AuthService', '$window', function($scope, $q, _, $state, $interval, $stateParams, Notif, Contract, Application, Creche, Company, Network, Site, AuthService, $window) {
	/* Init variables */
	$scope.$state = $state;
	$scope.loader = false;
	$scope.contracts = [];
	$scope.applications = [];
	$scope.pagination = {
		total: 0,
		skip: 0,
		currentPage: 1,
		limit: 20
	};

	var billStatus = [
		'Attente réception',
		'Reçu',
		'Confirmé',
		'Refusé'
	];

	var billChunkSize = 50;
	var currentChunkIndex = 0;

  $scope.query = {
		contracts: [],
		creches: [],
		companies: [],
		applications: [],
		sites: []
	};

	$scope.params = {
		type: 'family',
		state: 'Facturation',
		creches: [],
		companies: [],
		applications: [],
		contracts: [],
		networks: [],
		notreceived: false
	};
	$scope.options = {
    sort: 'name',
    order: 'DESC'
  };

	var tmpContract = [];
	var now = new Date();

 	var formatDate = function (date) {
    var dates = [
      'planned.start', 'planned.end', 'real.start', 'real.end',
      'cancelled', 'terminated', 'bdc', 'signed',
      'effective.start', 'effective.end', 'priceAnticipated',
      'free.start', 'free.end', 'call', 'interest'
    ];
    _.each(dates, function (d) {
      var dt = _.get(date, d);
      if (dt) _.set(date, d, new Date(dt));
    });
  };

	$scope.canAffectContract = function () {
    var user = AuthService.getAuthenticatedUser();
    return _.indexOf(user.rights, 'BILL_EDIT') !== -1;
  };

	$scope.billClass = function (bill, month, div) {
		var year = $scope.year + !!(month < 8);
		if (!bill || !bill[year] || !bill[year][month]) return '';
		else if (bill && bill[year][month] === billStatus[0]) return (div === 'i') ? 'fa fa-clock-o fa-lg' : 'warning';
		else if (bill && bill[year][month] === billStatus[1]) return (div === 'i') ? 'fa fa-inbox fa-lg' : 'info';
		else if (bill && bill[year][month] === billStatus[2]) return (div === 'i') ? 'fa fa-check fa-lg' : 'success';
		else if (bill && bill[year][month] === billStatus[3]) return (div === 'i') ? 'fa fa-close fa-lg': 'danger';
		return '';
	};

	$scope.setBillStatus = function (bill, month) {
		var year = $scope.year + !!(month < 8);
		if (!bill || !bill[year] || !bill[year][month]) return;
		if (bill[year][month] !== billStatus[3]) bill[year][month] = billStatus[_.indexOf(billStatus, bill[year][month]) + 1];
		else bill[year][month] = billStatus[0];
	};

	$scope.updateBill = function (contract) {
		Contract.update(_.omit(contract, 'populated'), function (doc) {
			Notif.success('La facture a bien été mise à jour');
		}, Notif.error);
	};

	$scope.changeYear = function (inc) {
		$scope.year += inc;
	};

	$scope.filterList = function() {
		_.each(['contracts', 'creches', 'companies', 'applications','sites', 'networks'], function (key) {
      $scope.params[key] = _.map($scope.query[key], '_id');
		});
		if ($scope.params['notreceived']) $scope.params['year'] = $scope.year;
		else delete $scope.params['year'];

		buildStateParams();
		changeList();
	};

	$scope.exportList = function(format) {
    // Set options for query
    var options = _.clone($scope.options);

    //Proper format params
    var params = _.clone($scope.params);

		if (params['notreceived']) params['year'] = $scope.year;
		else delete params['year'];

    var options = encodeURIComponent(JSON.stringify(options));
    params = encodeURIComponent(JSON.stringify(params));

    $window.open('/api/contract/export.' + format + '?params=' + params + '&options=' + options, '_blank');
	};

	var changeList = function () {
    currentChunkIndex = 0;

		loadApplications();

		if ($scope.params['notreceived']) $scope.params['year'] = $scope.year;
		else delete $scope.params['year'];
		Contract.query({params: $scope.params, options: $scope.options }, function (contracts) {
			_.each(contracts, function (c) {
				formatDate(c.date);
				c.application = (c.application) ? _.find($scope.applications, { '_id': c.application }) : { 'name': 'Icare'};
				if(_.has(c, ['family', 'application'])) _.set(c, ['family', 'application'], _.find($scope.applications, { _id: c.family.application }))
      });
      // if ($scope.contracts.length > contracts.length) $scope.contracts = _.slice($scope.contracts, 0, contracts.length);
      addContracts(contracts);
			// $scope.contracts = contracts;
		}, Notif.error);
	};

	var addContracts = function (contracts) {
    $scope.contracts = [];
    var limit = Math.trunc(contracts.length/billChunkSize + 1);
		$interval(function() {
			var start = currentChunkIndex * billChunkSize;
      var end = (currentChunkIndex + 1) * billChunkSize;
      for (var i = start; i < end; ++i) {
        if (!contracts[i]) break;
        $scope.contracts.push(contracts[i]);
      }
      ++currentChunkIndex;
    }, 0, limit);
    // $interval.cancel();
	};

	var buildStateParams = function () {
    var params = {};
    _.each(['creches', 'companies', 'applications','contracts', 'networks'], function (key) {
      params[key] = _.get($scope.params, key, []).join(';');
    });

		params['year'] = $scope.year;
		params['notreceived'] = $scope.params.notreceived;

    var state = 'bill';
    $state.go(state, params, {
      reload: false,
      notify: false,
      location: 'replace',
      inherit: true
    });
	};

	var loadStateParams = function () {
    _.each(['creches', 'companies', 'applications', 'contracts', 'sites', 'networks'], function (key) {
      if ($stateParams[key]) $scope.params[key] = $stateParams[key].split(';');
      else $scope.params[key] = [];
    });
		$scope.params['notreceived'] = _.get($stateParams, 'notreceived') == 'true';
		$scope.year = _.get($stateParams, 'year', now.getFullYear());
		$scope.year = parseInt($scope.year);

    async.parallel([
      function (cb) {
        if (!$scope.params.creches.length) return cb();
        return Creche.query({params: {ids: $scope.params.creches}}, function (creches) {
          $scope.query.creches = creches;
          cb();
        }, cb);
      },
      function (cb) {
        if (!$scope.params.contracts.length) return cb();
        return Contract.query({params: {ids: $scope.params.contracts}}, function (contracts) {
          $scope.query.contracts = contracts;
          cb();
        }, cb);
      },
      function (cb) {
        if (!$scope.params.companies.length) return cb();
        return Company.query({params: {ids: $scope.params.companies}}, function (companies) {
          setDisplayCompany(companies);
          $scope.query.companies = companies;
          cb();
        }, cb);
      },
      function (cb) {
        if (!$scope.params.applications.length) return cb();
        var applications = [];
        _.forEach($scope.params.applications, function(appId) {
          var app = _.find($scope.applications, function(app) {
            return app._id.toString() === appId;
          })
          if(app) applications.push(app);
        })
        setDisplayApplication(applications);
        $scope.query.applications = applications;
        return cb();
      },
      function  (cb) {
        if (!$scope.params.sites.length) return cb();
        return Site.query({params: {ids: $scope.params.sites}}, function (sites) {
          setDisplaySite(sites);
          $scope.query.sites = sites;
          cb();
        }, cb);
      },
      function  (cb) {
        if (!$scope.params.networks.length) return cb();
        return Network.query({params: {ids: $scope.params.networks}}, function (networks) {
          setDisplayNetwork(networks);
          $scope.query.networks = networks;
          cb();
        }, cb);
      },
    ], Notif.error);
  };

	var makeSearchFun = function (Model, params, func) {
    return function (query) {
      var deferred = $q.defer();
      Model.query({params: params, search: {text: query}, options: {limit: 20, sort: '_name', order: 'ASC'}}, function (docs) {
        if (func) func(docs);
        deferred.resolve(docs);
      }, Notif.error);
      return deferred.promise;
    };
	};

  var setDisplayApplication = function (applications) {
    _.each(applications, function (application) {
      application['display'] = application.name;
    });
  };

  var setDisplayCompany = function (companies) {
    _.each(companies, function (company) {
      company['display'] = company.name || company.denomination;
    });
  };

  var setDisplaySite = function(sites) {
    _.each(sites, function(site) {
      site['display'] = site.name;
    });
  }

  var setDisplayNetwork = function (networks) {
    _.each(networks, function (network) {
      network['display'] = network._name;
    });
  };

	var loadApplications = function(cb) {
    Application.query({}, function (applications) {
      $scope.applications = applications;
      if (cb) return cb();
      return null;
    });
  };

	$scope.searchContracts = makeSearchFun(Contract, {type: 'company'});
  $scope.searchCreches = makeSearchFun(Creche, {});
  $scope.searchCompanies = makeSearchFun(Company, {}, setDisplayCompany);
  $scope.searchSites = makeSearchFun(Site, {}, setDisplaySite);
  $scope.searchNetworks = makeSearchFun(Network, {}, setDisplayNetwork);
  $scope.searchApplications = makeSearchFun(Application, {}, setDisplayApplication);

	function init() {
		loadApplications(function() {
			loadStateParams();
			//$scope.year = now.getFullYear();
			changeList();
		});
	};

	init();
}]);
