'use strict';
  
app.controller('CompanyCtrl', ['$scope', '_', 'Modal', '$state', '$stateParams', 'Notif', 'Company', 'CompanyEntity', '$http', '$sce', function ($scope, _, Modal, $state, $stateParams, Notif, Company, CompanyEntity, $http, $sce) {
  /* Init variables */
  $scope.loader = false;
  $scope.isEdit = $scope.$parent.isEdit;
  $scope.company = CompanyEntity;
  $scope.query = '';
  $scope.aside = false;
  $scope.iframeUrl = []

  $scope.cancel = function() {
    $state.go('company-detail.profil', {companyId: $scope.company._id}, {reload: true});
  };
  
  $scope.searchSociety= function(query) {
    if(_.isEmpty(query)) return
    $scope.aside = true;  
    $scope.iframeUrl.length = 0;
    $scope.iframeUrl.push($sce.trustAsResourceUrl('https://www.societe.com/cgi-bin/search?champs='+encodeURIComponent(query)));
  };

  $scope.removeIframe = function() {
    $scope.aside = false;
    $scope.query = '';
  };

  var searchDb = function (cb) {
    var query = $scope.query;
    query = query.replace(/ /g, '');
    return Company.query({params: {siren: query}, options: {limit: 1}}, function (cs) {
      if (cs) cs = cs[0];
      cb(null, cs);
    }, cb);
  };

  $scope.firmApi = function (c, query, cb) {
    if(!query) {
      if(cb) return cb();
      else return;
    }
    query = query.replace(/[ .]/g, '');
    return $http({method: 'GET', url: 'https://firmapi.com/api/v1/companies/' + query})
      .then(function (res) {
        var t = res.data.company;
        c = {
          _id: _.get(c, '_id'),
          name: t.names.commercial_name,
          denomination: t.names.denomination,
          activity: t.activity,
          address: {
            street: t.address,
            city: t.city,
            zipCode: t.postal_code
          },
          siren: t.siren
        };
        if (cb) cb(null, c);
        else $scope.company = c;
      }).catch(cb || Notif.error);

  };

  var searchSiren = function (c, cb) {
    if (c) return cb(null, c);
    if (!$scope.query) return null;
    return $scope.firmApi(c, $scope.query, cb);
  };

  $scope.search = function () {    
    async.waterfall([
      searchDb,
      searchSiren
    ], function (err, c) {
      if (err) return Notif.error(err);
      $scope.company = c;
    });
  };

  $scope.saveCompany = function (form, company) {
    if (form.$valid && company) {
      if (company._id) {
        Company.update(company, function (data) {
          Notif.success("L'entreprise a bien été modifié !");
          $scope.$parent.editMode = 0;
          $state.go('company-detail.profil', {companyId: data._id});
        }, Notif.error);
      } else {
        Company.save(company, function (data) {
          Notif.success("L'entreprise a bien été crée !");
          $scope.$parent.editMode = 0;
          $state.go('company-detail.edit', {companyId: data._id});
        }, Notif.error);
      }
    }
  };

}]);

app.controller('ContractListCtrl', ['$scope', '_', '$state', '$stateParams', '$uibModal', 'Modal', 'Notif', 'Family', 'Creche', 'Contract', 'Application', 'AuthService', function ($scope, _, $state, $stateParams, $uibModal, Modal, Notif, Family, Creche, Contract, Application, AuthService) {
  $scope.icare;
  $scope.loader = false;
  $scope.editMode = $scope.$parent.editMode;
  $scope.contracts = [];
  $scope.params = {
    company: $stateParams.companyId,
    type: 'company'
  };
  $scope.search = {};
  $scope.options = {
    sort: 'created',
    order: 'DESC'
  };
  $scope.pagination = {
    total: 0,
    skip: 0,
    currentPage: 1,
    limit: 20
  };
  $scope.applications = [];

  var openModal = function (mode, contract) {
    var options = {
      animation: false,
      backdrop: 'static',
      keyboard: true,
      size: 'md',
      templateUrl: '/partials/ui/contractCompany.html',
      resolve: {
        Context: function () {
          return {
            contract: contract,
            mode: mode,
            scope: $scope,
            edit: $scope.editMode,
            type: 'company',
            nbContracts: _.get(contract, 'nbSubContracts', {count: 0}),
            companyId: $scope.company._id
          };
        }
      },
      controller: 'ContractModalInstanceCtrl'
    };
    return $uibModal.open(options);
  };

  var loadApplications = function(cb) {
    Application.query({}, function (applications) {
      $scope.applications = applications;
      $scope.defaultApp = _.find(applications, { 'name': 'Icare' })._id;
      if (cb) return cb();
      return null;
    });
  };

  $scope.update = function (contract) {
    Contract.update(contract, function () {
      Notif.success('Le contrat a bien été modifié !');
    }, Notif.error);
  };

  var getCount = function (cb) {
    Contract.count({params: $scope.params}, function (data) {
      if (!data) return cb();
      resetPaging(data.count);
      return cb();
    }, Notif.error);
  };

  var resetPaging = function (total) {
    $scope.pagination.total = total;
    $scope.pagination.skip = 0;
    $scope.pagination.currentPage = 1;
  };

  $scope.addContract = function () {
    var m = openModal('add');
    m.result.then($scope.initContracts);
  };

  $scope.editContract = function (contract) {
    var m = openModal('edit', contract);
    m.result.then($scope.initContracts);
  };

  $scope.viewContract = function (contract) {
    var m = openModal('view', contract);
    m.result.then($scope.initContracts);
  };

  var loadNbContract = function (contract) {
    Contract.count({ params: { parent: contract._id }}, function (data) {
      contract.nbSubContracts = data;
    }, Notif.error);
  };

  $scope.changeList = function () {
    var options = _.clone($scope.options);
    options.skip = ($scope.pagination.currentPage - 1) * $scope.pagination.limit;
    $scope.pagination.skip = options.skip;
    options.limit = $scope.pagination.limit;

    Contract.query({params: $scope.params, options: options}, function (contracts) {
      $scope.contracts = contracts;
      _.each(contracts, function(contract){
        loadNbContract(contract);
        //contract.application = _.find($scope.applications, { '_id': contract.application });
      });
    }, Notif.error);
  };

  $scope.canAffectContract = function (contract) {
    var user = AuthService.getAuthenticatedUser();
    return _.indexOf(user.rights, 'CONTRACT_EDIT') !== -1;
  };

  $scope.initContracts =  function () {
    if (!$stateParams.companyId) return;
    $scope.loader = true;
    loadApplications(function (){
      $scope.icare = _.find($scope.applications, { name: 'Icare' });
      if($scope.icare) _.set($scope, ['contract', 'application'], $scope.icare._id);
      getCount(function () {
        $scope.changeList();
        $scope.loader = false;
      });
    });
  };

  $scope.deleteContract = function (contract) {
    Modal.confirm().then(function (result) {
      Contract.delete(contract, function () {
        Notif.success('Le contrat a bien été supprimé !');
        $scope.initContracts();
      }, Notif.error);
    });
  }

  $scope.validateContract = function (contract) {
    var options = {
      animation: false,
      backdrop: true,
      keyboard: true,
      size: 'md',
      templateUrl: '/views/company/contractValidationModal.html',
      resolve: {
        Context: function () {
          return {
            contract: contract,
            reloadContracts: $scope.initContracts
          }
        }
      },
      controller: 'ContractValidationModalInstanceCtrl'
    };
    return $uibModal.open(options);
  }
}]);

app.controller('FamilyListCtrl', ['$scope', '_', '$state', '$stateParams', 'Notif', 'Family', 'FamilyStatus', 'CompanyEntity', 'Application', function ($scope, _, $state, $stateParams, Notif, Family, FamilyStatus, CompanyEntity, Application) {
  $scope.loader = false;
  $scope.isEdit = false;
  $scope.families = [];
  $scope.statuses = [];
  $scope.users = [];

  $scope.pagination = {
    total: 0,
    skip: 0,
    currentPage: 1,
    limit: 20
  };

  $scope.params = {};
  $scope.search = {};
  $scope.options = {
    sort: '_name',
    order: 'ASC'
  };

  var loadStatuses = function () {
    FamilyStatus.query({}, function (data) {
      $scope.statuses = data;
    });
  };

  var loadApplications = function(cb) {
    Application.query({}, function (applications) {
      $scope.applications = applications;
      if (cb) return cb();
      return null;
    });
  };

  $scope.getStatus = function (statusId) {
    var id = _.findIndex($scope.statuses, {_id: statusId});
    return (id === -1 ? '' : $scope.statuses[id].label);
  };

  $scope.getCount = function (cb) {
    Family.count({params: $scope.params}, function (data) {
      if (!data) return cb();
      $scope.resetPaging(data.count);
      return cb();
    }, Notif.error);
  };

  $scope.initFamilies = function () {
    $scope.params['parents.company.reference'] = CompanyEntity._id;
    $scope.loader = true;
    $scope.getCount(function () {
      loadApplications(function() {
        loadStatuses();
        $scope.changeList();
        $scope.loader = false;
      })
    });
  };

  $scope.initFamilies();

  $scope.changeList = function () {
    if (!CompanyEntity._id) return ;
    var options = _.clone($scope.options);
    options.skip = ($scope.pagination.currentPage - 1) * $scope.pagination.limit;
    $scope.pagination.skip = options.skip;
    options.limit = $scope.pagination.limit;
    options.populate = true;

    Family.query({params: $scope.params, options: options}, function (families) {
      _.forEach(families, function(family) {
        family.application = _.find($scope.applications, { _id: family.application });
      })
      $scope.families = families;
    }, Notif.error);
  };

  $scope.resetPaging = function (total) {
    $scope.pagination.total = total;
    $scope.pagination.skip = 0;
    $scope.pagination.currentPage = 1;
  };
}]);
