'use strict';

app.controller('PamCtrl', ['$scope', '_', 'Creche', 'CrecheStatus', '$stateParams', function ($scope, _, Creche, CrecheStatus, $stateParams) {
    /* Init variables */
    $scope.loader = false;
    $scope.queryEnd = false;
    $scope.chunk = "00";
    $scope.mapLoaded = false;
    $scope.total = NaN;

    $scope.defaultAddress = null;
    if ($stateParams.address) {
        $scope.defaultAddress = $stateParams.address;
    }

    $scope.familyId = null;
    if ($stateParams.familyId) {
        $scope.familyId = $stateParams.familyId;
    }

    $scope.networkId = null;
    if ($stateParams.networkId) {
        $scope.networkId = $stateParams.networkId;
    }

    $scope.crecheId = null;
    if ($stateParams.crecheId) {
        $scope.crecheId = $stateParams.crecheId.split(';');
    }


    // Company variable for company's direction

    $scope.addressCompany = null;
    if ($stateParams.addressCompany) {
      $scope.addressCompany = $stateParams.addressCompany;
    }

    /**
     * Init method for the pam page
     */
    $scope.initPam = function () {
        //Display the loader
        $scope.creches = [];
        $scope.loader = false;
        $scope.mapLoaded = true;

        var limit = 200;
        var skip = 0;

        // Get the list of creches from the DB
        function queryChunk(skip, cb, arg1, arg2) {
            var params = {};
            if($scope.crecheId) params.ids = $scope.crecheId;
            return Creche.query({params: params, search: {}, options: {populate: true, limit: limit, skip: skip}}, function (data) {
                $scope.creches = _.unionBy($scope.creches, data, function (e) {
                    return e._id;
                });
               cb(arg1, arg2);
            });
        }
        function nextChunk(size, p) {
            var nextP = p.then(function () {
                skip += limit;
                $scope.chunk = Math.min(Math.round(skip / size * 100), 100);
                if ($scope.chunk < 10) $scope.chunk = "0" + $scope.chunk;
                if (skip <= size)
                    queryChunk(skip, nextChunk, size, nextP);
                else
                    $scope.queryEnd = true;
            })
        }
        Creche.count(function (size) {
            $scope.total = size.count;
            var p = queryChunk(skip, function () {});
            nextChunk(size.count, p.$promise);
        });
    };

    $scope.initPam();
}]);
