// var modalController =
app.directive("comment", ['Comment', 'AuthService', 'Notif', '$uibModal', function (Comment, AuthService, Notif, $uibModal) {
  return ({
    restrict: 'E',
    scope: {
      type: '=',
      ref: '=',
      editable: '=',
      modal: '='
    },
    templateUrl: '/js/directives/templates/comment.html',
    link: function link($scope) {
      var resetComment = function (comment) {
        return {
          type: $scope.type,
          _id: _.get(comment, '_id'),
          content: _.get(comment, 'content', ''),
          user: $scope.user,
          application: '58ad59fabda3fdb4efba6d13',
          reference: $scope.ref._id
        };
      };

      var updateComment = function (comment) {
        if (_.findIndex($scope.context.comments, {_id: comment._id}) !== -1) {
          return Comment.update(comment, function () {
            Notif.success('Le commentaire a bien été modifié !');
          });
        } else {
          delete comment._id;
          return saveComment(comment);
        }
      };

      var saveComment = function (comment) {
        return Comment.save(comment, function () {
          Notif.success('Le commentaire a bien été enregistré !');
        });
      };

      var initComments = function () {
        Comment.getByUserId({userId: $scope.ref._id}, function (comments) {
          $scope.context.comments = comments;
        });
      };

      var editComment = function (comment) {
        $scope.context.isEditing = true;
        $scope.context.comment = resetComment(comment);
      };
      $scope.editComment = editComment;

      var deleteComment = function (comment) {
        Comment.delete(comment, function () {
          initComments();
          Notif.success('Le commentaire a bien été supprimé !');
        }, Notif.error);
      };
      $scope.deleteComment = deleteComment;

      var addComment = function (context) {
        if (!context.comment.content) return ;
        var p;
        if (context.comment._id) p = updateComment(context.comment);
        else p = saveComment(context.comment);
        p.$promise.then(function () {
          initComments();
          $scope.context.isEditing = false;
          $scope.context.comment = resetComment();
        }).catch(Notif.error);
      };
      $scope.addComment = addComment;

      var cancelEdit = function () {
        $scope.context.isEditing = false;
        $scope.context.comment = resetComment();
      };
      $scope.cancel = cancelEdit;

      $scope.openModal = function () {
        var options = {
          animation: false,
          backdrop: true,
          keyboard: true,
          size: 'md',
          templateUrl: '/js/directives/templates/commentModal.html',
          resolve: {
            Context: function () {
              return $scope.context;
            }
          },
          controller: ['$scope', '$uibModalInstance', 'Context', function ($scope, $uibModalInstance, Context) {
            $scope.context = Context;
            $scope.addComment = addComment;
            $scope.editComment = editComment;
            $scope.deleteComment = deleteComment;
            $scope.cancel = function () {
              if (Context.isEditing) return cancelEdit();
              else return $uibModalInstance.dismiss('cancel');
            };
          }]
        };
        $uibModal.open(options).closed.then(cancelEdit);
      };

      $scope.user = AuthService.getAuthenticatedUser().user._id;
      $scope.context = {
        comment: resetComment(),
        comments: [],
        user: $scope.user,
        editable: $scope.editable,
        isEditing: false
      };

      initComments();
    }
  });
}]);
