'use strict'

app.controller('NetworksCtrl', ['$scope', '$window', '_', 'Modal', 'Network', 'Creche', 'Contact', '$state', '$stateParams', 'Notif', function ($scope, $window, _, Modal, Network, Creche, Contact, $state, $stateParams, Notif) {
  $scope.networks = []

  $scope.pagination = {
    total: 0,
    skip: 0,
    currentPage: 1,
    limit: 20
  }

  $scope.params = {}
  $scope.search = {}
  $scope.options = {
    sort: '_name',
    orderBy: 1
  }

  $scope.networks = []
  $scope.creches = []
  $scope.network = null
  $scope.networkMainContact = null
  $scope.contact = {}
  $scope.contacts = []

  $scope.loader = false
  $scope.isEditingContact = -1

  /* List page Method */

  /**
   * Init method to get all networks from the database
   */
  $scope.initNetworks = function () {
    // Display loader
    $scope.loader = true

    // Get data source count
    $scope.getCount()

    // Get data
    setTimeout(function () {
      $scope.changeList()
      $scope.loader = false
    }, 500)
  }

  function getContact (ref, cb) {
    Contact.query({ params: { reference: ref._id } }, function (contacts) {
      var i = _.findIndex(contacts, { main: true })
      ref.contact = (i === -1 ? contacts[0] : contacts[i])
      if (cb) cb()
    }, function (err) {
      if (cb) cb(err)
    })
  }


  /**
   * Reset pagging method
   * @param total
   */
  $scope.resetPaging = function (total) {
    $scope.pagination.total = total
    $scope.pagination.offset = 0
    $scope.pagination.currentPage = 1
  }

  /**
   * Method called when the user changes the pagination
   */
  $scope.changeList = function () {
    // Set options for query
    var options = _.clone($scope.options)
    options.skip = ($scope.pagination.currentPage - 1) * $scope.pagination.limit
    options.limit = $scope.pagination.limit
    options.populate = true

    var search = _.clone($scope.search)

    // Perform the query
    Network.query({ params: $scope.params, search: search, options: options },
      function (data) {
        $scope.networks = data
        var ids = []
        angular.forEach($scope.networks, function (value, key) {
          getContact(value)
          value.creches = 0
          ids.push(value._id)
        })
        // Get creches
        Creche.query({
          params: { network: ids }, search: {}, options: { populate: true }
        }, function (creches) {
          _.each(creches, function (c) {
            var index = _.findIndex($scope.networks, function (n) {
              return n._id === c.network._id
            })
            $scope.networks[index].creches += 1
          })
        })
      })
  }

  /**
   * Return count of the datasource based on params
   */
  $scope.getCount = function () {
    // Perform the query
    Network.count({ params: $scope.params, search: $scope.search },
      function (data) {
        $scope.resetPaging(data.count)
      })
  }
  /**
   * Filter list based on search and params
   */
  $scope.filterList = function () {
    // Display loader dot the list
    $scope.loader = true

    // Get count
    $scope.getCount()

    // Get data
    setTimeout(function () {
      $scope.changeList()
      $scope.loader = false
    }, 500)
  }


  /**
   * Sort list by key
   * @param key
   */
  $scope.sortList = function (key) {
    $scope.loader = true
    $scope.options.sort = key
    $scope.options.order = $scope.options.order == 'DESC' ? 'ASC' : 'DESC'

    //Get data
    setTimeout(function () {
      $scope.changeList()
      $scope.loader = false
    }, 500)
  }

  /**
   * Load creches of a given network
   * @param network
   */
  $scope.loadCreches = function (network) {
    var networkId = network._id
    $state.go('network.profil', { 'networkId': networkId, '#': 'creche' })
  }

  /**
   * Load the PAM of creches with selected network
   * @param network
   */
  $scope.locate = function (network) {
    $state.go('pam', { 'networkId': network._id })
  }

  /**
   * Delete Network
   * @param network
   */
  $scope.delete = function (network) {
    Modal.confirm().then(function (result) {
      Network.delete(network, function () {
        Notif.success('Le réseau a bien été supprimé !')
        $scope.initNetworks()
      }, Notif.error)
    })
  }

  $scope.exportList = function (format) {
    // Set options for query
    var options = _.clone($scope.options)

    var options = encodeURIComponent(JSON.stringify(options))
    var params = encodeURIComponent(JSON.stringify($scope.params))
    var search = encodeURIComponent(JSON.stringify($scope.search))

    $window.open('/api/network/export.' + format + '?params=' + params + '&search=' + search + '&options=' + options, '_blank')
  }


}])

app.controller('NetworkCtrl', ['$scope', '_', 'Modal', 'Network', 'Creche', 'Contact', '$state', '$stateParams', 'Notif', function ($scope, _, Modal, Network, Creche, Contact, $state, $stateParams, Notif) {
  /* Init variables */
  $scope.search = {}
  $scope.params = {}
  $scope.options = {}
  $scope.creches = []
  $scope.popups = []

  $scope.isEdit = $stateParams.networkId !== null

  $scope.network = null

  $scope.networkMainContact = null
  $scope.contact = {}
  $scope.contacts = []

  $scope.loader = false
  $scope.options.sort = '_name'
  $scope.options.order = 'ASC'

  $scope.isEditingContact = -1

  /* List variable */
  $scope.pagination = {}
  $scope.pagination.total = 0
  $scope.pagination.limit = 20
  $scope.pagination.currentPage = 1

  /* Regex for form */
  $scope.numberRegex = /^[0-9, .-]+$/
  $scope.emailRegex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,6}/i

  /* List page Method */

  $scope.changeEditMode = function () {
    $scope.editMode = !$scope.editMode
    if ($scope.editMode)
      $state.go('network-detail.edit')
    else if (!$scope.editMode)
      $state.go('network-detail.profil')
  }

  function getContact (ref, cb) {
    console.log('getContact')
    Contact.query({ params: { reference: ref._id } }, function (contacts) {
      var i = _.findIndex(contacts, { main: true })
      ref.contact = (i === -1 ? contacts[0] : contacts[i])
      if (cb) cb()
    }, function (err) {
      if (cb) cb(err)
    })
  }


  /**
   * Reset pagging method
   * @param total
   */
  $scope.resetPaging = function (total) {
    $scope.pagination.total = total
    $scope.pagination.offset = 0
    $scope.pagination.currentPage = 1
  }

  $scope.open = function (idx) {
    _.set($scope, 'popups[' + idx + '].opened', true)
  }

  $scope.formatDate = function (network) {
    var dates = [
      'interestDate'
    ]
    _.each(dates, function (d) {
      var dt = _.get(network, d)
      if (dt) _.set(network, d, new Date(dt))
    })
  }

  /**
   * Load creches of a given network
   * @param network
   */
  $scope.loadCreches = function (network) {
    var networkId = network._id
    $state.go('network.profil', { 'networkId': networkId, '#': 'creche' })
  }

  /**
   * Load the PAM of creches with selected network
   * @param network
   */
  $scope.locate = function (network) {
    $state.go('pam', { 'networkId': network._id })
  }

  /* Add and edit Method */

  /**
   * Get the current network from db
   */
  $scope.initNetwork = function () {
    // Get the current network
    if (!_.isUndefined($stateParams.networkId)) {
      Network.get({ _id: $stateParams.networkId }, function (data) {
        $scope.network = data
        $scope.formatDate(data)
        getContact($scope.network)

        // Set isEdit flag
        $scope.isEdit = true

        // Creche.query({params: {network: $stateParams.networkId}, search: {}, options: {populate: true}}, function (data) {
        //   $scope.creches = data;
        // });
      })
    } else {
      $scope.network = {
        contacts: []
      }
    }

  }

  /**
   * Method used to save a user on database
   * @param form
   * @param network
   */
  $scope.saveNetwork = function (form, network) {
    if (form.$valid) {

      // Difference between create and update
      if (!_.isUndefined(network._id)) {
        Network.update(network, function (data) {
          Notif.success('Le réseau a bien été modifié !')
        }, Notif.error)
      } else {
        Network.save(network, function (data) {
          $state.go('network-detail.edit', { networkId: data._id })
          Notif.success('Le réseau a bien été créé !')
        }, Notif.error)
      }
    }
  }

  $scope.updateNetworkContact = function (network) {
    return function (contact) {
      network.contacts = _.map($scope.contacts, '_id')
      network.contacts.push(contact._id)
      Network.update(network, function () {
        Notif.success('Le réseau a bien été modifié !')
        $scope.isEditingContact = -1
        $scope.contact = {}
        $scope.initNetwork()
      })
    }
  }

  $scope.saveContact = function (form, network) {
    if (form.$valid) {
      var up = function () {
        if ($scope.isEditingContact < 0) {
          var contact = _.omit($scope.contact, '_id')
          Contact.save(contact, $scope.updateNetworkContact(network), Notif.error)
        } else {
          Contact.update($scope.contact, $scope.updateNetworkContact(network), Notif.error)
        }
      }
      if ($scope.networkMainContact && $scope.contact.main === true && $scope.isEditingContact !== $scope.networkMainContact) {
        $scope.networkMainContact.main = false
        Contact.update($scope.networkMainContact, up, Notif.error)
      } else {
        up()
      }
    }
  }

  /**
   * Delete Network
   * @param network
   */
  $scope.delete = function (network) {
    Modal.confirm().then(function (result) {
      Network.delete(network, function () {
        Notif.success('Le réseau a bien été supprimé !')
        $scope.initNetworks()
      }, Notif.error)
    })
  }

  $scope.deleteContact = function (network, index) {
    Modal.confirm().then(function (result) {
      Contact.delete(network.contacts[index], function () {
        network.contacts.splice(index, 1)
        Network.update(network, function () {
          Notif.success('Le contact a bien été Supprimer !')
          if ($scope.isEditingContact === index)
            $scope.isEditingContact = -1
        }, function (err) {
          Notif.error(err)
          $scope.initNetwork()
        })
      }, Notif.error)
    })
  }

  $scope.cancelContact = function () {
    $scope.contact = {}
    $scope.isEditingContact = -1
  }

  $scope.editContact = function (network, index) {
    $scope.contact = _.clone($scope.contacts[index])
    $scope.isEditingContact = index
  }

  /**
   * Delete Creche
   * @param creche
   */
  $scope.deleteCreche = function (creche) {
    Modal.confirm().then(function (result) {
      Creche.delete(creche, function () {
        Notif.success('La crèche a bien été supprimé !')
        $scope.initNetwork()
      }, Notif.error)
    })
  }
}])

app.controller('NetworkRootCtrl', ['$scope', '$state', '$stateParams', 'Network', 'Notif', function ($scope, $state, $stateParams, Network, Notif) {
  $scope.editMode = ($state.current.name == 'network-detail.edit')
  $scope.isEdit = $stateParams.networkId !== null

  $scope.network = Network.get({ _id: $stateParams.networkId }, function (data) {
    return data
  }, Notif.error)

  $scope.changeEditMode = function () {
    $scope.editMode = !$scope.editMode
    if ($scope.editMode)
      $state.go('network-detail.edit')
    else if (!$scope.editMode)
      $state.go('network-detail.profil')
  }
}])

app.controller('CrechesListCtrl', ['$scope', '_', 'Modal', 'Network', 'Creche', 'CrecheStatus', 'Contact', '$state', '$stateParams', 'Notif', function ($scope, _, Modal, Network, Creche, CrecheStatus, Contact, $state, $stateParams, Notif) {
  $scope.loader = false
  $scope.creches = []
  $scope.statuses = []

  $scope.pagination = {
    total: 0,
    skip: 0,
    currentPage: 1,
    limit: 20
  }

  var loadStatuses = function () {
    CrecheStatus.query({}, function (data) {
      data = _.orderBy(data, ['group'], 'ASC')
      data = _.groupBy(data, 'group')
      $scope.statuses = data
    })
  }

  $scope.params = {}
  $scope.search = {}
  $scope.options = {
    sort: '_name',
    orderBy: 1
  }

  $scope.getStatus = function (statusId) {
    var id = _.findIndex($scope.statuses, { _id: statusId })
    if (id !== -1) return $scope.statuses[id].label
    return ''
  }

  $scope.getCount = function (cb) {
    Creche.count({ params: $scope.params }, function (data) {
      if (!data) return cb()
      $scope.resetPaging(data.count)
      return cb()
    }, Notif.error)
  }


  $scope.initCreches = function () {
    Network.get({ _id: $stateParams.networkId }, function (data) {
      $scope.network = data
      $scope.params['network'] = $scope.network._id
      $scope.loader = true

      $scope.getCount(function () {
        loadStatuses()
        $scope.changeList()
        $scope.loader = false
      })
    }, Notif.error)
  }

  $scope.changeList = function () {
    if (!$scope.network._id) return
    var options = _.clone($scope.options)
    options.skip = ($scope.pagination.currentPage - 1) * $scope.pagination.limit
    $scope.pagination.skip = options.skip
    options.limit = $scope.pagination.limit
    options.populate = true

    Creche.query({ params: $scope.params, options: options }, function (creches) {
      $scope.creches = creches
    }, Notif.error)
  }

  $scope.resetPaging = function (total) {
    $scope.pagination.total = total
    $scope.pagination.skip = 0
    $scope.pagination.currentPage = 1
  }

  /**
   * Load the PAM
   * @param creche
   */
  $scope.locateCreche = function (creche) {
    var crecheAddress = (_.get(creche.address, 'street', '').toString() + ' ' + _.get(creche.address, 'zipCode', '').toString() + ' ' + _.get(creche.address, 'city', '').toString()).trim()
    $state.go('pam', {
      'networkId': creche.network._id,
      'address': crecheAddress
    })
  }

  $scope.groupNurseriesUpdate = function () {
    const globaEditNurseries = $('#globalEditNurseries').val()
    let totalUpdated = 0
    Creche.query({ params: $scope.params }, function (creches) {
      _.each(creches, (creche) => {
        if (creche.status !== globaEditNurseries) {
          totalUpdated++
          let tmpNursery = Object.assign({}, creche)
          tmpNursery.status = globaEditNurseries
          Creche.update(tmpNursery, (data) => {
            // console.log(data)
          })
        }
        $scope.changeList()
        Notif.success(`Nous avons mis à jour ${totalUpdated} creche(s) pour ce réseau`)
      })
    }, Notif.error)
  }

}])
