'use strict';

app.controller('ContractCtrl', ['$scope', '$window', '_', '$q', '$state', '$stateParams', 'Notif', 'Contract', 'Creche', 'Network', 'Family', 'Company', 'Site', 'Application', 'User', 'ContractStatus', 'AuthService', 'Modal', 'moment', 'Role', '$uibModal', 'Event', function ($scope, $window, _, $q, $state, $stateParams, Notif, Contract, Creche, Network, Family, Company, Site, Application, User, ContractStatus, AuthService, Modal, moment, Role, $uibModal, Event) {
    /* Init variables */
    $scope.$state = $state;
    $scope.loader = false;
    $scope.pending = false;
    $scope.contract = null;
    $scope.contracts = [];
    $scope.creches = [];
    $scope.sites = [];
    $scope.applications = [];
    $scope.networks = [];
    $scope.family = null;
    $scope.statuses = [];
    $scope.users = [];
    $scope.rolesId = [];
    $scope.pagination = {
        total: 0,
        skip: 0,
        currentPage: 1,
        limit: 20
    };


    // This openModal() is here for the modal of contract.
    var openModal = function (mode, contract) {
        var options = {
            animation: false,
            backdrop: 'static',
            keyboard: true,
            size: 'md',
            templateUrl: '/partials/ui/contractCompany.html',
            resolve: {
                Context: function () {
                    return {
                        contract: contract,
                        mode: mode,
                        scope: $scope,
                        edit: $scope.editMode,
                        type: 'contract',
                        nbContracts: _.get(contract, 'nbSubContracts', {count: 0}),
                        companyId: contract._id
                    };
                }
            },
            controller: 'ContractModalInstanceCtrl'
        };
        return $uibModal.open(options);
    };

    $scope.viewContract = function (contract) {
        var m = openModal('view', contract);
        m.result.then($scope.initContracts);
    };

    $scope.now = new Date();
    $scope.childNum = [];
    $scope.categories = [
        "new business",
        "renouvellement",
        "mouvement",
        "intercrèche"
    ];

    $scope.saleTypes = [
        "Adressé",
        "Commission"
    ];

    $scope.pipes = [
        "cycle court",
        "cycle long",
        "réseau"
    ];

    $scope.reasons = [
        "Déménagement-autre crèche trouvée",
        "Déménagement-perte du contrat",
        "Fin contrat salarié",
        "Autre mode de garde",
        "Place mairie",
        "Jamais entré",
        "Pb ouverture crèche",
        "Tarification ",
        "Congé parental",
        "Exigence des parents",
        "Crèche trop loin",
        "Pas de dispo immédiate",
        "Refus du rythme par le gestionnaire",
        "Reste dans crèche initiale",
        "Enfant entre à l'école",
        "Non reconduction par l'entreprise",
        "Annulation gestionnaire",
        "Annulation entreprise",
        "Raison de santé (Décès enfant, grande prématurité)",
        "0 info",
        "Déménagement à l'étranger",
        "Parents non payeurs",
        "Naissance N-1/ naissance prévue",
        "Insatisfaction des parents vis à vis de la crèche"
    ];

    $scope.capTerminates = [
        "n'importe quand - avec préavis de 3 mois",
        "n'importe quand - avec préavis de 6 mois",
        "à chaque fin d'année scolaire - avec préavis de 6 mois",
        "à chaque fin d'année scolaire - avec préavis de 3 mois",
        "au terme du contrat - avec préavis de 3 mois",
        "au terme du contrat - avec préavis de 6 mois",
        "aucune"
    ];

    $scope.prices = [
        'Paris + 92', 'Province', 'Ile de France (hors 92)',
        'Grandes Villes de Province (10 villes)', 'Petite Couronne (contrat avant 01/01/2017)',
        'Grande Couronne (contrat avant 01/01/2017)'
    ]

    $scope.dateFormat = 'dd/MM/yyyy';
    $scope.dateOptions = {
        startingDay: 1
    };

    /* Options used by filter dateMonth */
    $scope.dateFormat2 = 'MM/yyyy';
    $scope.dateOptions2 = {
        datepickerMode: "month",
        minMode: "month"
    };

    $scope.popups = [];
    $scope.open = function (idx) {
        _.set($scope, 'popups[' + idx + '].opened', true);
    };

    $scope.paginationLimits = [1, 2, 5, 10, 20, 50, 100, 200];

    $scope.params = {type: 'family'};
    $scope.params.states = [];
    $scope.search = {text: ''};
    $scope.query = {
        contracts: [],
        creches: [],
        companies: [],
        applications: [],
        statuses: [],
        users: [],
        sites: [],
        networks: [],
        date: {},
        locked: null,
        mail: null,
        states: []
    };
    $scope.options = {
        sort: 'created',
        order: 'DESC'
    };

    $scope.mode = $stateParams.mode;

    $scope.isBillFilter = false;

    var isBillFilter = function () {
        $scope.isBillFilter = !(_.isUndefined($scope.query.date.month) || _.isNull($scope.query.date.month));
    }

    $scope.isHubState = function () {
        var states = $state.$current.name.split('.');
        return (states.indexOf('hub-contract') >= 0 || states.indexOf('hub-contract-detail') >= 0);
    };

    $scope.lastEvent = {};
    $scope.firstEvent = {};
    $scope.currentEvent = {};
    // Multiple Edition variables
    $scope.choicesAll = false;
    $scope.choices = _.range($scope.pagination.limit).map(function () {
        return false;
    });
    $scope.nbCheck = 0;
    $scope.rcToEdit = undefined;
    /***************************/
    $scope.checkId = function (index) {
        $scope.nbCheck += $scope.choices[index] ? 1 : -1;
    };

    $scope.checkAll = function () {
        $scope.choicesAll = !$scope.choicesAll;
        for (var i = 0; i < $scope.choices.length; i++) {
            $scope.choices[i] = $scope.choicesAll;
        }
        if ($scope.choicesAll)
            $scope.nbCheck = $scope.choices.length;
        else
            $scope.nbCheck = 0;
    };

    $scope.editChoices = function (rcToEdit) {
        if (!_.isUndefined(rcToEdit) && !_.isEmpty(rcToEdit)) {
            for (var i = 0; i < $scope.choices.length; i++) {
                if ($scope.choices[i]) {
                    $scope.contracts[i].quality.operator = rcToEdit;
                    $scope.save($scope.contracts[i]);
                }
            }
        }
    };

    var resetChoices = function (b_total) {
        if (b_total) {
            $scope.nbCheck = 0;
            $scope.choicesAll = false;
            $scope.choices = _.range($scope.pagination.limit).map(function () {
                return false;
            });
        } else {
            if ($scope.choices.length > $scope.pagination.limit) {
                $scope.choices = _.dropRight($scope.choices, $scope.choices.length - $scope.pagination.limit);
            } else {
                for (var i = $scope.choices.length; i < $scope.pagination.limit; i++) {
                    $scope.choices.push($scope.choicesAll);
                }
            }
        }
    };

    var getModalContracts = function () {
        var contracts = [];
        for (var i = 0; i < $scope.choices.length; i++) {
            if ($scope.choices[i]) {
                contracts.push($scope.contracts[i]);
            }
        }
        return contracts;
    };

    $scope.openMultipleEditModal = function () {
        var modalInstance = $uibModal.open({
            templateUrl: "partials/ui/contractMultipleEdit.html",
            controller: 'ContractMultipleEditModalInstanceCtrl',
            backdrop: 'static',
            size: 'lg',
            resolve: {
                Context: function () {
                    return {
                        users: $scope.users,
                        contracts: getModalContracts(),
                        choices: $scope.choices,
                        application: $scope.application,
                        type: 'family',
                        scope: $scope,
                        mode: 'edit'
                    };
                }
            }
        });


        modalInstance.result.then(function () {
            $scope.loader = true;
            $scope.changeList(function () {
                $scope.loader = false;
            });
        });
    };
    $scope.getMonthly = function (value) {
        if (_.isNaN(value)) return '';
        value = value / 12;
        if (_.isNaN(value)) return '';
        return value.toFixed(2)
    }

    var formatDate = function (date) {
        var dates = [
            'planned.start', 'planned.end', 'real.start', 'real.end',
            'cancelled', 'terminated', 'bdc', 'signed',
            'effective.start', 'effective.end', 'priceAnticipated',
            'free.start', 'free.end', 'call', 'interest'
        ];
        _.each(dates, function (d) {
            var dt = _.get(date, d);
            if (dt) _.set(date, d, new Date(dt));
        });
    };

    var getBoolean = function (boolString) {
        switch (boolString) {
            case 'true':
                return true;
            case 'false':
                return false;
        }
        return undefined;
    };

    var setDisplayUser = function (users, autocomplete) {
        _.each(users, function (user) {
            user['display'] = user.firstname[0].toUpperCase() + '. ' + _.capitalize(user.lastname);
        });
        if (autocomplete) users.unshift({display: 'Aucun', _id: 'NoUser'});
    };

    var setDisplayApplication = function (applications) {
        _.each(applications, function (application) {
            application['display'] = application.name;
        });
    };

    var setDisplayCompany = function (companies) {
        _.each(companies, function (company) {
            company['display'] = company.name || company.denomination;
        });
    };

    var setDisplaySite = function (sites) {
        _.each(sites, function (site) {
            site['display'] = site.name;
        });
    }

    var setDisplayNetwork = function (networks) {
        _.each(networks, function (network) {
            network['display'] = network._name;
        });
    };


    var buildStateParams = function () {
        var params = {};
        params.search = $scope.search.text;
        _.each(['creches', 'users', 'companies', 'applications', 'contracts', 'statuses', 'sites', 'networks'], function (key) {
            params[key] = _.get($scope.params, key, []).join(';');
        });

        params.dateStart = _.get($scope.params, 'date.start');
        params.dateEnd = _.get($scope.params, 'date.end');
        params.dateMonth = _.get($scope.params, 'date.month');
        if (params.dateStart) params.dateStart = moment(params.dateStart).startOf('day').format('DD-MM-YYYY');
        if (params.dateEnd) params.dateEnd = moment(params.dateEnd).startOf('day').format('DD-MM-YYYY');
        if (params.dateMonth) params.dateMonth = moment(params.dateMonth).startOf('month').format('MM-YYYY');
        params.locked = _.get($scope.params, 'locked');
        params.mail = _.get($scope.params, 'mail');

        //params.state = _.get($scope.params, 'state');
        params.state = '';
        if (!_.isEmpty($scope.params.states)) {
            angular.forEach($scope.params.states, function (item, key) {
                if (key == 0) params.state += item;
                else params.state += ';' + item;
            });
        }

        params.page = $scope.pagination.currentPage;
        params.limit = $scope.pagination.limit;
        params.sort = ($scope.options.order === 'DESC' ? '-' : '') + $scope.options.sort;
        var state = ($state.is('hub-contract')) ? 'hub-contract' : 'contract';
        $state.go(state, params, {
            reload: false,
            notify: false,
            location: 'replace',
            inherit: true
        });
    };

    var loadStateParams = function () {
        $scope.pagination.limit = validateIntParams($stateParams.limit, $scope.pagination.limit);
        $scope.choices = _.range($scope.pagination.limit).map(function () {
            return false;
        });
        $scope.pagination.currentPage = validateIntParams($stateParams.page, 1);
        $scope.query.locked = $stateParams.locked;
        $scope.query.mail = $stateParams.mail;

        //$scope.query.state = $stateParams.state;
        if (!_.isUndefined($stateParams.state)) {
            if ($stateParams.state.indexOf(';') != -1) {
                $scope.query.states = $stateParams.state.split(';');
            } else {
                $scope.query.states = [$stateParams.state];
            }
        }

        if ($stateParams.dateStart) {
            var dStart = moment.utc($stateParams.dateStart, 'DD-MM-YYYY').toDate();
            _.set($scope.params, 'date.start', dStart);
            $scope.query.date.start = dStart;
        }
        if ($stateParams.dateEnd) {
            var dEnd = moment.utc($stateParams.dateEnd, 'DD-MM-YYYY').toDate();
            _.set($scope.params, 'date.end', dEnd);
            $scope.query.date.end = dEnd;
        }
        if ($stateParams.dateMonth) {
            var dMonth = moment.utc($stateParams.dateMonth, 'MM-YYYY').toDate();
            _.set($scope.params, 'date.month', dMonth);
            $scope.query.date.month = dMonth;
        }
        if ($stateParams.sort) {
            if ($stateParams.sort[0] === '-') {
                $scope.options.order = 'DESC';
                $scope.options.sort = $stateParams.sort.substr(1);
            } else {
                $scope.options.order = 'ASC';
                $scope.options.sort = $stateParams.sort;
            }
        }
        _.each(['creches', 'users', 'companies', 'applications', 'contracts', 'statuses', 'sites', 'networks'], function (key) {
            if ($stateParams[key]) $scope.params[key] = $stateParams[key].split(';');
            else $scope.params[key] = [];
        });
        if ($stateParams.search) $scope.search.text = $stateParams.search;
        if ($stateParams.locked) $scope.params.locked = $stateParams.locked;
        if ($stateParams.mail) $scope.params.mail = $stateParams.mail;
        if ($stateParams.state) $scope.params.states = $stateParams.state.split(';');

        async.parallel([
            function (cb) {
                if (!$scope.params.users.length) return cb();
                var idx = _.indexOf($scope.params.users, 'NoUser');
                if (idx > -1) $scope.params.users[idx] = null;
                return User.query({params: {ids: $scope.params.users}}, function (users) {
                    setDisplayUser(users, false);
                    $scope.query.users = users;
                    cb();
                }, cb);
            },
            function (cb) {
                if (!$scope.params.creches.length) return cb();
                return Creche.query({params: {ids: $scope.params.creches}}, function (creches) {
                    $scope.query.creches = creches;
                    cb();
                }, cb);
            },
            function (cb) {
                if (!$scope.params.contracts.length) return cb();
                return Contract.query({params: {ids: $scope.params.contracts}}, function (contracts) {
                    $scope.query.contracts = contracts;
                    cb();
                }, cb);
            },
            function (cb) {
                if (!$scope.params.companies.length) return cb();
                return Company.query({params: {ids: $scope.params.companies}}, function (companies) {
                    setDisplayCompany(companies);
                    $scope.query.companies = companies;
                    cb();
                }, cb);
            },
            function (cb) {
                if (!$scope.params.applications.length) return cb();
                var applications = [];
                _.forEach($scope.params.applications, function (appId) {
                    var app = _.find($scope.applications, function (app) {
                        return app._id.toString() === appId;
                    })
                    if (app) applications.push(app);
                })
                setDisplayApplication(applications);
                $scope.query.applications = applications;
                return cb();
            },
            function (cb) {
                if (!$scope.params.sites.length) return cb();
                return Site.query({params: {ids: $scope.params.sites}}, function (sites) {
                    setDisplaySite(sites);
                    $scope.query.sites = sites;
                    cb();
                }, cb);
            },
            function (cb) {
                if (!$scope.params.networks.length) return cb();
                return Network.query({params: {ids: $scope.params.networks}}, function (networks) {
                    setDisplayNetwork(networks);
                    $scope.query.networks = networks;
                    cb();
                }, cb);
            },
            function (cb) {
                if (!$scope.params.statuses.length) return cb();
                return ContractStatus.query({params: {ids: $scope.params.statuses}}, function (statuses) {
                    $scope.query.statuses = statuses;
                    cb();
                }, cb);
            }
        ], Notif.error);
    };

    $scope.exportList = function (format) {
        var options = encodeURIComponent(JSON.stringify($scope.options));
        var params = encodeURIComponent(JSON.stringify($scope.params));
        var search = encodeURIComponent(JSON.stringify($scope.search));

        $window.open('/api/contract/export.' + format + '?params=' + params + '&search=' + search + '&options=' + options, '_blank');
    };


    $scope.changeLimit = function () {
        buildStateParams();
        $scope.loader = true;
        $scope.changeList(function () {
            resetChoices(false);
            $scope.loader = false;
        });
    };

    var getCount = function (cb) {
        if ($scope.isHubState() && $scope.params.applications.length === 0) {
            var applications = _.filter($scope.applications, {type: 'hub'});
            $scope.params.applications = _.map(_.filter($scope.applications, {type: 'hub'}), '_id');
        }
        // else if ($scope.query.sites.length === 0) {
        //   $scope.params.application = "familyhub";
        // }

        buildChildNumParam();

        Contract.count({params: $scope.params, search: $scope.search}, function (data) {
            if (!data) return cb();
            $scope.resetPaging(data.count);
            return cb();
        }, Notif.error);
    };

    $scope.getChild = function (id) {
        if (!$scope.family) return '';
        var i = _.findIndex($scope.family.children, {_id: id});
        return (i === -1 ? {} : $scope.family.children[i]);
    };

    var loadStatuses = function () {
        return ContractStatus.query({}, function (statuses) {
            $scope.statuses = statuses;
        }, Notif.error);
    };

    $scope.contractSelect = function () {
        var c = $scope.contract;
        var p = _.find($scope.contractsCompany, {_id: c.parent});
        var toCopyArr = [
            'price.sale',
            'price.sales',
            'date.signed',
            'price.billing',
            'application'
        ];
        _.each(toCopyArr, function (toCopy) {
            _.set(c, toCopy, _.get(p, toCopy));
        });
    };

    $scope.calcProrata = function (type) {
        var p = _.get($scope.contract, 'price.' + type, 0);
        var r = _.get($scope.contract, 'rythm') || 5;
        return p / 5.0 * r;
    };

    $scope.getStatus = function (id) {
        var i = _.findIndex($scope.statuses, {_id: id});
        return (i === -1 ? '' : $scope.statuses[i].label);
    };

    $scope.getLastname = function (family) {
        var lastname = _.get(family, 'parents[0].lastname', '');
        if (_.isEmpty(lastname)) lastname = _.get(family, 'parents[1].lastname', '');
        return _.capitalize(lastname);
    };

    $scope.delete = function (contract) {
        Modal.confirm().then(function (result) {
            Contract.delete(contract, function () {
                Notif.success('Le berceau a bien été supprimé !');
                getCount(function () {
                    $scope.changeList();
                });
            }, Notif.error);
        });
    };

    $scope.resetPaging = function (total) {
        $scope.pagination.total = total;
    };

    var setupNetworkData = function (contract) {
        var n = _.find($scope.networks, function (network) {
            return network._id === $scope.networkId;
        }) || {};
        contract.price.interest = n.interest || contract.price.interest;
        contract.date.interest = new Date(n.interestDate) || contract.price.interest;
    };

    $scope.loadCreches = function (contract, cb) {
        if (!$scope.networkId) return (cb ? cb() : null);
        var p = Creche.query({
            params: {network: $scope.networkId},
            options: {sort: '_name', order: 'ASC'}
        }, function (creches) {
            $scope.creches = creches;
            if ($scope.contract && _.findIndex(creches, {_id: contract.creche}) === -1) $scope.contract.creche = null;
            setupNetworkData(contract);
            if (cb) cb();
        }).$promise;
        if (cb) p.catch(cb);
        else p.catch(Notif.error);
    };

    var loadCreche = function (contract, cb) {
        Creche.get({_id: contract.creche}, function (creche) {
            if (creche) {
                $scope.networkId = creche.network;
                $scope.loadCreches(contract, cb);
            }
        }, cb);
    };

    var loadApplications = function (cb) {
        Application.query({}, function (applications) {
            $scope.applications = applications;
            if (cb) return cb();
            return null;
        });
    };


    var loadCompanies = function (contract, family, cb) {
        var companyIds = [];
        _.each(family.parents, function (parent) {
            var id = _.get(parent, 'company.reference');
            if (id) companyIds.push(id);
        });
        if (!companyIds.length) companyIds = [contract.company];

        Company.query({params: {ids: companyIds}}, function (companies) {
            $scope.companies = companies;
            if (_.findIndex(companies, {_id: contract.company}) === -1) contract.company = null;
            cb();
        }, cb);
    };

    var loadFamily = function (contract, cb) {
        Family.get({_id: contract.family}, function (family) {
            if (!family || !family._id) return cb();
            family.application = _.find($scope.applications, {_id: family.application});
            $scope.family = family;
            return loadCompanies(contract, family, cb);
        }, Notif.error);
    };

    $scope.loadContracts = function (id, type, parent, cb) {
        if (!id || !type) return null;
        var params = {};
        params['type'] = type;
        params[type] = id;
        var p = Contract.query({params: params}, function (contracts) {
            if (type === 'company') {
                $scope.contractsCompany = contracts;
                if (_.findIndex(contracts, {_id: parent}) === -1) _.set($scope, 'contract.parent', null);
            } else if (type === 'family') {
                $scope.contracts = contracts;
            }
            if (cb) cb();
        }).$promise;
        if (cb) return p.catch(cb);
        else return p.catch(Notif.error);
    };

    $scope.getParentContract = function (parent) {
        return _.find($scope.contractsCompany, {_id: parent});
    }

    var loadNetworks = function (cb) {
        Network.query({options: {sort: '_name', order: 'ASC'}}, function (networks) {
            $scope.networks = networks;
            cb();
        }, cb);
    };

    var loadUsers = function (cb) {
        if (!cb) cb = Notif.error;
        Role.query({params: {label: 'Réseau Crèche'}}, function (roles) {
            $scope.rolesId = _.map(roles, '_id');
            User.query({
                params: {roles: $scope.rolesId, active: true},
                options: {sort: 'lastname', order: 'ASC'}
            }, function (users) {
                $scope.users = users;
                if (cb !== Notif.error) cb();
            }, cb);
        }, cb);
    };

    // FIXME: this controller should not init the parent's
    var initParentCtrl = function (contract) {
        _.set($scope.$parent, 'mode', $scope.mode);
        _.set($scope.$parent, 'contract', contract);
    };

    $scope.initContract = function () {
        if (!$stateParams.contractId) return Notif.error('Aucun contrat');
        Contract.get({_id: $stateParams.contractId}, function (c) {
            if (!c) return;
            async.series([
                function (cb) {
                    loadNetworks(function () {
                        if (c.creche) loadCreche(c, cb);
                        else cb();
                    });
                },
                function (cb) {
                    loadApplications(cb);
                },
                function (cb) {
                    loadUsers(cb);
                },
                function (cb) {
                    if (c.family) loadFamily(c, cb); else cb();
                },
                function (cb) {
                    if (c.company) $scope.loadContracts(c.company, 'company', c.parent, cb); else cb();
                }
            ], function (err) {
                if (err) return Notif.error(err);
                formatDate(c.date);
                $scope.contract = c;
                return initParentCtrl(c);
            });

            // FIXME: this should be removed
        }, Notif.error);
        return loadStatuses();
    };


    $scope.filterList = function () {
        _.each(['contracts', 'creches', 'companies', 'applications', 'statuses', 'users', 'sites', 'networks'], function (key) {
            $scope.params[key] = _.map($scope.query[key], '_id');
        });
        $scope.params.date = {
            start: $scope.query.date.start,
            end: $scope.query.date.end,
            month: $scope.query.date.month
        };

        $scope.params.locked = getBoolean($scope.query.locked);
        $scope.params.mail = getBoolean($scope.query.mail);

        _.set($scope, 'params.states', _.get($scope, 'query.states', []));
        //$scope.params.state = $scope.query.states;


        buildStateParams();
        isBillFilter();
        getCount(function () {
            $scope.changeList();
        });
    };

    $scope.activeState = function (contract) {
        var state;
        if (!contract) return '';
        var date = contract.date;
        if (isStatusLost(contract.status)) {
            state = "Perdu";
        }
        // BDC signe || envoye
        else if (contract.status === '58988b51924119d813a6c964' || contract.status === '58988b51924119d813a6c965') {
            if (_.get(date, 'effective.end') > $scope.now) {
                if (_.get(date, 'effective.start') <= $scope.now) state = "Actif";
                else state = "Embarqué";
            } else {
                state = "Inactif";
            }
        } else {
            state = "En cours";
        }
        return state;
    };

    var getNetworks = function (contracts) {
        var ids = _.remove(_.map(contracts, "creche.network"));
        Network.query({params: {ids: ids}}, function (networks) {
            $scope.networks = networks;
            _.each(contracts, function (c) {
                if (c.creche) _.set(c, 'populated.network', _.find(networks, {_id: c.creche.network}));
            });
        });
    };

    $scope.setBdcDate = function (contract) {
        if (contract && contract.status && contract.status === '58988b51924119d813a6c964') // O__BDC envoyé
            _.set(contract, 'date.bdc', Date.now());
        return;
    }

    var buildChildNumParam = function () {
        if ($scope.childNum.length > 0) {
            $scope.params.childNum = [];
            _.each($scope.childNum, function (num) {
                if (parseInt(num.text)) $scope.params.childNum.push(parseInt(num.text));
            });
            _.pull($scope.params.childNum, null);
        } else if ($scope.childNum.length === 0) {
            $scope.params.childNum = undefined;
        }
    }

    $scope.isMultiplePriceSales = function (contract) {
        var c = contract || $scope.contract;
        var p = _.get(c, ['price', 'sales']);
        return (p && p.length > 0);
    }

    $scope.changeList = function (cb) {
        var options = _.clone($scope.options);
        options.skip = ($scope.pagination.currentPage - 1) * $scope.pagination.limit;
        $scope.pagination.skip = options.skip;
        options.limit = $scope.pagination.limit;
        options.populate = true;

        buildChildNumParam();

        if ($scope.isHubState()) {
            var applications = [];
            if ($scope.params.applications.length === 0) {
                applications = _.filter($scope.applications, {type: 'hub'});
                $scope.params.applications = _.map(_.filter($scope.applications, {type: 'hub'}), '_id');
            } else {
                _.forEach($scope.params.applications, function (appId) {
                    applications.push(_.find($scope.applications, function (app) {
                        return appId === app._id.toString();
                    }))
                })

            }
            setDisplayApplication(applications)
            $scope.query.applications = applications;
        }
        // else if ($scope.query.sites.length === 0) {
        //   $scope.params.application = "familyhub";
        // }

        Contract.query({params: $scope.params, search: $scope.search, options: options}, function (contracts) {
            _.each(contracts, function (c, cb) {
                formatDate(c.date);
                c.application = (c.application) ? _.find($scope.applications, {'_id': c.application}) : {'name': 'Icare'};
                if (_.has(c, ['family', 'application'])) _.set(c, ['family', 'application'], _.find($scope.applications, {_id: c.family.application}))
            });
            getNetworks(contracts);
            $scope.contracts = contracts;
            if (cb) cb();
        }, Notif.error);
    }

    $scope.paginate = function () {
        $scope.changeList();
        resetChoices(true);
        buildStateParams();
    };

    var validateIntParams = function (s, defaultValue) {
        defaultValue = (_.isUndefined(defaultValue) ? 1 : defaultValue);
        if (!s) return defaultValue;
        var v = parseInt(s);
        if (_.isNaN(v) || v < 1) v = defaultValue;
        return v;
    };

    $scope.initContracts = function () {
        $scope.loader = true;
        loadApplications(function () {
            loadStateParams();
            isBillFilter();
            getCount(function () {
                loadUsers(function () {
                    // init in init Company for rolesId
                    $scope.searchUsers = makeSearchFun(User, {roles: $scope.rolesId, active: true}, setDisplayUser);
                });
                loadStatuses();
                $scope.changeList();
                $scope.loader = false;
            });
        })
    };

    var createContract = function (contract) {
        $scope.pending = true;
        return Contract.save(_.omit(contract, 'populated'), function (doc) {
            $scope.pending = false;
            Notif.success('Le berceau a bien été créé');
        }, function (err) {
            $scope.pending = false;
            Notif.error
        });
    };

    var isNotNull = function (val) {
        return !_.isNull(val);
    }

    var isNotUndefined = function (val) {
        return !_.isUndefined(val);
    }

    var isNotEmpty = function (val) {
        return !_.isEmpty(val);
    }

    var isNotNumber = function (val) {
        return !_.isNumber(val);
    }

    var isValidPrice = function (val) {
        return (isNotNull(val) && isNotUndefined(val) && isNotNumber(val) && val > 0);
    }

    var isBDC = function (val) {
        let itIs = true;
        let bdc = ["58988b51924119d813a6c964", "58988b51924119d813a6c965"]; // 1st: n__bdr-envoye  2nd: o__bdc-envoye 3rd: p__bdc-signe
        let stat = _.get($scope, ['contract', 'status'], '');
        if (bdc.indexOf(stat) === -1) {
            itIs = false;
        }
        return itIs;
    }

    var checkStatus = function (val) {
        var valid = true;
        var statuses = ["58988b51924119d813a6c963", "58988b51924119d813a6c964", "58988b51924119d813a6c965"]; // 1st: n__bdr-envoye  2nd: o__bdc-envoye 3rd: p__bdc-signe
        var status = _.get($scope, ['contract', 'status'], '');
        if (statuses.indexOf(status) !== -1 && (_.isNull(val) || _.isUndefined(val) || val === ''))
            valid = false;
        return valid;
    }
//return true if status is = I or J
    var isStatusLost = function (currentStatus) {
        let lost = true;
        let statusesLost = ["58988b51924119d813a6c95a", "58988b51924119d813a6c95b", "58988b51924119d813a6c95c", "58988b51924119d813a6c95d", "58988b51924119d813a6c95e", "58988b51924119d813a6c95f"];
        if (statusesLost.indexOf(currentStatus) === -1)
            lost = false;
        return lost;
    }

    var checkPrices = function (val) {
        var valid = false;
        if ($scope.isMultiplePriceSales()) {
            _.forEach(_.get($scope.contract, ['price', 'sales'], []), function (s) {
                if (!valid) valid = isValidPrice(s.value);
            })
        } else valid = isValidPrice(_.get($scope.contract, ['price', 'sale']));
        return valid;
    }

    var checkDates = function (val) {
        var flag = true;

        if (!_.isNull(val))
            flag = moment(val).isSameOrAfter(moment($scope.contract.date.effective.start));

        return (flag);
    }

    var isBDC = function (contract) {
        let itIs = true;
        let bdcr = ["58988b51924119d813a6c964", "58988b51924119d813a6c965"]; // 1st: o__bdc-envoye 2nd: p__bdc-signe
        let stat = _.get($scope, ['contract', 'status'], '');
        if (stat == null || stat == '') {
            stat = contract.status;
        }
        if (bdcr.indexOf(stat) === -1) {
            itIs = false;
        }
        return itIs;
    }

    var setEffectiveStart = function (date) {
        var attrs = ['planned.start', 'real.start'];
        var d;
        _.each(attrs, function (attr) {
            var v = _.get(date, attr);
            if (v) d = v;
        });
        if (d) _.set(date, 'effective.start', d);
    }

    var setEffectiveEnd = function (date) {
        var attrs = ["planned.end", "real.end", "cancelled"];
        var d;
        _.each(attrs, function (attr) {
            var v = _.get(date, attr);
            if (v) d = v;
        });
        if (d) _.set(date, 'effective.end', d);
    }

    //if the contract is edited to BDC then flag becomes automatically attributaire
    $scope.isAttributaire = function (contract) {
        if (isBDC(contract)) {
            //if the change is done on the list with all the families (scope not accessible there)
            if (contract !== undefined) {
                contract.family.flag = "attributaire";
                Family.update(contract.family);
            }
            //if the change is done through the edition of a specific contract
            else {
                $scope.family.flag = "attributaire";
                Family.update($scope.family);
            }
        }
        return true;
    }

    $scope.canSubmit = function () {
        var submit = true;
        if ($scope.contract.date) {
            setEffectiveStart($scope.contract.date);
            setEffectiveEnd($scope.contract.date);
        }
        var validations = _.filter([
            {key: 'pipe', label: 'Canal de vente', type: 'company', func: [isNotNull, isNotUndefined, isNotEmpty]},
            {key: 'date.signed', label: 'Signature du contrat', type: 'company', func: [isNotNull, isNotUndefined]},
            {key: 'price.sale', label: 'Prix de vente annuel unitaire (ETP)', type: 'company', func: checkPrices},
            {
                key: 'price.buy',
                label: 'Prix d\'achat annuel (ETP)',
                type: 'family',
                func: checkStatus,
                mess: 'Données manquantes pour valider ce statut'
            },
            {
                key: 'price.sale',
                label: 'Prix de vente annuel (ETP)',
                type: 'family',
                func: checkStatus,
                mess: 'Données manquantes pour valider ce statut'
            },
            {
                key: 'date.planned.start',
                label: 'Date d\'entrée (réelle)',
                type: 'family',
                func: checkStatus,
                mess: 'Données manquantes pour valider ce statut'
            },
            {
                key: 'date.planned.end',
                label: 'Date de sortie (prévue)',
                type: 'family',
                func: checkStatus,
                mess: 'Données manquantes pour valider ce statut'
            },
            {
                key: 'company',
                label: 'Entreprise',
                type: 'family',
                func: checkStatus,
                mess: 'Données manquantes pour valider ce statut'
            },
            {
                key: 'creche',
                label: 'Crèche',
                type: 'family',
                func: checkStatus,
                mess: 'Données manquantes pour valider ce statut'
            },
            {
                key: 'category',
                label: 'Catégorie',
                type: 'family',
                func: checkStatus,
                mess: 'Données manquantes pour valider ce statut'
            },
            //{ key: 'date.effective.end', label: 'Date de sortie (effective)', type: 'family', func: checkDates, mess: 'La date de sortie effective doit être supérieure à la date effective d\'entrée' }
        ], {type: _.get($scope.contract, 'type')})
        for (var i = 0; i < validations.length; i++) {
            var v = validations[i];
            var val = _.get($scope.contract, v.key);
            if (!_.isArray(v.func)) {
                v.func = [v.func];
            }
            for (var j = 0; j < v.func.length; j++) {
                if (v.func[j](val) === false) {
                    submit = false;
                    j = v.func.length;
                }
            }
            if (!submit) {
                var message = 'Le champ "' + v.label + '" est obligatoire, merci de le renseigner.';
                if (v.mess) message = v.mess + ' ("' + v.label + '" doit être renseigné)';
                Notif.set(message, 'warn');
                i = validations.length;
            }
        }
        //if commission && BDC/R envoye/signe && num crèche virtuelle not set then submit is false
        if (_.get($scope.contract, 'num') == null && isBDC($scope.contract) && _.get($scope.contract, 'saleType') === "Commission") {
            submit = false;
            var msg = 'Le champ Numéro de berceaux (crèche virtuelle) est obligatoire, merci de le renseigner.';
            Notif.set(msg, 'warn');

        }
        return submit;
    }


    var updateContract = function (contract) {
        $scope.pending = true;
        return Contract.update(_.omit(contract, 'populated'), function (doc) {
            $scope.pending = false;
            Notif.success('Le berceau a bien été modifié !');
        }, function () {
            $scope.pending = false;
            Notif.error(err);
        });

    }

    $scope.save = function (contract) {
        _.each(['company', 'parent', 'status', 'creche', 'user', 'application'], function (key) {
            if (!contract[key]) contract[key] = null;
        });

        if (_.get(contract, 'rc._id') === '') contract.rc = null;

        var p;
        if (contract.id) p = createContract(contract);
        else {
            p = updateContract(contract);
        }
        if (p) {
            p.$promise.then(function () {
                // reinit only if edit
                if ($state.is('contract') == false) $scope.initContract();
            });
        }
    };

    $scope.lock = function (contract) {
        Modal.confirm().then(function () {
            contract.locked = true;
            Contract.update({_id: contract._id, locked: true}, function () {
                Notif.success('Le berceau a bien été verrouillé');
                if ($stateParams.mode === 'edit') $state.go('contract-detail.profile', {
                    contractId: contract._id,
                    mode: 'view'
                });
            }, Notif.error);
        });
    };

    $scope.unlock = function (contract) {
        Modal.confirm().then(function () {
            contract.locked = false;
            Contract.update({_id: contract._id, locked: false}, function () {
                Notif.success('Le berceau a bien été déverrouillé');
            }, Notif.error);
        });
    };

    $scope.sortList = function (key) {
        $scope.loader = true;
        $scope.options.sort = key;
        $scope.options.order = $scope.options.order == 'DESC' ? 'ASC' : 'DESC';
        resetChoices(true);

        $scope.changeList(function () {
            $scope.loader = false;
        });
        buildStateParams();
    };

    $scope.getFaSort = function (key) {
        return {
            'fa-sort': $scope.options.sort !== key,
            'fa-sort-asc': $scope.options.sort === key && $scope.options.order === 'ASC',
            'fa-sort-desc': $scope.options.sort === key && $scope.options.order === 'DESC'
        };
    };

    $scope.canAffectContract = function (contract) {
        var user = AuthService.getAuthenticatedUser();
        return _.indexOf(user.rights, 'CONTRACT_EDIT') !== -1;
    };

    var makeSearchFun = function (Model, params, func) {
        return function (query) {
            var deferred = $q.defer();
            Model.query({
                params: params,
                search: {text: query},
                options: {limit: 20, sort: '_name', order: 'ASC'}
            }, function (docs) {
                if (func) func(docs, true);
                deferred.resolve(docs);
            }, Notif.error);
            return deferred.promise;
        };
    };

    $scope.searchContracts = makeSearchFun(Contract, {type: 'company'});
    $scope.searchCreches = makeSearchFun(Creche, {});
    $scope.searchCompanies = makeSearchFun(Company, {}, setDisplayCompany);
    $scope.searchStatuses = makeSearchFun(ContractStatus, {});
    $scope.searchSites = makeSearchFun(Site, {}, setDisplaySite);
    $scope.searchNetworks = makeSearchFun(Network, {}, setDisplayNetwork);
    $scope.searchApplications = makeSearchFun(Application, {}, setDisplayApplication)
}]);

app.controller('ContractHistoryCtrl', ['$scope', '_', '$stateParams', 'Contract', 'ContractHistory', 'ContractStatus', 'Notif', 'User', function ($scope, _, $stateParams, Contract, ContractHistory, ContractStatus, Notif, User) {
    $scope.contractHistory = [];
    $scope.contract = null;
    $scope.contractStatus = [];
    $scope.users = [];

    var loadContract = function () {
        Contract.get({_id: $stateParams.contractId}, function (contract) {
            $scope.contract = contract;
            $scope.$parent.mode = $stateParams.mode;
            $scope.$parent.contract = contract;
        });
    };

    $scope.getType = function (type) {
        return (type === 'assign' ? 'Assignation' : 'Changement de statut');
    };

    $scope.getName = function (item) {
        if (item.type === 'assign') {
            var user = _.find($scope.users, {_id: item.reference});
            if (!user) return 'Inconnu';
            return user.firstname[0] + '. ' + _.capitalize(user.lastname);
        } else if (item.type === 'status') {
            return _.find($scope.contractStatus, {_id: item.reference}).label;
        } else
            return "Inconnu";
    };

    $scope.initHistory = function () {
        ContractStatus.query({}, function (status) {
            $scope.contractStatus = status;
        });

        User.query({}, function (users) {
            $scope.users = users;
            ContractHistory.query({contract: $stateParams.contractId}, function (history) {
                $scope.contractHistory = history;
            });
        });

        loadContract();

    };
}]);

// app.controller('ContractEventCtrl', ['$scope', '_', '$uibModal', '$stateParams', 'AuthService', 'Contract', 'Event', 'Notif', 'User', 'Role', function ($scope, _, $uibModal, $stateParams, AuthService, Contract, Event, Notif, User, Role) {
//   $scope.events = [];
//   $scope.event = null;
//   $scope.contract = null;
//   $scope.users = [];
//   $scope.popup = false;
//   $scope.editing = false;
//   $scope.dateValidated = {
//     bool: false,
//     text: "(Théorique)"
//   };
//   $scope.null = null;
//
//   /* Datepicker options */
//   $scope.dateOptions = {
//     startingDay: 1
//   };
//
//   $scope.qualityStatuses = [
//     'Appel à passer',
//     'Appel complété',
//     'Messagerie',
//     'Mail/SMS envoyé'
//   ];
//
//   var user = AuthService.getAuthenticatedUser().user._id;
//
//   var loadUsers = function (cb) {
//     if (!cb) cb = Notif.error;
//     Role.query({ params: {label: 'Réseau Crèche'}}, function (roles) {
//       $scope.rolesId = _.map(roles, '_id');
//       User.query({params: {roles: $scope.rolesId, active: true}, options: {sort: 'lastname', order: 'ASC'}}, function (users) {
//         $scope.users = users;
//         if (cb !== Notif.error) cb();
//       }, cb);
//     }, cb);
//   };
//
//   var loadContract = function (cb) {
//     Contract.get({_id: $stateParams.contractId}, function (contract) {
//       $scope.contract = contract;
//       $scope.$parent.mode = $stateParams.mode;
//       $scope.$parent.contract = contract;
//       return cb();
//     });
//   };
//
//   $scope.getDate = function(date) {
//     var loc = moment.locale();
//     moment.locale('fr');
//     var newDate = moment(date).format('dddd D MMMM YYYY');
//     moment.locale(loc);
//     return newDate;
//   };
//
//   $scope.save = function(event) {
//     event.type = 'quality';
//     event.ref = $scope.contract._id;
//     if (!_.isUndefined(event.dates.planned) && !event.dates.planned.toString().length) {
//       delete event.dates.planned;
//     }
//     if (!_.isUndefined(event.status) && event.status.length) { _.set(event, 'dates.real', event.dates.planned); }
//     if (_.isNull(event.user._id) || _.isEmpty(event.user._id)) {
//       event.user = null;
//     }
//
//     if (event._id) {
//       Event.update(event, function(ev) {
//         if (!ev) return;
//         Notif.success("L'appel a bien été enregistré");
//         $scope.initEvents();
//       }, Notif.error);
//     }
//     else {
//       Event.save(event, function(ev) {
//         if (ev && ev._id) {
//           Notif.success("L'appel a bien été créé");
//           $scope.initEvents();
//         }
//         else return;
//       }, Notif.error);
//     }
//   };
//
//   $scope.initEvents = function () {
//     $scope.editing = false;
//     $scope.dateValidated = {
//       bool: true,
//       text: "(Validée)"
//     };
//     async.series([
//       function(cb) {
//         loadContract(cb);
//       },
//       function(cb) {
//         loadUsers(cb);
//       },
//       function(cb) {
//         var contractId = $stateParams.contractId;
//         Event.query({params: {type: 'quality', ref: contractId}, options: { sort: 'created', order: 'DESC'}}, function(events) {
//           $scope.events = events; //_.sortBy(events, 'created');
//           $scope.event = (events.length && _.get($scope, 'events[0].status') == 'Appel à passer') ? $scope.events[0] : null;
//           if (_.isNull($scope.event)) {
//             $scope.event = {};
//             if (_.indexOf($scope.users, user) > -1) $scope.event.user = user;
//             var dateMetric = !events.length ? 'month' : 'year';
//             var dateRef = !events.length ? $scope.contract.date.effective.start : $scope.events[0].dates.real;
//             _.set($scope.event, 'dates.planned', moment(dateRef).add(1, dateMetric).toDate());
//             $scope.dateValidated.text = "(Théorique)";
//             $scope.dateValidated.bool = false;
//           }
//           else {
//             _.forEach(['planned', 'real'], function(e) {
//               if (_.get($scope.event, 'dates[' + e + ']', null)) {
//                 $scope.event.dates[e] = moment($scope.event.dates[e]).toDate();
//               }
//             });
//             if (!_.get($scope.event, 'user._id', null)) _.set($scope.event, 'user._id', $scope.event.user);
//           }
//           return cb();
//         });
//       }
//     ]);
//   };
//
//   // This openModal() is here for the modal of contract.
//   $scope.openModal = function (ev, mode) {
//     var options = {
//       animation: false,
//       backdrop: 'static',
//       keyboard: true,
//       size: 'md',
//       templateUrl: '/partials/ui/contractEvent.html',
//       resolve: {
//         Context: function () {
//           return {
//             event: ev,
//             mode: mode,
//             contract: $scope.contract,
//             scope: $scope,
//             statuses: $scope.statuses,
//             users: $scope.users
//           };
//         }
//       },
//       controller: 'ContractEventModalInstanceCtrl'
//     };
//     var modalInstance = $uibModal.open(options);
//
//     modalInstance.result.then(function () {
//       $scope.initEvents();
//     });
//   };
// }]);

app.controller('ContractEventCtrl', ['$scope', '_', '$state', '$stateParams', 'Contract', function ($scope, _, $state, $stateParams, Contract) {
    var applications = [];
    Contract.get({_id: $stateParams.contractId}, function (c) {
        if (!c) return;
        $state.go('family.event-quality', {familyId: c.family});
    })
}]);
