'use strict';

app.controller('FamilyCtrl', ['$scope', '$anchorScroll', '$window', '_', 'moment', 'Modal', 'Application', 'Company', 'Registration', 'Site', 'Form', 'Question', 'Family', 'FamilyStatistic', 'Link', '$state', '$stateParams', 'Notif', 'User', 'Creche', 'AuthService', 'Geocoder', '$uibModal', 'FamilyStatus', '$filter', '$q', 'Comment', 'FamilyHistory', 'Right', 'VisDataSet', 'Contract', 'Sector', '$location', '$rootScope', function ($scope, $anchorScroll, $window, _, moment, Modal, Application, Company, Registration, Site, Form, Question, Family, FamilyStatistic, Link, $state, $stateParams, Notif, User, Creche, AuthService, Geocoder, $uibModal, FamilyStatus, $filter, $q, Comment, FamilyHistory, Right, VisDataSet, Contract, Sector, $location, $rootScope) {

  /* Init variables */
  var stateName = $state.$current.name.split('.');
  $scope.currentState = (stateName.length > 1) ? stateName[1]: stateName[0];
  $scope.search = {};
  $scope.params = {};
  $scope.ngForms = {};
  $scope.options = {};
  $scope.dates = {};
  $scope.families = [];
  $scope.users = [];
  $scope.creches = [];
  $scope.statuses = [];
  $scope.companies = [];
  $scope.addressCompanies = [];
  $scope.registrations = [];
  $scope.forms = [];
  $scope.sites = [];
  $scope.questions = [];
  $scope.links = [];
  $scope.metas = {name: [], source: [], medium: []};
  $scope.family = null;
  $scope.children = [];
  $scope.applications = [];
  $scope.application;
  $scope.nbContracts = {count: 0};
  $scope.emailHistory = [];

  $scope.initialFamily = null;
  $scope.initStatus = null;
  $scope.initCA = null;
  $scope.count = 0;
  $scope.newComments = [];
  $scope.mapLoad = 0;
  $scope.params.cas = [];
  $scope.params.statuts = [];
  $scope.params.zipCodes = [];
  $scope.params.companies = [];
  $scope.params.applications = [];

  $scope.isEdit = $stateParams.familyId ? true : false;

  //$scope.search.text = [];

  $scope.loader = false;
  //$scope.starLoader = false;
  $scope.options.sort = 'created';
  $scope.options.order = 'DESC';
  $scope.loadingCreche = false;

  $scope.paginationLimits = [20, 50, 100, 150, 200];

  /* List variable */
  $scope.pagination = {};
  $scope.pagination.total = 0;
  $scope.pagination.limit = $scope.paginationLimits[0];
  $scope.pagination.currentPage = 1;

  /* Regex for form */
  $scope.numberRegex = /^[0-9, .-]+$/;
  $scope.emailRegex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,6}/i;

  /* Datepicker options */
  $scope.dateOptions = {
    startingDay: 1
  };

  $scope.dateOptionsCustom = {
    startingDay: 1,
    customClass: getDayClass
  };

  /* DatePicker events array */
  var calendarLimit = 2; // (in mounths) 2 months of visibility

  $scope.calendarEvents = [];//_.range(calendarLimit).map(function() { return { date: undefined, status: '', count: 0 }; });
  $scope.dateParams = {};

  $scope.days = [
    {label: 'lundi', short: 'lun', i: 1},
    {label: 'mardi', short: 'mar', i: 2},
    {label: 'mercredi', short: 'mer', i: 3},
    {label: 'jeudi', short: 'jeu', i: 4},
    {label: 'vendredi', short: 'ven', i: 5},
    {label: 'samedi', short: 'sam', i: 6},
    {label: 'dimanche', short: 'dim', i: 0}
  ];


  /* Popover variables */
  $scope.auth = AuthService.getAuthenticatedUser();

  /* CHECK IF SEE_MARKETING_MENU is in the user rights */
  /* TODO: check by _id or change rights access */
  $scope.isAuthorizedPopup = function() {
    $scope.auth.user.roles.forEach(function(role) {
      role.rights.forEach(function(right) {
        if (right.key == 'SEE_MARKETING_MENU') {
          $scope.popover.rights = true;
          return;
        }
        if ($scope.popover.rights)
          return;
      });
    });
    return;
  };

  $scope.popover = {
    title: 'Tracking/Provenance',
    templateUrl: 'views/family/popover-template.html',
    isOpen: -1,
    rights: false,
  };

  /* ------------------ */


  // TODO: query flags
  $scope.flags = ["inscrit", "en cours", "attributaire", "liste d'attente", "inéligible", "désistement", "ancienne inscription"];

  // $scope.childTimelineOptions = function(child){
  //   var birthdate = moment(child.birthdate).hours(0).minutes(0).seconds(0).milliseconds(0);
  //   var options = {
  //     width: '100%',
  //     height: '110px',
  //     min: moment(birthdate).subtract(13, 'months').toDate(),
  //     max: moment(birthdate).add(49, 'months').toDate()
  //   }
  //   return options;
  // }

  // $scope.childTimelineData = function(child){
  //   var birthdate = moment(child.birthdate).hours(0).minutes(0).seconds(0).milliseconds(0);
  //   var data = VisDataSet([
  //     { id: 2, content: '-9<br>mois', start: moment(birthdate).subtract(9, 'months').toDate() },
  //     { id: 3, content: 'Naissance', start: moment(birthdate).toDate(), className: 'green' },
  //     { id: 4, content: '1<br>an', start: moment(birthdate).add(1, 'years').toDate() },
  //     { id: 5, content: '2<br>ans', start: moment(birthdate).add(2, 'years').toDate() },
  //     { id: 6, content: '3<br>ans', start: moment(birthdate).add(3, 'years').toDate() },
  //     { id: 7, content: 'hors<br>délai', start: moment(birthdate).add(3, 'years').toDate(), end: moment().hours(0).minutes(0).seconds(0).milliseconds(0).toDate(), type: 'background', className: 'horsdelai' },
  //     { id: 8, content: '', start: moment(birthdate).subtract(9, 'months').toDate(), end: moment(birthdate).add(3, 'years').toDate(), type: 'background', className: 'delai' }
  //   ])
  //   return data;
  // }

  var showStickyNotif = function() {
      $scope.stickyNotifDisplay = true;
  }

  var hideStickyNotif = function() {
    $scope.stickyNotifDisplay = false;
  }


  /* List page Method */

  /**
   * Init method to get all networks from the database
   */
  $scope.initFamilies = function () {

    // Display loader
    $scope.loader = true;

    $scope.isAuthorizedPopup();

    //Load applications
    $scope.loadApplications(function() {
      // Load the users
      $scope.loadUsers();
      //Load the links
      $scope.loadLinks();
      //Load the statuses
      $scope.loadStatuses();
      //Load the sectors
      $scope.loadSectors();

      //Load sites
      $scope.loadSites();

      //Load scores
      $scope.loadRegistrations();

      //Load stateparams
      $scope.loadStateParams();

      initScoresAndApplications();
    })
  }

  var setStatus = function(count) {
    if (!count) return '';
    if (count < 10) return 'bgcolor-t1';
    if (count < 20) return 'bgcolor-t2';
    if (count < 30) return 'bgcolor-t3';
    if (count >= 30) return 'bgcolor-t4';
  };

  $scope.loadNextStepEvents = function(cb) { // TODO: find a way to not launch those request if not a CC or other
    var user = AuthService.getAuthenticatedUser();
    var paramsStat = {};
    $scope.dateParams = {
      nextStep: calendarLimit.toString(),
      user: []
    };
    $scope.dateParams.user.push(_.get(user, ['user', '_id']));
    $scope.dateParams['status'] = [];
    paramsStat.application = 'icare';
    paramsStat.userId = _.get(user, ['user', '_id']);

    FamilyStatistic.priorities(paramsStat, function(data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i]._id) $scope.dateParams.status.push(data[i]._id);
      }
      var today = new Date();
      var param = {};
      Family.query({ params: $scope.dateParams, search: {}, options: {} }, function(data) {
        var dico = {};
        _.forEach(data, function(fam) {
          if (!dico[fam.nextStep]) {
            dico[fam.nextStep] = {
              date: fam.nextStep,
              status: '',
              count: 1
            };
          }
          else dico[fam.nextStep].count++;
        });
        _.forEach(dico, function(event) {
          event.status = setStatus(event.count);
          $scope.calendarEvents.push(event);
        });
        if(cb) return cb();
      });
    });
  };

  function getDayClass(data) {
    var date = data.date, mode = data.mode;
    if (mode === 'day') {
      var dayToCheck = new Date(date).setHours(0,0,0,0);

      for (var i = 0; i < $scope.calendarEvents.length; i++) {
        var currentDay = new Date($scope.calendarEvents[i].date).setHours(0,0,0,0);

        if (dayToCheck === currentDay) {
          return $scope.calendarEvents[i].status;
        }
      }
    }
    return '';
  };


  $scope.loadRegistrations = function(familyId, cb) {
    if (familyId) {
      Registration.query({ params: { 'family': familyId }, search: {}, options: {} },
        function (data) {
          $scope.registrations = data;
          if(cb) return cb();
        });
    } else {
      Registration.query({ params: {}, search: {}, options: {} },
        function (data) {
          $scope.registrations = data;
          if(cb) return cb();
        });
      }
  };

  $scope.loadSites = function(cb) {
    Site.query({ params: {}, search: {}, options: {} },
    function(data){
      $scope.sites = data;
      if(cb) return cb(null, data);
    });
  };

  $scope.loadForms = function(siteId, cb) {
    Form.query({ params: {site: siteId}, search: {}, options: {} },
    function(data){
      $scope.forms = data;
      if(cb) return cb(data);
    });
  };

  $scope.loadQuestions = function(siteId, formId, cb) {
    Question.query({ params: { site: siteId, form: formId }, search: {}, options: {} }, cb);
  }

  var initScoresAndApplications= function(families) {
    _.forEach(families, function (family) {
      var site = undefined;
      _.forEach($scope.registrations, function(registration) {
        if (registration.family) {
          var familyRegistration = (registration.family._id === family._id) ? registration : undefined;
          if (familyRegistration) family.score = familyRegistration.score;
        }
      });
      family.application = _.find($scope.applications, { '_id': family.application});
    });
  };

  $scope.loadApplications = function(cb) {
    Application.query({}, function(data) {
      $scope.applications = data;
      if(cb) return cb();
    });
  };

  /**
   * Reset pagging method
   * @param total
   */
  // $scope.resetPaging = function (total, page, limit) {
  //   $scope.pagination.total = total || 0;
  //   $scope.pagination.currentPage = page || 0;
  //   $scope.pagination.limit = limit || $scope.paginationLimits[0];
  //   $scope.pagination.offset = $scope.pagination.limit * $scope.pagination.currentPage;
  // };

  /**
   * Method called when the user changes the pagination
   */
  $scope.changeList = function (cb) {
    // Set options for query
    var options = _.clone($scope.options);
    options.skip = ($scope.pagination.currentPage - 1) * $scope.pagination.limit;
    options.limit = $scope.pagination.limit;
    options.populate = true;

    //Proper format params
    var params = _.clone($scope.params);
    if ((!_.isUndefined($scope.dates.admissionEnd) && !_.isNull($scope.dates.admissionEnd)) || (!_.isUndefined($scope.dates.admissionStart) && !_.isNull($scope.dates.admissionStart))) {
      params.dates = {}
      if($scope.dates.admissionStart)
        params.dates.admissionStart = moment($scope.dates.admissionStart).startOf('day').format('YYYY-MM-DD HH:mm:ss');
      if($scope.dates.admissionEnd)
        params.dates.admissionEnd = moment($scope.dates.admissionEnd).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    }
    else {
      delete params.dates;
    }

    params.user = [];
    var successUser = $scope.buildParams(params.cas, params.user);
    if (!successUser) delete params.user;
    delete params.cas;

    params.status = [];
    var successStatus = $scope.buildParams(params.statuts, params.status);
    if (!successStatus) delete params.status;
    delete params.statuts;

    params.zipCode = [];
    var successZipCode = $scope.buildParams(params.zipCodes, params.zipCode);
    if (!successZipCode) delete params.zipCode;
    delete params.zipCodes;

    params.company = [];
    var successCompany = $scope.buildParams(params.companies, params.company);
    if (!successCompany) delete params.company;
    delete params.companies;

    params.application = [];
    //if(params.applications.length === 0) params.application.push($state.params.defaultApplication);
    var successApplication = $scope.buildParams(params.applications, params.application);
    if (!successApplication) delete params.application;
    delete params.applications;

    params['meta.name'] = [];
    var successMetaName = $scope.buildParams(params['meta.names'], params['meta.name']);
    if (!successMetaName) delete params['meta.name'];
    delete params['meta.names'];

    params['meta.source'] = [];
    var successMetaSource = $scope.buildParams(params['meta.sources'], params['meta.source']);
    if (!successMetaSource) delete params['meta.source'];
    delete params['meta.sources'];

    params['meta.medium'] = [];
    var successMetaMedium = $scope.buildParams(params['meta.mediums'], params['meta.medium']);
    if (!successMetaMedium) delete params['meta.medium'];
    delete params['meta.mediums'];

    //params.applicationIds = ($scope.params.applications) ? $scope.params.applications : $state.params.defaultApplication;

    //if ($scope.currentState === 'icare') params.application = 'icare';
    //else if (!params.applicationIds && $scope.currentState === 'hub') params.application = "familyhub";
    // else if ($scope.currentState === 'family')

    var userAffectFamily = {
      "_id" : "5d7f86a3a27a49f42107113d",
      "firstname" : "Mélodie",
	    "lastname" : "ELAME",
    }
    // Perform the query
    Family.query({ params: params, search: $scope.search, options: options },
      function (data) {
        _.forEach(data, function(newData) {
          if (_.has(newData, 'user') && !_.has(newData, 'user._id')) {
            if ( _.get(newData, 'user') === "59ae8f7d57efd6d613be73c2") {
              newData.user = userAffectFamily
            } else {
              newData.user._id = newData.user
            }
          } else if (_.has(newData, 'user') && _.has(newData, 'user._id') ){
            if ( _.get(newData, 'user._id') === "59ae8f7d57efd6d613be73c2") {
              newData.user = userAffectFamily
            }
          }

        });

        $scope.families = data;
        initScoresAndApplications($scope.families);

        loadCompanies(associateParentsCompanies);

        angular.forEach($scope.families, function (value, key) {
          var params = _.transform(value.meta, function(result, value, key) {
            if(value && _.indexOf(['name', 'source', 'medium'], key) > -1)
              result['mpec_' + key] = value;
          }, {})
          if(params && !_.isEmpty(params)) {
            var link = _.filter($scope.links, { params: params });
            if(link && _.isArray(link) && link.length === 1 && link[0].highlight && link[0].params && link[0].params.mpec_name) {
              value._color =  '#' + string_to_color(link[0].params.mpec_name);
            }
          }
        });
        if (cb) return cb();
      });
  };

  $scope.exportList = function(format) {
    // Set options for query
    var options = _.clone($scope.options);

    //Proper format params
    var params = _.clone($scope.params);
    if ((!_.isUndefined($scope.dates.admissionEnd) && !_.isNull($scope.dates.admissionEnd)) || (!_.isUndefined($scope.dates.admissionStart) && !_.isNull($scope.dates.admissionStart))) {
      params.dates = {}
      if($scope.dates.admissionStart)
        params.dates.admissionStart = moment($scope.dates.admissionStart).startOf('day').format('YYYY-MM-DD HH:mm:ss');
      if($scope.dates.admissionEnd)
        params.dates.admissionEnd = moment($scope.dates.admissionEnd).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    }
    else {
      delete params.dates;
    }

    params.user = [];
    var successUser = $scope.buildParams(params.cas, params.user);
    if (!successUser) delete params.user;
    delete params.cas;

    params.status = [];
    var successStatus = $scope.buildParams(params.statuts, params.status);
    if (!successStatus) delete params.status;
    delete params.statuts;

    params.zipCode = [];
    var successZipCode = $scope.buildParams(params.zipCodes, params.zipCode);
    if (!successZipCode) delete params.zipCode;
    delete params.zipCodes;

    params.company = [];
    var successCompany = $scope.buildParams(params.companies, params.company);
    if (!successCompany) delete params.company;
    delete params.companies;

    params.application = [];
    //if(params.applications.length === 0) params.application.push($state.params.defaultApplication);
    var successApplication = $scope.buildParams(params.applications, params.application);
    if (!successApplication) delete params.application;
    delete params.applications;

    params['meta.name'] = [];
    var successMetaName = $scope.buildParams(params['meta.names'], params['meta.name']);
    if (!successMetaName) delete params['meta.name'];
    delete params['meta.names'];

    params['meta.source'] = [];
    var successMetaSource = $scope.buildParams(params['meta.sources'], params['meta.source']);
    if (!successMetaSource) delete params['meta.source'];
    delete params['meta.sources'];

    params['meta.medium'] = [];
    var successMetaMedium = $scope.buildParams(params['meta.mediums'], params['meta.medium']);
    if (!successMetaMedium) delete params['meta.medium'];
    delete params['meta.mediums'];

    //params.applicationIds = ($scope.params.applications) ? $scope.params.applications : $state.params.defaultApplication;

     //if ($scope.currentState === 'icare') params.application = 'icare';
    //else if (!params.applicationIds && $scope.currentState === 'hub') params.application = "familyhub";

    // params.tel = [];
    // var successPhone = $scope.buildParams(params.phones, params.tel)
    // if (!successPhone) delete params.tel;
    // delete params.phones;

    var options = encodeURIComponent(JSON.stringify(options));
    var params = encodeURIComponent(JSON.stringify(params));
    var search = encodeURIComponent(JSON.stringify($scope.search));

    $window.open('/api/family/export.' + format + '?params=' + params + '&search=' + search + '&options=' + options, '_blank');
  };

  /**
   * Return count of the datasource based on params
   */
  $scope.getCount = function () {
    var params = _.clone($scope.params);
    if ((!_.isUndefined($scope.dates.admissionEnd) && !_.isNull($scope.dates.admissionEnd)) || (!_.isUndefined($scope.dates.admissionStart) && !_.isNull($scope.dates.admissionStart))) {
      params.dates = {};
      if($scope.dates.admissionStart)
        params.dates.admissionStart = moment($scope.dates.admissionStart).startOf('day').format('YYYY-MM-DD HH:mm:ss');
      if($scope.dates.admissionEnd)
        params.dates.admissionEnd = moment($scope.dates.admissionEnd).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    }
    else {
      delete params.dates;
    }

    params.user = [];
    var successUser = $scope.buildParams(params.cas, params.user);
    if (!successUser) delete params.user;
    delete params.cas;

    params.status = [];
    var successStatus = $scope.buildParams(params.statuts, params.status);
    if (!successStatus) delete params.status;
    delete params.statuts;

    params.zipCode = [];
    var successZipCode = $scope.buildParams(params.zipCodes, params.zipCode);
    if (!successZipCode) delete params.zipCode;
    delete params.zipCodes;

    params.company = [];
    var successCompany = $scope.buildParams(params.companies, params.company);
    if (!successCompany) delete params.company;
    delete params.companies;

    params.application = [];
    //if(params.applications.length === 0) params.application.push($state.params.defaultApplication);
    var successApplication = $scope.buildParams(params.applications, params.application);
    if (!successApplication) delete params.application;
    delete params.applications;

    params['meta.name'] = [];
    var successMetaName = $scope.buildParams(params['meta.names'], params['meta.name']);
    if (!successMetaName) delete params['meta.name'];
    delete params['meta.names'];

    params['meta.source'] = [];
    var successMetaSource = $scope.buildParams(params['meta.sources'], params['meta.source']);
    if (!successMetaSource) delete params['meta.source'];
    delete params['meta.sources'];

    params['meta.medium'] = [];
    var successMetaMedium = $scope.buildParams(params['meta.mediums'], params['meta.medium']);
    if (!successMetaMedium) delete params['meta.medium'];
    delete params['meta.mediums'];

    //params.applicationIds = ($scope.params.applications) ? $scope.params.applications : $state.params.defaultApplication;

    //if ($scope.currentState === 'icare') params.application = 'icare';
    //else if (!params.applicationIds && $scope.currentState === 'hub') params.application = "familyhub";

    // Perform the query
    Family.count({ params: params, search: $scope.search },
      function (data) {
        //$scope.resetPaging(data.count);
        $scope.pagination.total = data.count;
      });
  };

  $scope.changeLimit = function () {
    $scope.buildUrlParams();
    $scope.loader = true;
    $scope.changeList(function () {
      $scope.loader = false;
    });
  };

  $scope.paginate = function () {
    $anchorScroll();
    $scope.buildUrlParams();
    $scope.loader = true;
    $scope.changeList(function () {
      $scope.loader = false;
    });
  };
  /**
   * Filter list based on search and params
   */
  $scope.filterList = function () {
    // Display loader dot the list
    $scope.loader = true;

    //if ($scope.params.quality == '') delete $scope.params.quality;

    // Get count
    $scope.getCount();

    //Update Url
    $scope.buildUrlParams();

    $scope.changeList(function () {
      $scope.loader = false;
    });
  };


  /**
   * Sort list by key
   * @param key
   */
  $scope.sortList = function (key) {
    $scope.loader = true;
    $scope.options.sort = key;
    $scope.options.order = $scope.options.order == 'DESC' ? 'ASC' : 'DESC';

    $scope.changeList(function () {
      $scope.loader = false;
    });
  };

  /**
   *
   * @param family
   */
  $scope.updateFamily = function (family) {
    User.get({ _id: family.user._id }, function (data) {
      family.user.firstname = data.firstname;
      family.user.lastname = data.lastname;

      Family.update(family, function () {
      }, Notif.error);
    });
  };

  /**
   *
   * @param family
   */
   $scope.bookmarkFamily = function(family) {
     Family.update({ _id: family._id, bookmark: !family.bookmark }, function (data) {
       family.bookmark = !family.bookmark;
       var action = 'ajouté';
       if(!family.bookmark) action = 'supprimé';
       ngNotify.set('Favori ' + action + ' !', 'success');
     }, function (err) {
       ngNotify.set(err, 'error');
     });
   };

  /**
   * Load the list of users with right 'appear in family list'
   */
  $scope.loadUsers = function (cb) {
    //var assignRight = '577cd527cb8e03f03f6c83a4';
    Right.getById({ _id: 'COMMERCIAL_HANDLE_FAMILY', populate: true }, function (data) {
      var assignRight = data[0]._id;
      User.query({ params: {}, search: {}, options: { populate: true, sort: '_lastname', order: 'ASC' } },
        function (data) {
          angular.forEach(data, function (user, key) {
            var keepLooping = true;
            angular.forEach(user.roles, function (role, key) {
              if (keepLooping) {
                if (_.indexOf(role.rights, assignRight) != -1) {
                  $scope.users.push(user);
                  keepLooping = false;
                }
              }
            });
          });
          if(cb) return cb();
        });
    });
  };


  /**
   * Load the list of contracts
   */
  $scope.loadNbContracts = function () {
    Contract.count({ params: { family: $stateParams.familyId, type: 'family' }}, function (data) {
      $scope.nbContracts = data;
    }, Notif.error);
  };

  /**
   *
   * @param userId
   */
  $scope.isUserInList = function (userId) {
    if (_.isUndefined(_.find($scope.users, ['_id', userId]))) {
      return true;
    }
    else {
      return false;
    }
  };

  /**
   * Source for the ngTagsInput
   */
  $scope.searchUsers = function (query) {
    var deferred = $q.defer();
    var activeUsers = _.filter($scope.users, "active");
    var ql = query.toLowerCase();
    deferred.resolve(_.filter(activeUsers, function (user) {
      return user.lastname.toLowerCase().contains(ql);
    }));
    return deferred.promise;
  };

  /**
   * Load the list of users with right 'appear in family list'
   */
  $scope.loadStatuses = function (cb) {
    FamilyStatus.query({ params: {}, search: {}, options: {sort: '_label', order: 'ASC'} },
      function (data) {
        $scope.statuses = data;
        if(cb) return cb();
      });
  };

  /**
   * Source for the ngTagsInput
   */
  $scope.searchStatuses = function (query) {
    var deferred = $q.defer();
    deferred.resolve($filter('filter')($scope.statuses, query));
    return deferred.promise;
  };

  /**
   * Source for the ngTagsInput
   */
  $scope.searchCompanies = function (query) {
    var deferred = $q.defer();
    Company.query({search: {text: query}, options: {limit: 30, sort: '_name', order: 'ASC'}}, function (companies) {
      _.each(companies, function (c) {
        c.display = c.name || c.denomination;
      });
      deferred.resolve(companies);
    }, Notif.error);
    return deferred.promise;
  };

  /**
   * Source for the ngTagsInput
   */
  $scope.searchApplications = function (query) {
    var deferred = $q.defer();
    Application.query({search: {text: query}, options: {limit: 30, sort: '_name', order: 'ASC'}}, function (applications) {
      _.each(applications, function (c) {
        c.display = c.name;
      });
      deferred.resolve(applications);
    }, Notif.error);
    return deferred.promise;
  };

  /**
   * Source for the ngTagsInput
   */
  $scope.searchZipCodes = function (query) {
    var deferred = $q.defer();
    deferred.resolve($filter('filter')($scope.zipCodes, query));
    return deferred.promise;
  };

  /**
   * Source for the ngTagsInput
   */
  $scope.searchMetas = function (key, query) {
    var deferred = $q.defer();
    deferred.resolve($filter('filter')($scope.metas[key], query));
    return deferred.promise;
  };

  /**
   * Load the list of users with right 'appear in family list'
   */
  $scope.loadCreches = function (location) {
    if ($scope.family.parents[$scope.mapLoad].location) {
      $scope.loadingCreche = true;

      var params = {};
      if (location) {
        params.location = location;
        params.radius = 10000;
      }
      var p = Creche.query({ params: params, search: {}, options: { populate: true } });
      var l = $scope.creches.length;
      while (l) {
        $scope.creches.shift();
        l--;
      }
      p.$promise.then(function (data) {
          _.each(data, function (e) {
            $scope.creches.unshift(e);
          });
          $scope.creches.forEach(function (creche) {
            var icon = 'creche_error';
            if(creche.status && creche.status.label)
              icon = 'creche_' + slug(creche.status.label, {'lower': true});
            creche.icon = '/public/image/' + icon + '.png';
          });
          $scope.loadingCreche = false;
        });
    }
  };

  /**
   * Load the list of links
   */
  $scope.loadLinks = function (cb) {
    Link.query({ params: { active: true }, search: {}, options: {sort: 'label', order: 'ASC'} }, function (data) {
      var labels = _.keysIn($scope.metas);
      _.forEach(labels, function(label) {
        _.forEach(data, function(link) {
          var value = _.get(link, 'params.mpec_' + label);
          if(value) $scope.metas[label].push(value);
        })
        $scope.metas[label] = _.uniq($scope.metas[label]).sort(function(a, b) {
          a = a.toLowerCase();
          b = b.toLowerCase();
          if (a < b) return -1;
          else if (a > b) return 1;
          return 0;
        })
      })
      $scope.links = data;
      if(cb) return cb();
    });
  };

  /**
   * Load the params from the URL
   */
  $scope.loadStateParams = function () {
    if (!_.isUndefined($stateParams.nextStep)) $scope.params.nextStep = $stateParams.nextStep;
    if (!_.isUndefined($stateParams.dateStart)) $scope.dates.admissionStart = moment.utc($stateParams.dateStart, 'YYYY-MM-DD HH:mm:ss').toDate();
    if (!_.isUndefined($stateParams.dateEnd)) $scope.dates.admissionEnd = moment.utc($stateParams.dateEnd, 'YYYY-MM-DD HH:mm:ss').toDate();

    if($stateParams.limit) {
      var limit = parseInt($stateParams.limit);
      if(_.isNaN(limit) || limit < 1) limit = $scope.paginationLimits[0];
      $scope.pagination.limit = $scope.params.limit = limit;
    }
    if($stateParams.page) {
      var page = parseInt($stateParams.page);
      if(_.isNaN(page) || page < 1) page = 1;
      $scope.pagination.currentPage = page;
    }

    // Handle async queries
    async.parallel([
      // Ca
      function (callback) {
        if (_.isUndefined($stateParams.ca)) return callback();

        if ($stateParams.ca.indexOf(';') != -1) {
          var caId = $stateParams.ca.split(';');
          User.query({ params: { ids: caId } }, function (data) {
            angular.forEach(data, function (item, key) {
              $scope.params.cas.push(item);
            });
            return callback();
          });
        }
        else {
          User.get({ _id: $stateParams.ca }, function (data) {
            $scope.params.cas.push(data);
            return callback();
          });
        }
      },
      function (callback) {
        if (_.isUndefined($stateParams.company)) return callback();
        if ($stateParams.company.indexOf(';') !== -1) {
          var companyIds = $stateParams.company.split(';');
          Company.query({params: {ids: companyIds}}, function (data) {
            angular.forEach(data, function (item, key) {
              $scope.params.companies.push(item);
            });
            return callback();
          });
        }
        else {
          Company.get({ _id: $stateParams.company}, function (data) {
            data.display = data.name || data.denomination;
            $scope.params.companies.push(data);
            return callback();
          });
        }
      },
      function (callback) {
        if(!$stateParams.application) $stateParams.application = '';
        var apps = $stateParams.application.split(';');
        if(apps.length === 1 && apps[0] === '') apps = [];
        if($scope.currentState === 'hub' && apps.length === 0) {
          $stateParams.application = _.map($scope.applications, function(app) {
            var id = app._id.toString();
            if(id !== $state.params.defaultApplication && app.type === 'hub') return id;
          }).join(';');
        }
        // else if($scope.currentState === 'family' && apps.length === 0) {
        //   $stateParams.application = $state.params.defaultApplication;
        // }

        if (_.isUndefined($stateParams.application)) return callback();
        if ($stateParams.application.indexOf(';') !== -1) {
          var applications = $stateParams.application.split(';');
          angular.forEach(applications, function (item, key) {
            var app = _.find($scope.applications, { _id: item });
            if(app) {
              app.display = app.name;
              $scope.params.applications.push(app);
            }
          });
          return callback();
        }
        else if(!_.isEmpty($stateParams.application)){
          var app = _.find($scope.applications, { _id: $stateParams.application });
          app.display = app.name;
          $scope.params.applications.push(app);
          return callback();
        }
        else return callback();
      },
      // Status
      function (callback) {
        if (_.isUndefined($stateParams.status)) return callback();

        if ($stateParams.status != 'all') {
          if ($stateParams.status.indexOf(';') != -1) {
            var statusId = $stateParams.status.split(';');
            FamilyStatus.query({ params: { ids: statusId } }, function (statuses) {
              angular.forEach(statuses, function (item, key) {
                $scope.params.statuts.push(item);
              });
              return callback();
            });
          }
          else {
            FamilyStatus.get({ _id: $stateParams.status }, function (status) {
              $scope.params.statuts.push(status);
              return callback();
            });
          }
        }
        else {
          FamilyStatus.query({ params: { category: 'OPPORTUNITES ACTIVES' } }, function (status) {
            angular.forEach(status, function (item, key) {
              $scope.params.statuts.push(item);
            });
            return callback();
          });
        }
      },
      // Zip code
      function (callback) {
        if (!_.isUndefined($stateParams.zipCode)) {
          if ($stateParams.zipCode.indexOf(';') != -1) {
            var zipArray = $stateParams.zipCode.split(';');
            $scope.params.zipCodes = zipArray;
          } else {
            $scope.params.zipCodes = [$stateParams.zipCode];
          }
        }

        return callback();
      },
      // Meta name
      function (callback) {
        if (!_.isUndefined($stateParams['meta.name'])) {
          if ($stateParams['meta.name'].indexOf(';') != -1)
            $scope.params['meta.names'] = $stateParams['meta.name'].split(';');
          else
            $scope.params['meta.names'] = [$stateParams['meta.name']];
        }
        return callback();
      },
      // Meta source
      function (callback) {
        if (!_.isUndefined($stateParams['meta.source'])) {
          if ($stateParams['meta.source'].indexOf(';') != -1)
            $scope.params['meta.sources'] = $stateParams['meta.source'].split(';');
          else
            $scope.params['meta.sources'] = [$stateParams['meta.source']];
        }
        return callback();
      },
      // Meta medium
      function (callback) {
        if (!_.isUndefined($stateParams['meta.medium'])) {
          if ($stateParams['meta.medium'].indexOf(';') != -1)
            $scope.params['meta.mediums'] = $stateParams['meta.medium'].split(';');
          else
            $scope.params['meta.mediums'] = [$stateParams['meta.medium']];
        }
        return callback();
      },
      // Search
      function (callback) {
        if (_.isUndefined($stateParams.search)) return callback();

        $scope.search.text = $stateParams.search;
        return callback();
      }
    ], function (err, results) {
      // Get data source count
      $scope.getCount();

      $scope.changeList(function() {
        $scope.loader = false;
      });
    });
  };

  /* Build params */
  $scope.buildParams = function (base, real) {
    if (!_.isUndefined(base) && base.length > 0) {
      angular.forEach(base, function (value) {
        if (!_.isUndefined(value._id)) real.push(value._id);
        else if (!_.isUndefined(value.text)) real.push(value.text);
        else if (!_.isUndefined(value)) real.push(value);
      });
      return true;
    }
    else {
      return false;
    }
  };

  /**
   * Function used to add params to URL
   */
  $scope.buildUrlParams = function () {
    // Init params object
    var params = {};

    if (!_.isEmpty($scope.search)) {
      params.search = '';
      if (!_.isUndefined($scope.search.text) && $scope.search.text != '') {
        params.search = $scope.search.text;
      }
    }

    // Now go through all search params and add them to URL
    //params.quality = '';
    params.nextStep = '';
    if (!_.isEmpty($scope.params)) {
      //if (!_.isUndefined($scope.params.quality)) params.quality = $scope.params.quality;
      if (!_.isUndefined($scope.params.nextStep)) params.nextStep = $scope.params.nextStep;

      // Handle array values such as CA and Status
      params.ca = '';
      if (!_.isEmpty($scope.params.cas)) {
        angular.forEach($scope.params.cas, function (ca, key) {
          if (key == 0) params.ca += ca._id;
          else params.ca += ';' + ca._id;
        });
      }

      params.status = '';
      if (!_.isEmpty($scope.params.statuts)) {
        angular.forEach($scope.params.statuts, function (status, key) {
          if (key == 0) params.status += status._id;
          else params.status += ';' + status._id;
        });
      }

      params.zipCode = '';
      if (!_.isEmpty($scope.params.zipCodes)) {
        angular.forEach($scope.params.zipCodes, function (zipCode, key) {
          if (key == 0) params.zipCode += zipCode.text;
          else params.zipCode += ';' + zipCode.text;
        });
      }

      params.company = '';
      if (!_.isEmpty($scope.params.companies)) {
        angular.forEach($scope.params.companies, function (company, key) {
          if (key == 0) params.company += company._id;
          else params.company += ';' + company._id;
        });
      }

      params.application = '';
      if ($scope.params.applications.length > 0) {
        angular.forEach($scope.params.applications, function (application, key) {
          if (key == 0) params.application += application._id;
          else params.application += ';' + application._id;
        });
      }

      params['meta.name'] = '';
      if (!_.isEmpty($scope.params['meta.names'])) {
        angular.forEach($scope.params['meta.names'], function (meta, key) {
          if (key == 0) params['meta.name'] += meta.text;
          else params['meta.name'] += ';' + meta.text;
        });
      }

      params['meta.source'] = '';
      if (!_.isEmpty($scope.params['meta.sources'])) {
        angular.forEach($scope.params['meta.sources'], function (meta, key) {
          if (key == 0) params['meta.source'] += meta.text;
          else params['meta.source'] += ';' + meta.text;
        });
      }

      params['meta.medium'] = '';
      if (!_.isEmpty($scope.params['meta.mediums'])) {
        angular.forEach($scope.params['meta.mediums'], function (meta, key) {
          if (key == 0) params['meta.medium'] += meta.text;
          else params['meta.medium'] += ';' + meta.text;
        });
      }

      //params.applicationIds = ($scope.params.applications) ? $scope.params.applications : $state.params.defaultApplication;

      // if (!_.isEmpty($scope.params.applications)) {
      //   angular.forEach($scope.params.companies, function (company, key) {
      //     if (key == 0) params.company += company._id;
      //     else params.company += ';' + company._id;
      //   });
      // }
    }

    params.dateStart = '';
    params.dateEnd = '';

    if (!_.isEmpty($scope.dates)) {
      if (!_.isNull($scope.dates.admissionStart) && !_.isUndefined($scope.dates.admissionStart))
        params.dateStart = moment($scope.dates.admissionStart).startOf('day').format('YYYY-MM-DD HH:mm:ss');
      if (!_.isNull($scope.dates.admissionEnd) && !_.isUndefined($scope.dates.admissionEnd))
        params.dateEnd = moment($scope.dates.admissionEnd).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    }

    params.page = $scope.pagination.currentPage;
    params.limit = $scope.pagination.limit;

    var state = ($scope.currentState === 'family') ? $scope.currentState: 'family.' + $scope.currentState;
    $state.go(state, params, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: true
    });
  };

  /* Add and edit Method */

  /**
   * Get the current network from db
   */
  $scope.initFamily = function () {
    Family.getById({ _id: $stateParams.familyId, populate: true }, function (data) {
      $scope.family = data[0] || {};
      $scope.initCA = _.get($scope.family, 'user._id', undefined);
      $scope.initStatus = $scope.family.status;
      if ($scope.family && $scope.family.meta && $scope.family.meta.name && $scope.family.meta.source && $scope.family.meta.source.indexOf('partenariat') > -1)
        showStickyNotif();

      // Change date format to match datepicker
      $scope.formatDates($scope.family);

      async.series([
        $scope.loadUsers,
        $scope.loadStatuses,
        $scope.loadSectors,
        $scope.loadLinks,
        $scope.loadCAHistory,
        $scope.loadStatusHistory,
        $scope.loadEmailHistory,
        $scope.loadSites,
        $scope.loadApplications,
        function(cb) {
          if($scope.currentState !== 'hub') return cb();
          $scope.application = _.find($scope.applications, {_id: $scope.family.application});
          //Load registrations + answers and score for initFamily
          $scope.loadRegistrations($scope.family._id, function() {
            var defaultSite = _.find($scope.sites, { application: $scope.family.application });
            var defaultRegistration = {
              score: 0,
              answers: [],
              family: $scope.family._id,
              application: $scope.family.application,
              site: defaultSite._id,
              form: {}
            }

            var registration =  defaultRegistration;
            if($scope.registrations[0]) registration = $scope.registrations[0];
            $scope.family.registration = registration;
            $scope.family.site = _.find($scope.sites, { '_id': registration.site });
            
            if (registration.score) $scope.family.score = registration.score;              
            else if (registration.score == 0) $scope.family.score = 0;
            else $scope.family.score = "?";

            if(!registration.form._id) {
              $scope.loadForms($scope.family.site._id, function(forms) {
                if(forms && forms.length === 1) registration.form = forms[0];
              })
            }

            $scope.$watch($scope.family.registration.form, function(ov, nv) {
              $scope.loadQuestions($scope.family.site._id, $scope.family.registration.form._id,function(questions) {
                $scope.questions = questions;
                var answers = [];
                _.forEach(questions, function(question, key) {
                  var answer = _.find($scope.family.registration.answers, function(a) { return a.question._id === question._id; });
                  if(answer) return;
                  $scope.family.registration.answers.splice(key, 0, { question: question, value: undefined });
                })
              })
            })

            //Handles checkbox cases when values hasn't been initialized or has been initialized as a single String
            _.forEach($scope.family.registration.answers, function(answer) {
              if (answer.question.type === "checkbox" && !(_.isArray(answer.value)))
                answer.value = (_.isUndefined(answer.value)) ? [] : [answer.value];
            });

            // _.forEach($scope.family.answers, function(answer, key){
            //   if (answer.question.type === "radio") {
            //     answer.value = _.find(answer.question.answers, { '_id': answer.value }).value;
            //   }
            // });
          })
        },
        $scope.loadNextStepEvents
      ])



      // // Load external data
      // $scope.loadUsers();
      //
      // //Load the statuses
      // $scope.loadStatuses();
      //
      // //Load the sectors
      // $scope.loadSectors();
      //
      // //Load the links
      // $scope.loadLinks();
      //
      // //Load CA history
      // $scope.loadCAHistory();
      //
      // //Load status history
      // $scope.loadStatusHistory();
      //
      // //Load Sites
      // $scope.loadSites();
      //
      // //Load Applications
      // $scope.loadApplications();
      //
      //
      // if($scope.isHubState()) {
      //   //Load registrations + answers and score for initFamily
      //   $scope.loadRegistrations($scope.family._id, function() {
      //     var defaultSite = _.find($scope.sites, { application: $scope.family.application });
      //     var defaultRegistration = {
      //       score: 0,
      //       answers: [],
      //       family: $scope.family._id,
      //       application: $scope.family.application,
      //       site: defaultSite._id,
      //       form: {}
      //     }
      //
      //     var registration =  defaultRegistration;
      //     if($scope.registrations[0]) registration = $scope.registrations[0];
      //     $scope.family.registration = registration;
      //     $scope.family.score = registration.score;
      //     $scope.family.site = _.find($scope.sites, { '_id': registration.site });
      //
      //     if(!registration.form._id) {
      //       $scope.loadForms($scope.family.site._id, function(forms) {
      //         if(forms && forms.length === 1) registration.form = forms[0];
      //       })
      //     }
      //
      //     $scope.$watch($scope.family.registration.form, function(ov, nv) {
      //       $scope.loadQuestions($scope.family.site._id, $scope.family.registration.form._id,function(questions) {
      //         $scope.questions = questions;
      //         var answers = [];
      //         _.forEach(questions, function(question, key) {
      //           var answer = _.find($scope.family.registration.answers, function(a) { return a.question._id === question._id; });
      //           if(answer) return;
      //           $scope.family.registration.answers.splice(key, 0, { question: question, value: undefined });
      //         })
      //       })
      //     })
      //
      //     //Handles checkbox cases when values hasn't been initialized or has been initialized as a single String
      //     _.forEach($scope.family.registration.answers, function(answer) {
      //       if (answer.question.type === "checkbox" && !(_.isArray(answer.value)))
      //         answer.value = (_.isUndefined(answer.value)) ? [] : [answer.value];
      //     });
      //
      //     // _.forEach($scope.family.answers, function(answer, key){
      //     //   if (answer.question.type === "radio") {
      //     //     answer.value = _.find(answer.question.answers, { '_id': answer.value }).value;
      //     //   }
      //     // });
      //   })
      //}


      //Load contracts
      $scope.loadNbContracts();

      //Load comments
      Comment.getByUserId({ userId: $scope.family._id }, function (data) {
        $scope.comments = data;
      });


      var linkParams = _.transform($scope.family.meta, function(result, value, key) {
        if(value && _.indexOf(['name', 'source', 'medium'], key) > -1)
          result['params.mpec_' + key] = value;
      }, {});
      if(linkParams && !_.isEmpty(linkParams)) {
        linkParams.highlight = true;
        Link.query({ params: linkParams }, function(link) {
          if(link && _.isArray(link) && link.length === 1 && link[0].params && link[0].params.mpec_name) {
            link._color = '#' + string_to_color(link[0].params.mpec_name);
            $scope.family._link = link;
          }
        });
      }

      var parents = $scope.family.parents;
      //Check address to load
      if (parents.length > 1) {
        var fav = _.findIndex(parents, {favorite: true});
        if (fav !== -1) {
          $scope.switchMap(fav);
        } else {
          if (parents[0].location) $scope.switchMap(0);
          else if (parents[1].location) $scope.switchMap(1);
          else $scope.switchMap(0);
        }
      }
      else {
        if ($scope.family.parents.length > 0) {
          $scope.switchMap(0);
        }
        else {
          $scope.loadCreches();
          $scope.family.earning = 0;
        }
      }

      var earning = 0;
      _.forEach($scope.family.parents, function (p) {
        earning += p.earning;
      });
      $scope.family.earning = earning;

      initParentsCompanies();
    });
  };

  $scope.syncRythm = function() {
    _.forEach($scope.family.children, function(c) {
      if(!c.days) c.days = [];
      c.rythm = c.days.length;
    })
  }

  $scope.exportRegistration = function(registrationId, format) {
    $window.open('/api/registration/'+ registrationId +'/export.' + format, '_blank');

  }

  /**
   * Init all companies
   */
  var loadCompanies = function(cb) {
    Company.query({ params: {}, search: {}, options: {} }, function (data) {
      $scope.companiesData = data;
      if (cb) return cb();
    });
  };

  /**
   * init companies for each parents
   */
  var initParentsCompanies = function () {
    _.forEach($scope.family.parents, function (p, key) {
      if (p.company && p.company.reference) {
        Company.get({_id: p.company.reference}, function (data) {
          // Ensures we do not assign an empty Company
          if (data._id) {
            $scope.companies[key] = data;
            var addressCompany = _.clone(data.address);
            $scope.addressCompanies[key] = (_.get(addressCompany, 'street', "").toString() + " " + _.get(addressCompany, 'zipCode', "").toString() + " " + _.get(addressCompany, 'city', "").toString()).trim();
          } else {
            p.company.reference = null;
          }
        }, Notif.error);
      } else if (_.has(p, "company")) {
        $scope.companies[key] = undefined;
        var addressCompany = _.clone(p.company.address);
        $scope.addressCompanies[key] = (_.get(addressCompany, 'street', "").toString() + " " + _.get(addressCompany, 'zipCode', "").toString() + " " + _.get(addressCompany, 'city', "").toString()).trim();
      }
    });
  };

  var associateParentsCompanies = function() {
    _.forEach($scope.families, function(f){
      _.forEach(f.parents, function(p) {
        var comp;
        if (p.company) comp = _.find($scope.companiesData, { '_id':  p.company.reference});
        if (comp) {
          p.company._id = comp._id;
          p.company.registeredCompanyName = comp.name || comp.denomination;
        }
      });
    });
  };

  /**
   * Method used to save a user on database
   * @param form
   * @param family
   */
  $scope.saveFamily = function (form, family) {
    var _family;
    // Difference between create and update
    if (!_.isUndefined(family._id)) {
      // Watch value of status and Ca to see if anything has changed
      //if ($scope.initStatus != family.status) $scope.saveStatusHistory();
      //if ($scope.initCA != family.user) $scope.saveCaHistory();

      $scope.splitRevenue();
      _family = _.omit(family, ['site', 'registration']);

      _family.children = _.map(_family.children, function (c) {
        delete c.popups;
        return c;
      });

      // Update the family
      Family.update(_family, function () {
        Notif.success('La fiche de la famille a bien été modifié !');
        //$scope.initFamily();
        $state.transitionTo($state.current, $stateParams, {
          reload: true,
          inherit: false,
          notify: true
        });
      }, Notif.error);
    }
    else {
      family.parents = _.map(family.parents, function (p) {
        return p;
      });
      $scope.splitRevenue();
      _family = _.omit(family, ['site', 'registration']);
      _family.children = _.map(_family.children, function (c) {
        delete c.popups;
        return c;
      });

      if (_family.meta) {
        var m_string = "mpec_"
        var m = {};
        _.each(Object.keys(_family.meta), function (key) {
          var new_key = key;
          if (key.startsWith(m_string)) new_key = key.slice(m_string.length);
          m[new_key] = _family.meta[key];
        })
        _family.meta = m;
      }
      Family.save(_family, function () {
        $state.go('family');
        //$scope.initFamily();
        Notif.success('La fiche de la famille a bien été créé !');
      }, Notif.error);
    }
  };

  /**
   * Load the list of users with right 'appear in family list'
   */
  $scope.loadSectors = function (cb) {
    Sector.query({ params: {}, search: {}, options: {} },
      function (data) {
        $scope.sectors = data;
        if(cb) return cb();
      });
  };

  /**
   * Save comments in the family object
   */
  $scope.saveComment = function () {
    var user = AuthService.getAuthenticatedUser();
    var commentToAdd = {
      type: 'family',
      content: $scope.newComments[0],
      user: user.user._id,
      reference: $scope.family._id
    };
    $scope.comments.push(commentToAdd);
    Comment.save(commentToAdd, function () {
      //Load comments
      Comment.getByUserId({ userId: $scope.family._id }, function (data) {
        $scope.comments = data;
        Notif.success('Le commentaire à bien été ajouté !');
      });
      $scope.newComments = null;
    }, Notif.error);
  };

  /**
   * Save the switch of CA
   */
  $scope.saveCaHistory = function () {
    var ca = {};
    var user = AuthService.getAuthenticatedUser();
    ca.type = 'assign';
    ca.user = user._id;
    ca.family = $scope.family._id;
    ca.reference = $scope.family.user;
    FamilyHistory.save(ca);
  };

  /**
   * Save the switch of status
   */
  $scope.saveStatusHistory = function () {
    var status = {};
    var user = AuthService.getAuthenticatedUser();
    status.type = 'status';
    status.user = user._id;
    status.family = $scope.family._id;
    status.reference = $scope.family.status;
    FamilyHistory.save(status);
  };

  /**
   * Returns a datepicker correct date format
   */
  $scope.formatDates = function (family) {
    // Next Step date
    // prevent the input to be initialized to 1/1/1970, if value is null
    if (family.nextStep) family.nextStep = new Date($scope.formatDate(family.nextStep));
    // For each child, format birth and start date
    if (family.children && family.children.length > 0) {
      angular.forEach(family.children, function (value, key) {
        if (value.birthdate) {
          value.birthdate = new Date($scope.formatDate(value.birthdate));
        }
        if (value.startdate) {
          value.startdate = new Date($scope.formatDate(value.startdate));
        }
      });
    }
  };

  $scope.formatDate = function (dateToFormate) {
    if (dateToFormate) {
      var tmp = dateToFormate.split(/[- :]/);
      var day = tmp[2].split(/[T]/)[0];
      var date = tmp[0] + '-' + tmp[1] + '-' + day;
      return date;
    } else {
      return null;
    }

  };

  /**
   * Load the CA history for the family
   */
  $scope.loadCAHistory = function (cb) {
    FamilyHistory.query({ family: $scope.family._id, type: 'assign' }, function (data) {
      $scope.caHistory = data;
      if(cb) return cb();
    });
  };

  /**
   * Load the status history for the family
   */
  $scope.loadStatusHistory = function (cb) {
    FamilyHistory.query({ family: $scope.family._id, type: 'status' }, function (data) {
      $scope.statusHistory = data;
      if(cb) return cb();
    });
  };

  /**
   * Load the status history for the family
   */
  $scope.loadEmailHistory = function (cb) {
    FamilyHistory.query({ family: $scope.family._id, type: 'email' }, function (data) {
      $scope.emailHistory = data;
      if(cb) return cb(null, data);
    });
  };

  /**
   * Open the modal to display comments to the user
   * @param family
   */
  $scope.openCommentModal = function (family) {
    var modalInstance = $uibModal.open({
      templateUrl: "partials/ui/comment.html",
      controller: 'CommentModalInstanceCtrl',
      size: 'lg',
      resolve: {
        Comments: function () {
          return $scope.comments;
        },
        FamilyEntity: function () {
          return $scope.family;
        }
      }
    });

    modalInstance.result.then(function (comment) {
      $scope.newComments = comment;
    });
  };

  /**
   * Open the modal to display comments to the user
   * @param family
   */
  $scope.openCAModal = function () {
    var modalInstance = $uibModal.open({
      templateUrl: "partials/ui/ca.html",
      controller: 'CAModalInstanceCtrl',
      size: 'lg',
      resolve: {
        CA: function () {
          return $scope.caHistory;
        },
        FamilyEntity: function () {
          return $scope.family;
        }
      }
    });

    modalInstance.result.then(function () {
      // Return actions
    });
  };

  $scope.openEmailModal = function () {
    var modalInstance = $uibModal.open({
      templateUrl: "partials/ui/email.html",
      controller: 'EmailModalInstanceCtrl',
      size: 'lg',
      resolve: {
        familyScope: $scope,
        FamilyEntity: function () {
          return $scope.family;
        }
      }
    });

    modalInstance.result.then(function () {
      // Return actions
    });
  }

  /**
   * Company modal -> search + erase
   */
  $scope.companyModal = function (parentNum) {
    var modalInstance = $uibModal.open({
      templateUrl: "partials/ui/company.html",
      controller: 'CompanyModalInstranceCtrl',
      size: 'md',
      resolve: {
        parentNum: function () {
          return parentNum;
        },
        familyEntity: function () {
          return $scope.family;
        }
      }
    });
    modalInstance.result.then(function () {
      initParentsCompanies();
    });
  };

  /**
   * Open the modal to display comments to the user
   * @param family
   */
  $scope.openStatusModal = function () {
    var modalInstance = $uibModal.open({
      templateUrl: "partials/ui/status.html",
      controller: 'StatusModalInstanceCtrl',
      size: 'lg',
      resolve: {
        Status: function () {
          return $scope.statusHistory;
        },
        FamilyEntity: function () {
          return $scope.family;
        }
      }
    });

    modalInstance.result.then(function (comment) {
      // Return actions
    });
  };

  $scope.openContractModal = function () {
    var modalInstance = $uibModal.open({
      templateUrl: "partials/ui/contractFamily.html",
      controller: 'ContractModalInstanceCtrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        Context: function () {
          return {
            nbContracts: $scope.nbContracts,
            family: $scope.family,
            application: $scope.application,
            id: $scope.family._id,
            type: 'family',
            mode: 'list',
            scope: $scope
          };
        }
      }
    });

    modalInstance.result.then(function () {
    });
  };

  /**
   * Update a parent address (lat and long)
   * @param parent
   */
  $scope.updateParentAddress = function (parent) {
    if (!_.isUndefined(parent) && !_.isUndefined(parent.address)) {
      var address = _.clone(parent.address);
      var fullAddress = (_.get(address, 'street', "").toString() + " " + _.get(address, 'zipCode', "").toString() + " " + _.get(address, 'city', "").toString()).trim();

      Geocoder.getFromAddress(fullAddress)
        .then(function (result) {
          var geoLoc = result.geometry.location;
          var coordinates = [geoLoc.lng(), geoLoc.lat()];
          parent.location = coordinates;

          if (parent.favorite == true) {
            //Load creches
            $scope.loadCreches(coordinates);
          }
        });
    }
  };

  /**
   * Update favorite parent
   * @param favoriteParent
   */
  $scope.updateFavoriteParent = function (favoriteParent) {
    $scope.favoriteParent = favoriteParent;
    if (favoriteParent == 0) {
      $scope.family.parents[1].favorite = false;
    } else if (favoriteParent == 1) {
      $scope.family.parents[0].favorite = false;
    }

    Family.update($scope.family);
  };

  $scope.arrayContains = function (array, el) {
    if (_.indexOf(array, el) === -1 ) return false;
    else return true;
  };

  $scope.checkedValues = function (qanswer, values) {
    if (_.isUndefined(values.value)) {
      values.value = [];
    }
    if (!$scope.arrayContains(values.value, qanswer))  {
      values.value.push(qanswer);
    } else if ($scope.arrayContains(values.value, qanswer)) {
      _.pull(values.value, qanswer);
    }
  };

  /**
   * Method used to update a registration on database
   * @param registration object
   */
  $scope.saveRegistration = function (registration) {
    var rgt = angular.copy(registration);
    rgt.answers = _.filter(rgt.answers, function(a) {
      return (a.question.type !== 'title' && a.value);
    });

    var request = Registration.update;
    if(!registration._id) request = Registration.save;

    request(rgt, function () {
      Notif.success('Le questionnnaire a bien été mis à jour');
      $scope.initFamily();
    }, Notif.error)

  }

  /**
   * Called when the user changes the map tab
   */
  $scope.switchMap = function (parent) {
    $scope.mapLoad = parent;
    if (parent !== null && _.get($scope.family, 'parents[' + parent + '].address')) {
      var address = _.clone($scope.family.parents[parent].address);
      $scope.favoriteFullAddress = (_.get(address, 'street', "").toString() + " " + _.get(address, 'zipCode', "").toString() + " " + _.get(address, 'city', "").toString()).trim();

      if (_.isUndefined($scope.family.parents[parent].location)) {
        if (!_.isEmpty($scope.favoriteFullAddress)) {
          Geocoder.getFromAddress($scope.favoriteFullAddress)
            .then(function (result) {
              var geoLoc = result.geometry.location;
              var coordinates = [geoLoc.lng(), geoLoc.lat()];

              //Load creches
              $scope.loadCreches(coordinates);
            });
        }
      } else {
        //Load creches
        $scope.loadCreches($scope.family.parents[parent].location);
      }
    }
  };

  $scope.canAffectFamily = function () {
    var user = AuthService.getAuthenticatedUser();
    return _.indexOf(user.rights, 'COMMERCIAL_ASSIGN_FAMILY') !== -1;
  };

  /**
   * FUnction called when the earnings of the couple is updated, split it into two parts for db
   */
  $scope.splitRevenue = function () {
    var earning = parseInt($scope.family.earning) / $scope.family.parents.length;
    if (!_.isNaN(earning)) {
      _.forEach($scope.family.parents, function (p) {
        p.earning = earning;
      });
    }
  };

   var createChild = function() {
    return {
      popups: [
        { opened: false },
        { opened: false }
      ]
    };
   };

  $scope.addChild = function() {
    if(!$scope.family.children) $scope.family.children = [];
    $scope.family.children.push({});
  };
  $scope.removeChild = function(index) {
    $scope.family.children.splice(index, 1);
  };

  $scope.openPopup = function(index, child) {
    child.popups[index].opened = true;
  };

  /**
   * Change tab method for detail family page
   * @param id
   */
  /*$scope.changeTab = function (id) {
   if (id != $scope.activeTab) {
   $scope.activeTab = id;
   $state.go('.', {tab: id}, {notify: false, reload: false});
   }
   };*/

  /* Datepicker methods */

  $scope.popup1 = {
    opened: false
  };

  $scope.popup2 = {
    opened: false
  };

  $scope.popup3 = {
    opened: false
  };

  $scope.popup4 = {
    opened: false,
    events: []
  };

  $scope.open1 = function () {
    $scope.popup1.opened = true;
  };

  $scope.open2 = function () {
    $scope.popup2.opened = true;
  };

  $scope.open3 = function () {
    $scope.popup3.opened = true;
  };

  $scope.open4 = function () {
    $scope.popup4.opened = true;
  };

  $scope.toInt = function (str) {
    return parseInt(str);
  };
}]);

app.controller('CAModalInstanceCtrl', ['$scope', '$uibModalInstance', 'CA', 'FamilyEntity', 'AuthService', function ($scope, $uibModalInstance, CA, FamilyEntity, AuthService) {
  $scope.ca = CA;
  $scope.family = FamilyEntity;

  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };
}]);

app.controller('StatusModalInstanceCtrl', ['$scope', '$uibModalInstance', 'Status', 'FamilyEntity', 'AuthService', function ($scope, $uibModalInstance, Status, FamilyEntity, AuthService) {

  $scope.status = Status;
  $scope.family = FamilyEntity;

  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };
}]);

app.controller('EmailModalInstanceCtrl', ['$scope', '$uibModalInstance', 'Notif', 'familyScope', 'FamilyHistory', 'FamilyEntity', 'AuthService', 'Template', 'Email', '_', function ($scope, $uibModalInstance, Notif, familyScope, FamilyHistory, FamilyEntity, AuthService, Template, Email, _) {
  $scope.loader = false;
  $scope.history = [];
  $scope.family = FamilyEntity;
  $scope.currentTab = 'history';
  $scope.getters = [];
  $scope.parent;
  $scope.template;

  $scope.templates = [];
  $scope.usableTemplates = [];
  $scope.recipients = [];

  $scope.status = {
    waiting: 'en attente',
    pending: 'en cours',
    sended: 'envoyé',
    error: 'erreur'
  }

  var loadTemplates = function(cb) {
    Template.query({}, function (data) {
      $scope.templates = data;
      $scope.usableTemplates = [];
      for(var i=0; i<data.length || 0; i++) {
        var t = data[i];
        if(!t.application || t.application === $scope.family.application) {
          if(t.active && !$scope.template) $scope.template = t._id;
          $scope.usableTemplates.push(t);
        }
      }
      if(cb) return cb();
    });
  }

  var applyGetters = function(nv, ov) {
    if(nv !== ov || _.isUndefined(nv)) {
      var t = _.find($scope.templates, { _id: $scope.template });
      if(!t) return;
      $scope.getters = [];
      _.forEach(t.vars, function(g) {
        if(!g || !g.func) return;
        var func = new Function('_', 'data', 'params', g.func);
        $scope.getters.push({
          name: g.name,
          value: func(_, $scope.family, { parent: $scope.parent, companies: familyScope.companies }) || '?'
        })
      })
    }
  }

  $scope.getTemplate = function(tid) {
    var t = _.find($scope.templates, { _id: tid });
    if(!t) t = {};
    return t;
  }

  $scope.$watch('parent', applyGetters);
  $scope.$watch('template', applyGetters);

  $scope.init = function() {
    $scope.loader = true;
    familyScope.loadEmailHistory(function(err, history) {
      $scope.history = history;
      loadTemplates(function() {
        _.forEach($scope.family.parents, function(parent) {
          var email = _.get(parent, 'email', '');
          if(!_.isEmpty(email))
          $scope.recipients.push(parent);
        })
        if($scope.recipients.length > 0) $scope.parent = $scope.recipients[0]._id;
        $scope.loader = false;
      })
    })
  }

  $scope.send = function() {
    var t = _.find($scope.templates, { _id: $scope.template });
    var p = _.find($scope.recipients, { _id: $scope.parent });
    if(!t || !p) return;
    var email = {
      type: 'family',
      reference: $scope.family._id,
      template: t._id,
      to: p.email
    }
    if(t.from) email.from = t.from;
    if(t.subject) email.subject = t.subject;
    if(t.reply) email.reply = t.reply;
    _.forEach($scope.getters, function(g) {
      _.set(email, ['context', g.name], g.value);
    })
    Email.save(email, function () {
      Notif.success('Email envoyé avec succès');
      familyScope.loadEmailHistory();
      $scope.cancel();
    }, function() {
      Notif.error('Une erreur est survenue');
    });
  }

  $scope.preview = function(tid) {
    var exp = function(str) { return "\{\{var:" + str + ":[^\{]+\}\}"; };
    var t = $scope.getTemplate(tid);
    if(t) {
      var content = t.content;
      var data = {};
      _.forEach(t.vars, function(g) {
        var func = new Function('_', 'data', 'params', g.func);
        var r = new RegExp(exp(g.name), 'g');
        content = content.replace(r, func(_, $scope.family, { parent: $scope.parent, companies: familyScope.companies }));
        data[g.name] = func(_, $scope.family, { parent: $scope.parent, companies: familyScope.companies });
      })
      var popup = window.open('', '_blank');
      popup.document.write(window.Twig.twig({data: content}).render(data));
    }
  }

  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };

  $scope.init();
}])


app.controller('CompanyModalInstranceCtrl', ['$scope', '$uibModalInstance', '$state', 'Notif', 'Company', 'Family', 'parentNum', 'familyEntity', '$http', function ($scope, $uibModalInstance, $state, Notif, Company, Family, parentNum, familyEntity, $http) {
  $scope.parent = familyEntity.parents[parentNum];
  $scope.companyName = _.get($scope.parent, 'company.name');
  $scope.query = "";
  $scope.company = null;
  $scope.modes = ['siren', 'nom'];
  $scope.mode = $scope.modes[0];

  $scope.initCompany = function () {
    if ($scope.parent.company && $scope.parent.company.reference) {
      Company.get({_id: $scope.parent.company.reference}, function (company) {
        $scope.company = company;
      }, Notif.error);
    } else {
      $scope.changeMode('nom');
    }
  };

  var searchFirmApi = function (query) {
    query = query.replace(/ /g, '');
    $scope.company = null;
    return $http({method: 'GET', url: 'https://firmapi.com/api/v1/companies/' + query})
      .then(function (res) {
        var c = res.data.company;
        $scope.company = {
          name: c.names.commercial_name,
          denomination: c.names.denomination,
          activity: c.activity,
          address: {
            street: c.address,
            city: c.city,
            zipCode: c.postal_code
          },
          siren: c.siren
        };
      }).catch(function () {
        Notif.error('Siren inconnu');
      });
  };

  var searchDb = function (query) {
    return Company.query({search: {text: query}, options: {limit: 0, order: 'ASC', sort: 'name'}}, function (cs) {
      $scope.companies = cs;
    }, Notif.error);
  };

  $scope.search = function (query) {
    switch ($scope.mode) {
    case 'siren': searchFirmApi(query); break;
    case 'nom': searchDb(query); break;
    default: Notif.error('Mode inconnu'); break;
    }
  };

  $scope.changeMode = function (mode) {
    $scope.mode = mode;
    if (mode === 'nom') {
      $scope.query = _.get($scope.parent, 'company.name');
      searchDb($scope.query);
    }
  };

  var updateFamily = function (id) {
    _.set($scope.parent, 'company.reference', id);
    Family.update(familyEntity, function (doc) {
      Notif.success('La famille a bien été modifié');
      $uibModalInstance.close('confirm');
    });
  };

  var firmApiSave = function () {
    Company.query({params: {siren: $scope.company.siren}}, function (companies) {
      if (companies.length > 1) Notif.error('Erreur');
      else if (companies.length === 1) {
        $scope.company._id = companies[0]._id;
        Company.update($scope.company, function () {
          updateFamily(companies[0]._id);
        }, Notif.error);
      } else {
        Company.save($scope.company, function (company) {
          updateFamily(company._id);
        }, Notif.error);
      }
    }, Notif.error);
  };

  var companySave = function () {
    updateFamily(_.get($scope.company, '_id', null));
  };

  $scope.confirm = function () {
    if ($scope.company === null) updateFamily(null);
    else if ($scope.mode === 'siren') firmApiSave();
    else if ($scope.mode === 'nom') companySave();
    else Notif.error('Mode inconnu');
  };

  $scope.cancel = function () {
    $uibModalInstance.dismiss('cancel');
  };

  $scope.selectCompany = function(company) {
    $scope.company = company;
  };
}]);
