'use strict';

app.controller('NotificationsCtrl', ['$scope', '$window', 'AuthService', '$stateParams', '_', 'Modal', 'Network', 'Creche', '$state', 'Notif', 'Notification', function ($scope, $window, AuthService, $stateParams, _, Modal, Network, Creche, $state, Notif, Notification) {
  $scope.notifications = [];

  //var userId = "59ae8f7d57efd6d613be73c2";
  var userId = AuthService.getAuthenticatedUser().user._id;

  $scope.paginationLimits = [20, 50, 100, 150, 200];

  $scope.pagination = {
    total: 0,
    skip: 0,
    currentPage: 1,
    limit: $scope.paginationLimits[0]
  };

  $scope.notificationTypes = {
    'oldAssign': 'Réattribution d\'une famille',
    'assign': 'Attribution d\'une famille',
    'newContract': 'Création d\'un berceau',
    'newNetwork': 'Création d\'un réseau',
    'contractUpdate': 'Modification d\'un berceau'
  };

  $scope.params = { user: userId };
  $scope.search = {};
  $scope.options = {
    sort: 'created',
    order: 'DESC'
  };

  $scope.loader = false;

  $scope.popover = {
    title: 'Informations',
    templateUrl: 'partials/ui/popover-template-notification.html'
  };

  /* List page Method */

  var setContent = function(notifs) {
    var checkKeys = {
      'rythm': 'rythme',
      'creche': 'crèche',
      'price_interest': 'Prix intérêt',
      'date_interest': 'Date intérêt',
      'price_buy': 'Prix achat',
      'date_planned_start': 'Date début planifiée',
      'date_planned_end': 'Date fin planifiée',
      'date_real_start': 'Date début réelle',
      'date_real_end': 'Date fin réelle',
      'date_effective_start': 'Date debut effective',
      'date_effective_end': 'Date fin effective',
      'category': 'Catégory'
    };
    _.forEach(notifs, function(notif) {
      if (_.get(notif, ['infos', notif.type].join('.'), null)) {
        notif.content = [];
        _.forEach(notif.infos[notif.type], function(values, key) {
          var t1 = t2 = null;
          t1 = values[1];
          t2 = values[2];
          if (values[0] == 'date') {
            t1 = values[1] ? new Date(values[1]) : '';
            t2 = values[2] ? new Date(values[2]) : '';
          }
          notif.content.push({
            main: checkKeys[key],
            type: values[0],
            old: t1,
            new: t2
          });
        });
      }
    });
    return notifs;
  };

  $scope.readAll = function() {
    async.forEach($scope.notifications, function(n, cb) {
      Notification.update({_id: n._id, read: true}, function(not) {
        return cb();
      });
    }, function() {
      $scope.changeList();
    });
  };

  $scope.changeRead = function(notif) {
    notif.read = !notif.read;
    Notification.update({ _id: notif._id, read: notif.read }, function(n) {
      if (!n || !n._id) Notif.error('Une erreur est survenue.');
      else Notif.success('État modifié.');
    });
  };

  /**
  * Init method to get all networks from the database
  */
  $scope.initNotifications = function () {
    // Display loader
    $scope.loader = true;

    $scope.loadStateParams();

    // Get data source count
    $scope.getCount();

    // Get data
    setTimeout(function () {
      $scope.changeList();
      $scope.loader = false;
    }, 500);
  };

  $scope.getType = function(type) {
    return _.get($scope.notificationTypes, type, 'Unknown');
  };

  /**
  * Reset pagging method
  * @param total
  */
  $scope.resetPaging = function (total) {
    $scope.pagination.total = total;
    $scope.pagination.offset = 0;
    $scope.pagination.currentPage = 1;
  };

  $scope.changeLimit = function () {
    //$scope.buildUrlParams();
    $scope.loader = true;

    $scope.buildStateParams();
    $scope.buildUrlParams();

    $scope.changeList(function () {
      $scope.loader = false;
    });
  };

  /**
  * Method called when the user changes the pagination
  */
  $scope.changeList = function (cb) {
    // Set options for query
    var options = _.clone($scope.options);
    options.skip = ($scope.pagination.currentPage - 1) * $scope.pagination.limit;
    options.limit = $scope.pagination.limit;
    options.populate = true;

    var search = _.clone($scope.search);

    var params = _.clone($scope.params);
    if (!_.get(params, 'type', '').length) delete $scope.params.type;
    var b = !(_.get(params, 'read', '').length);
    if (b) delete params.read;
    else {
      if (!_.isUndefined(params.read)) {
        params.read = (params.read == 'true');
      }
    }

    // Perform the query
    Notification.query({params: params, search: search, options: options}, function(data) {
      $scope.notifications = setContent(data);
      if (cb) cb();
    });
  };

  /**
  * Return count of the datasource based on params
  */
  $scope.getCount = function () {
    // Perform the query
    if (!_.get($scope.params, 'type', '').length) delete $scope.params.type;

    Notification.count({params: $scope.params, search: $scope.search}, function (data) {
      $scope.resetPaging(data.count);
    });
  };

  /**
  * Filter list based on search and params
  */
  $scope.filterList = function () {
    // Display loader dot the list
    $scope.loader = true;

    $scope.buildStateParams();

    $scope.buildUrlParams();

    // Get count
    $scope.getCount();

    // Get data
    setTimeout(function () {
      $scope.changeList();
      $scope.loader = false;
    }, 500);
  };


  /**
  * Sort list by key
  * @param key
  */
  $scope.sortList = function (key) {
    $scope.loader = true;
    $scope.options.sort = key;
    $scope.options.order = $scope.options.order == 'DESC' ? 'ASC' : 'DESC';

    //Get data
    setTimeout(function () {
      $scope.changeList();
      $scope.loader = false;
    }, 500);
  };

  $scope.loadStateParams = function () {
    $stateParams = {};
    if (!_.isUndefined($stateParams.type)) $scope.params.type = $stateParams.type;
    if (!_.isUndefined($stateParams.read)) $scope.params.read = $stateParams.read;

    if($stateParams.limit) {
      var limit = parseInt($stateParams.limit);
      if(_.isNaN(limit) || limit < 1) limit = $scope.paginationLimits[0];
      $scope.pagination.limit = $scope.params.limit = limit;
    }
    if($stateParams.page) {
      var page = parseInt($stateParams.page);
      if(_.isNaN(page) || page < 1) page = 1;
      $scope.pagination.currentPage = page;
    }

  };

  $scope.buildStateParams = function () {
    $stateParams = {};
    if (!_.isUndefined($scope.params.type) && !_.isEmpty($scope.params.type)) $stateParams.type = $scope.params.type;
    if (!_.isUndefined($scope.params.read) && !_.isEmpty($scope.params.read)) $stateParams.read = $scope.params.read;

    $stateParams.limit = $scope.pagination.limit;
    $stateParams.page = $scope.pagination.currentPage;

  };

  $scope.buildUrlParams = function() {
    var params = {};

    if (!_.isUndefined($scope.params.type) && !_.isEmpty($scope.params.type)) params.type = $scope.params.type;
    if (!_.isUndefined($scope.params.read) && !_.isEmpty($scope.params.read)) params.read = $scope.params.read;

    params.limit = $scope.pagination.limit;
    params.page = $scope.pagination.currentPage;

    $state.go('notifications', params, {
      // prevent the events onStart and onSuccess from firing
      notify: false,
      // prevent reload of the current state
      reload: false,
      // replace the last record when changing the params so you don't hit the back button and get old params
      location: 'replace',
      // inherit the current params on the url
      inherit: false
    });
  };

  /*$scope.exportList = function(format) {
  // Set options for query
  var options = _.clone($scope.options);

  var options = encodeURIComponent(JSON.stringify(options));
  var params = encodeURIComponent(JSON.stringify($scope.params));
  var search = encodeURIComponent(JSON.stringify($scope.search));

  $window.open('/api/network/export.' + format + '?params=' + params + '&search=' + search + '&options=' + options, '_blank');
};*/
}]);
